var T = Object.defineProperty;
var k = (e, t, n) => t in e ? T(e, t, { enumerable: !0, configurable: !0, writable: !0, value: n }) : e[t] = n;
var A = (e, t, n) => k(e, typeof t != "symbol" ? t + "" : t, n);
import { errorService as E } from "@waitroom/error-service";
import { LOGGER_SERVICE_BRAID as l } from "@waitroom/models";
import { parseJson as x, retryWithBackoff as O, parseStream as S } from "@waitroom/utils";
let i;
const M = (e) => {
  i = e.logger;
}, I = (e) => e ? `?${Object.keys(e).map((t) => `${t}=${encodeURIComponent(e[t])}`).join("&")}` : "";
class P {
  constructor() {
    A(this, "handlerRef", { id: -1 });
  }
  get handler() {
    return this.handlerRef.id;
  }
  set handler(t) {
    this.handlerRef.id = t;
  }
  clear() {
    clearTimeout(this.handlerRef.id);
  }
}
function D(e, t, n = new P()) {
  let r = !1;
  const s = () => {
    n.handler = setTimeout(() => {
      e(() => {
        r = !0, n.clear();
      }), r || s();
    }, t);
  };
  return s(), n;
}
var U = window.fetch, j = window.AbortController, $ = window.Headers;
async function N(e, t = {}) {
  if (t = { ...t }, t.headers ? t.headers = new $(t.headers) : t.headers = new $(), t.version && console.assert(
    Array.isArray(t.version),
    "fetch(): `version` must be an array"
  ), t.parents && console.assert(
    Array.isArray(t.parents),
    "fetch(): `parents` must be an array"
  ), t.version && t.headers.set(
    "version",
    t.version.map(JSON.stringify).join(", ")
  ), t.parents && t.headers.set(
    "parents",
    t.parents.map(JSON.stringify).join(", ")
  ), t.subscribe && t.headers.set("subscribe", "true"), t.peer && t.headers.set("peer", t.peer), t.headers.set("X-Keep-Alive", "true"), t.cache = "no-cache", t.patches)
    if (console.assert(!t.body, "Cannot send both patches and body"), console.assert(
      typeof t.patches == "object",
      "Patches must be object or array"
    ), Array.isArray(t.patches) || (t.patches = [t.patches]), t.patches.length === 1) {
      let c = t.patches[0];
      t.headers.set("Content-Range", `${c.unit} ${c.range}`), t.headers.set(
        "Content-Length",
        `${new TextEncoder().encode(c.content).length}`
      ), t.body = c.content;
    } else
      t.headers.set("Patches", t.patches.length), t.body = t.patches.map((c) => {
        var o = `content-length: ${new TextEncoder().encode(c.content).length}`, f = `content-range: ${c.unit} ${c.range}`;
        return `${o}\r
${f}\r
\r
${c.content}\r
`;
      }).join(`\r
`);
  var n = t.signal, r = new j();
  t.signal = r.signal, n && n.addEventListener("abort", () => r.abort());
  var s = await U(e, t);
  s.subscribe = h, s.subscription = { [Symbol.asyncIterator]: u };
  const a = s.headers.get("X-Keep-Alive");
  function h(c, o) {
    if (!s.ok) throw new Error("Request returned not ok status:", s.status);
    if (s.bodyUsed)
      throw new Error("This response's body has already been read", s);
    J(
      s.body,
      // Each time something happens, we'll either get a new
      // version back, or an error.
      (f, v) => {
        if (!v)
          c(f);
        else {
          const p = r.signal.aborted;
          if (r.abort(), o) o(v, p);
          else throw "Unhandled network error in subscription";
        }
      },
      a
    );
  }
  function u() {
    var c = !1, o = [], f = null, v = null;
    return {
      async next() {
        if (o.length > 0) return { done: !1, value: o.shift() };
        var p = new Promise((d, b) => {
          f = d, v = b;
        });
        c || (c = !0, h(
          (d) => f(d),
          (d) => v(d)
        ));
        var y = await p;
        return f = (d) => o.push(d), v = (d) => {
          throw d;
        }, { done: !1, value: y };
      }
    };
  }
  return s;
}
async function J(e, t, n) {
  const r = e.getReader(), s = B(t);
  let a;
  if (n) {
    const h = (parseInt(n) + 15) * 1e3;
    a = D(() => s.checkTimeout(h), 1e4), s.keepAlive = !0;
  }
  for (; ; ) {
    let h, u;
    try {
      const c = await r.read();
      h = c.done, u = c.value;
    } catch (c) {
      a && a.clear(), s.timedOut || t(null, c);
      return;
    }
    if (h) {
      a && a.clear(), s.timedOut || t(null, "Connection closed");
      return;
    }
    s.read(u);
  }
}
const B = (e) => ({
  // A parser keeps some parse state
  state: { input: [] },
  // And reports back new versions as soon as they are ready
  cb: e,
  // Timestamp of last input
  last: Date.now(),
  timedOut: !1,
  keepAlive: !1,
  // You give it new input information as soon as you get it, and it will
  // report back with new versions as soon as it finds them.
  read(t) {
    const n = t;
    if (this.last = Date.now(), !(this.keepAlive && n.length > 12 && n[0] === 88 && n[1] == 45 && n[2] === 75 && n[3] === 101 && n[4] === 101 && n[5] === 112 && n[6] === 45 && n[7] === 65 && n[8] === 108 && n[9] === 105 && n[10] === 118 && n[11] === 101))
      for (this.state.input.push(...t); this.state.input.length; ) {
        try {
          this.state = L(this.state);
        } catch (r) {
          this.cb(null, r);
          return;
        }
        if (this.state.result === "success")
          this.cb({
            version: this.state.version,
            parents: this.state.parents,
            body: this.state.body,
            patches: this.state.patches,
            // Output extra_headers if there are some
            extra_headers: C(this.state.headers)
          }), this.state = { input: this.state.input };
        else if (this.state.result === "error") {
          this.cb(null, this.state.message);
          return;
        }
        if (this.state.result == "waiting") break;
      }
  },
  checkTimeout(t) {
    const n = Date.now() - this.last;
    i == null || i.service(l, 5, `Interval diff: ${n}`), n > t && (i == null || i.service(l, 3, "Connection timed out"), this.timedOut || e(null, "Connection timed out"), this.timedOut = !0);
  }
});
function L(e) {
  if (!e.headers) {
    const t = m(e.input);
    if (t.result === "error") return t;
    if (t.result === "waiting")
      return e.result = "waiting", e;
    e.headers = t.headers, e.version = e.headers.version, e.parents = e.headers.parents, e.input = t.input;
  }
  return F(e);
}
function m(e) {
  const t = H(e);
  if (!t) return { result: "waiting" };
  const n = t.header_string, r = n.length, s = {}, a = /(:?[\w-_]+):\s?(.*)\r?\n?/gy;
  let h, u = !1;
  for (; h = a.exec(n); )
    s[h[1].toLowerCase()] = h[2], a.lastIndex === r && (u = !0);
  return u ? ("version" in s && (s.version = JSON.parse("[" + s.version + "]")), "parents" in s && (s.parents = JSON.parse("[" + s.parents + "]")), "patches" in s && (s.patches = JSON.parse(s.patches)), e = t.remaining_bytes, { result: "success", headers: s, input: e }) : {
    result: "error",
    message: 'Parse error in headers: "' + JSON.stringify(n.substr(a.lastIndex)) + '"',
    headers_so_far: s,
    last_index: a.lastIndex,
    headers_length: r
  };
}
function R(e) {
  const t = e.match(/(\S+)( (.*))?/);
  return t && { unit: t[1], range: t[3] || "" };
}
function F(e) {
  const t = parseInt(e.headers["content-length"]);
  if (isNaN(t)) {
    if (e.headers.patches != null) {
      e.patches = e.patches || [];
      let n = e.patches[e.patches.length - 1];
      for (; !(e.patches.length === e.headers.patches && (e.patches.length === 0 || "content" in n)); ) {
        if ((!n || "content" in n) && (n = {}, e.patches.push(n)), !("headers" in n)) {
          const r = m(e.input);
          if (r.result === "error") return r;
          if (r.result === "waiting")
            return e.result = "waiting", e;
          n.headers = r.headers, e.input = r.input;
        }
        {
          if (!("content-length" in n.headers))
            return {
              result: "error",
              message: "no content-length in patch",
              patch: n,
              input: new TextDecoder("utf-8").decode(new Uint8Array(e.input))
            };
          if (!("content-range" in n.headers))
            return {
              result: "error",
              message: "no content-range in patch",
              patch: n,
              input: new TextDecoder("utf-8").decode(new Uint8Array(e.input))
            };
          const r = parseInt(n.headers["content-length"]);
          if (e.input.length < r)
            return e.result = "waiting", e;
          const s = R(n.headers["content-range"]);
          if (!s)
            return {
              result: "error",
              message: "cannot parse content-range in patch",
              patch: n,
              input: new TextDecoder("utf-8").decode(new Uint8Array(e.input))
            };
          n.unit = s.unit, n.range = s.range, n.content = new TextDecoder("utf-8").decode(
            new Uint8Array(e.input.slice(0, r))
          ), n.extra_headers = C(n.headers), delete n.headers, e.input = e.input.slice(r);
        }
      }
      return e.result = "success", e;
    }
  } else {
    if (t > e.input.length)
      return e.result = "waiting", e;
    if (e.result = "success", e.headers["content-range"]) {
      const n = R(e.headers["content-range"]);
      if (!n)
        return {
          result: "error",
          message: "cannot parse content-range",
          range: e.headers["content-range"]
        };
      e.patches = [
        {
          unit: n.unit,
          range: n.range,
          content: new TextDecoder("utf-8").decode(
            new Uint8Array(e.input.slice(0, t))
          )
          // Question: Perhaps we should include headers here, like we do for
          // the Patches: N headers below?
          // headers: state.headers
        }
      ];
    } else
      e.body = new TextDecoder("utf-8").decode(
        new Uint8Array(e.input.slice(0, t))
      );
    return e.input = e.input.slice(t), e;
  }
  return {
    result: "error",
    message: "cannot parse body without content-length or patches header"
  };
}
function C(e) {
  const t = Object.assign({}, e), n = [
    "version",
    "parents",
    "patches",
    "content-length",
    "content-range"
  ];
  for (let r = 0; r < n.length; r++)
    delete t[n[r]];
  if (Object.keys(t).length !== 0)
    return t;
}
function H(e) {
  let t = 0;
  for (; e[t] === 13 || e[t] === 10; )
    t++;
  if (t === e.length)
    return null;
  let n = t, r = 0;
  for (; n < e.length; ) {
    if (e[n] === 10 && e[n + 1] === 10) {
      r = 2;
      break;
    }
    if (e[n] === 10 && e[n + 1] === 13 && e[n + 2] === 10) {
      r = 3;
      break;
    }
    if (e[n] === 13 && e[n + 1] === 10 && e[n + 2] === 10) {
      r = 3;
      break;
    }
    if (e[n] === 13 && e[n + 1] === 10 && e[n + 2] === 13 && e[n + 3] === 10) {
      r = 4;
      break;
    }
    n++;
  }
  if (n === e.length)
    return null;
  const s = e.slice(t, n), a = new TextDecoder("utf-8").decode(
    new Uint8Array(s)
  );
  return {
    remaining_bytes: e.slice(n + r),
    header_string: a
  };
}
const G = N, K = {
  method: "GET",
  shouldReconnect: !0,
  subscribe: !0,
  skipRetryErrorCodes: [404]
}, q = (e) => (t) => typeof t == "object" && !!e && (e.includes(t.status) || e.includes(t.code)), W = {
  _connections: {},
  config: { interceptors: {} },
  close: function(e) {
    const t = this._connections[e];
    t && (i == null || i.service(l, 3, "Closing connection", e), t.abort.abort(`Close called for ${e}`), delete this._connections[e]);
  },
  fetch: async function(e) {
    var c;
    const { onError: t, onRetry: n } = this.config.interceptors, r = { ...K, ...e }, s = r.key || r.url || "";
    if (i == null || i.service(l, 5, "Fetch", s), this._connections[s]) {
      const o = `Connection already exists ${s}`;
      if (i == null || i.service(l, 3, o), !r.shouldReconnect) throw new Error(o);
      this.close(s);
    }
    const a = I(r.params), h = `${r.baseURL}${r.url}${a}`, u = new AbortController();
    if (r.signal) {
      if (r.signal.aborted) return;
      r.signal.addEventListener("abort", () => {
        i == null || i.service(
          l,
          3,
          "Abort connection",
          s
        ), u.abort(`Abort called for ${s}`), delete this._connections[s];
      });
    }
    try {
      return await new Promise(async (o, f) => {
        await O(() => new Promise(async (p, y) => {
          i == null || i.service(
            l,
            5,
            "Opening connection",
            s
          );
          const d = G(h, {
            signal: u.signal,
            ...r
          });
          this._connections[s] = {
            status: void 0,
            abort: u,
            connection: d
          };
          let b;
          try {
            b = await d;
          } catch (w) {
            return y(w);
          }
          if (b.status > 399)
            return f({
              // @ts-expect-error types are wrong
              code: b.code || b.status,
              data: b == null ? void 0 : b.data,
              success: !1,
              error: b
            });
          b.subscribe(
            (w) => {
              if (w.body) {
                const g = x(w.body);
                return i == null || i.service(
                  l,
                  5,
                  "Body",
                  s
                ), r.onFetched && r.onFetched(g), g ? o(g) : f(w);
              }
              if (w.patches && r.onPatch) {
                i == null || i.service(
                  l,
                  5,
                  "Patch",
                  s,
                  w.patches
                );
                const g = w.patches.map(
                  (_) => ({
                    op: _.unit,
                    path: _.range,
                    value: _.content ? x(_.content) : void 0
                  })
                );
                r.onPatch(g);
              }
            },
            async (w, g) => (i == null || i.service(l, 2, "Connection error", {
              error: w,
              wasAborted: g
            }), g ? o(void 0) : y(w))
          );
        }), {
          // infinite retries
          retries: -1,
          shouldSkip: q(r.skipRetryErrorCodes),
          onRetry: async (p, y) => {
            i == null || i.service(
              l,
              5,
              `Retry ${y}`,
              s
            ), n && await n(r, p);
          }
        });
      });
    } catch (o) {
      if (typeof o == "object" && o) {
        const p = (c = o.error) == null ? void 0 : c.body;
        if (p) {
          const y = await S(p);
          if (y) {
            const d = x(y);
            o.error = d;
          }
        }
      }
      if (i == null || i.service(l, 1, "Error", s, o), E.report(o), r.onError && await r.onError(r, o), u.abort(`Error abort ${s}`), delete this._connections[s], !t || !await t(r, o)) throw o;
      return await this.fetch(r);
    }
  }
};
export {
  W as braidService,
  I as buildQueryString,
  M as initConfig,
  i as logger,
  D as setIntervalWithTimeout
};
