import { createAction as f } from "@waitroom/react-utils";
import { useSelector as U, useDispatch as g } from "react-redux";
import it from "deepmerge";
import { produce as rt } from "immer";
import { authApiService as T, authBaseEndpoint as G, httpService as at, braidService as ot, userApiService as L } from "@waitroom/common-api";
import { STORAGE_AUTH_TOKENS as C, LOGGER_SERVICE_AUTH as _, LOGGER_SERVICE_BRAID as z } from "@waitroom/models";
import { tryCatchAsync as F, withLock as V, retry as ut, isFunction as ht } from "@waitroom/utils";
import { jwtDecode as dt } from "jwt-decode";
import { useMemo as ft } from "react";
import { useMutation as P } from "@tanstack/react-query";
import { bearerValue as y } from "@waitroom/http-client";
import { createListenerMiddleware as Tt } from "@reduxjs/toolkit";
import { getRequestData as O } from "@waitroom/react-query";
const x = "refresh-token", p = "auth/INIT_AUTH", B = "auth/SETUP_AUTH", j = "auth/LOGOUT", q = "auth/SET_CURRENT_USER", W = "auth/UPDATE_CURRENT_USER", Bt = "auth/SET_CURRENT_USER_SUBSCRIPTION_PLAN", K = "auth/SET_AUTH_STATE", J = "auth/UPDATE_AUTH_STATE", Q = "auth/SET_SUBSCRIPTION_PLAN", X = "auth/REFRESH_SUBSCRIPTION_PLAN", Y = "auth/AUTHENTICATED", St = "auth/UN_AUTHENTICATED", Z = "auth/GET_CURRENT_USER", jt = f(p), $ = f(B), v = f(K), l = f(J), lt = f(Z), qt = f(q), Wt = f(W), mt = f(j), b = f(Y), I = f(St), tt = f(Q), Kt = f(X);
let a;
const Jt = (e) => {
  a = e.logger;
}, _t = (e) => e.reduce(
  (t, s) => ({
    ...t,
    [s]: !0
  }),
  {}
), w = {
  userId: void 0,
  isAuthenticated: !1,
  status: "initialized",
  isGuest: !1
}, S = {
  _refreshPromise: void 0,
  _cache: {},
  config: {},
  init: function(e) {
    this.config = e;
  },
  getAnonymous: () => w,
  getDecodedToken: function(e) {
    if (this._cache[e]) return this._cache[e];
    const t = dt(e);
    if (t)
      return this._cache[e] = t, t;
  },
  setup: async function(e, t = !0) {
    const s = e || this.getStorageTokens();
    if (!(s != null && s.accessToken)) return w;
    const n = this.decode(s, 600);
    n != null && n.accessToken && this.saveStorageTokens({
      accessToken: n.accessToken,
      refreshToken: n.refreshToken
    });
    const c = n || (t ? await this.refresh(s.refreshToken) : void 0);
    return c != null && c.accessToken ? c : this.logout();
  },
  getStorageTokens: function() {
    var e;
    return (e = this.config.storage) == null ? void 0 : e.getParsed(
      C
    );
  },
  saveStorageTokens: function(e) {
    var t;
    (t = this.config.storage) == null || t.set(C, e);
  },
  clearStorageTokens: function() {
    var e;
    (e = this.config.storage) == null || e.remove(C);
  },
  isTokenValid: function(e, t = 0) {
    const s = e ? this.getDecodedToken(e) : void 0;
    return !!s && !this.hasExpired(s.exp, t);
  },
  _refreshFn: async function(e) {
    var i;
    if (!e) return;
    const t = await F(
      () => T.refreshToken({
        data: {
          refreshToken: e
        }
      })
    )();
    if ((t == null ? void 0 : t.code) === 401) return;
    const { authToken: s, refreshToken: n } = ((i = t == null ? void 0 : t.data) == null ? void 0 : i.data) || {};
    if (!s) return;
    const c = { accessToken: s, refreshToken: n }, r = this.decode(c);
    if (r)
      return this.saveStorageTokens(c), r;
  },
  refresh: async function(e) {
    var c;
    if (a == null || a.service(
      _,
      1,
      "Refreshing auth token",
      e
    ), this._refreshPromise) return await this._refreshPromise;
    const t = (c = this.getStorageTokens()) == null ? void 0 : c.refreshToken, s = e || t;
    if (!s) return;
    this._refreshPromise = this._refreshFn(s);
    const n = await this._refreshPromise || await this._refreshFn(t);
    return a == null || a.service(
      _,
      1,
      "Refreshed auth token",
      n == null ? void 0 : n.refreshToken
    ), this._refreshPromise = void 0, n;
  },
  /** Decode token with expiration tolerance in seconds */
  decode: function(e, t) {
    const {
      userID: s,
      guestSurrogateID: n,
      guestEmail: c,
      guestFullName: r,
      exp: i,
      roleIDs: u = []
    } = this.getDecodedToken(e.accessToken) || {}, o = s || (n ? String(n) : void 0);
    if (!o || this.hasExpired(i, t)) return;
    const d = !s, m = {
      ...e,
      userId: o,
      isAuthenticated: !0,
      expires: i,
      agentId: o,
      isGuest: d,
      roles: _t(u),
      status: "initialized"
    };
    return d && (m.currentUser = {
      id: String(n),
      firstName: r || "",
      email: c || ""
    }), m;
  },
  logout: async function() {
    return await T.logout({ throwError: !1 }), this.clearStorageTokens(), w;
  },
  /** Check if token has expired with expiration tolerance in seconds */
  hasExpired: (e, t = 0) => !e || Date.now() + t * 1e3 > e * 1e3
}, et = S.getAnonymous(), st = {
  ...et,
  status: void 0
}, Qt = (e = st, t) => rt(e, (s) => {
  var n;
  switch (t.type) {
    case K:
      return t.payload || et;
    case J:
      return s = { ...s, ...t.payload }, s;
    case q:
      return s.currentUser = t.payload, s.userId = (n = t.payload) == null ? void 0 : n.id, s;
    case W:
      return t.payload && (s.currentUser = it(
        s.currentUser || {},
        t.payload
      ), t.payload.id && (s.userId = t.payload.id)), s;
    case Q:
      return !s.currentUser || !t.payload || (s.currentUser.subscriptionPlan = t.payload), s;
    default:
      return e;
  }
}), h = (e) => e && e.auth ? e.auth : st, Et = (e) => h(e).isAuthenticated, kt = (e) => h(e).isGuest, Ut = (e) => h(e).status, Xt = (e) => h(e).userId, E = (e) => h(e).currentUser, Yt = (e) => {
  var t;
  return (t = E(e)) == null ? void 0 : t.id;
}, Zt = (e) => {
  var t;
  return (t = E(e)) == null ? void 0 : t.customerID;
}, $t = (e) => {
  var t;
  return (t = E(e)) == null ? void 0 : t.email;
}, At = (e) => h(e).roles, nt = (e) => {
  var t;
  return (t = E(e)) == null ? void 0 : t.notificationSettings;
}, te = (e) => {
  var t;
  return (t = E(e)) == null ? void 0 : t.subscriptionPlan;
}, ee = (e) => {
  var t;
  return (t = E(e)) == null ? void 0 : t.team;
}, se = (e) => {
  var t;
  return (t = nt(e)) == null ? void 0 : t.isPushNotificationEnabledGlobal;
}, ne = (e) => {
  var t;
  return (t = nt(e)) == null ? void 0 : t.isEmailNotificationEnabledGlobal;
}, ce = (e) => {
  const { accessToken: t, refreshToken: s, expires: n } = h(e);
  return { accessToken: t, refreshToken: s, expires: n };
}, ie = (e) => h(e).accessToken, re = (e) => h(e).agentId, ae = () => {
  const e = U(h);
  return {
    state: e,
    isLoading: !e.status || e.status === "loading"
  };
}, oe = (e) => {
  const t = U(Et), s = U(kt), n = U(Ut), c = U(At), [r, i] = ft(() => e ? t ? (typeof e == "string" ? [e] : e).reduce(
    (o, d) => {
      const m = !!c && !!c[d];
      return o[0][d] = m, o[1] = o[1] && m, o;
    },
    [{}, !0]
  ) : [{}, !1] : [{}, !0], [e, t, c]);
  return {
    isAuthenticated: t,
    isGuest: s,
    status: n,
    isLoading: !n || n === "loading",
    hasAccess: i,
    access: r,
    roles: c
  };
}, yt = (e) => ({
  ...e,
  mutationFn: async (t) => {
    const s = {
      ...t,
      email: t.email.toLowerCase()
    };
    return t.clientId && t.redirectUrl ? T.requestOAuthOTP({
      data: {
        ...s,
        redirect_uri: t.redirectUrl,
        client_id: t.clientId,
        state: t.state,
        codeChallenge: t.codeChallenge
      }
    }) : T.requestOTP({ data: s });
  }
}), Pt = (e) => ({
  ...e,
  mutationFn: async ({ email: t, otp: s, clientId: n, state: c, codeChallenge: r }) => n ? T.verifyOAuthOTP({
    data: {
      email: t.toLowerCase(),
      otp: s,
      client_id: n,
      state: c,
      codeChallenge: r
    }
  }) : T.verifyOTP({
    data: {
      email: t.toLowerCase(),
      otp: s
    }
  })
}), vt = (e) => ({
  ...e,
  mutationFn: async ({
    token: t,
    code: s,
    type: n,
    userDetails: c,
    clientId: r,
    redirectUrl: i,
    state: u,
    codeChallenge: o
  }) => {
    if (!n || !s && !t) throw new Error("Invalid request");
    return r && i ? t ? T.verifyOAuthTokenProvider({
      data: {
        token: t,
        type: n,
        userDetails: c,
        client_id: r,
        redirect_uri: i,
        state: u,
        codeChallenge: o
      }
    }) : T.verifyOAuthCodeProvider({
      data: {
        code: s || "",
        type: n,
        client_id: r,
        redirect_uri: i,
        state: u,
        codeChallenge: o
      }
    }) : t ? T.verifyAuthTokenProvider({
      data: { token: t, type: n, userDetails: c }
    }) : T.verifyAuthCodeProvider({
      data: { code: s || "", type: n }
    });
  }
}), It = (e) => ({
  ...e,
  mutationFn: async (t) => T.logout(t)
}), ue = (e) => {
  const t = g();
  return P(
    It({
      ...e,
      onMutate: (...s) => {
        t(l({ status: "loading" })), e != null && e.onMutate && e.onMutate(...s);
      },
      onSuccess: (...s) => {
        t(l(S.getAnonymous())), t(I()), e != null && e.onSuccess && e.onSuccess(...s);
      }
    })
  );
}, he = (e) => P(yt(e)), de = ({
  onRedirect: e,
  ...t
}) => {
  const s = g();
  return P(
    vt({
      ...t,
      onMutate: (...n) => {
        s(l({ status: "loading" })), t != null && t.onMutate && t.onMutate(...n);
      },
      onError: (n, c, r) => {
        s(l({ status: "initialized" })), t != null && t.onError && t.onError(n, c, r);
      },
      onSuccess: (n, c, r) => {
        var u;
        if (s(l({ status: "initialized" })), !(n != null && n.success)) return;
        const i = (u = n.data) == null ? void 0 : u.data;
        if (i) {
          if (c.clientId && c.redirectUrl) {
            "redirect_uri" in i && i.redirect_uri && e(i.redirect_uri);
            return;
          }
          if ("authToken" in i && i.authToken) {
            const { authToken: o, refreshToken: d } = i;
            s(
              $({
                accessToken: o,
                refreshToken: d
              })
            );
          }
          t != null && t.onSuccess && t.onSuccess(n, c, r);
        }
      }
    })
  );
}, fe = ({ onRedirect: e, ...t }) => {
  const s = g();
  return P(
    Pt({
      ...t,
      onMutate: (...n) => {
        s(l({ status: "loading" })), t != null && t.onMutate && t.onMutate(...n);
      },
      onSuccess: (n, c, r) => {
        var u;
        if (s(l({ status: "initialized" })), !(n != null && n.success)) return;
        const i = (u = n.data) == null ? void 0 : u.data;
        if (i) {
          if (c.clientId) {
            "redirect_uri" in i && i.redirect_uri && e(i.redirect_uri);
            return;
          }
          if ("authToken" in i && i.authToken) {
            const { authToken: o, refreshToken: d } = i || {};
            if (!o) return;
            const m = c.redirectUrl;
            s(
              $({
                ...c,
                accessToken: o,
                refreshToken: d,
                onCompleted: m ? () => e(m) : void 0
              })
            );
          }
          t != null && t.onSuccess && t.onSuccess(n, c, r);
        }
      }
    })
  );
}, ct = () => {
  const e = S.getStorageTokens();
  return e != null && e.accessToken ? [S.isTokenValid(e.accessToken, 30), e] : [!1, void 0];
}, Rt = V(
  x,
  async (e) => {
    a == null || a.service(_, 3, "Refresh token interceptor lock");
    const [t, s] = ct();
    if (t || !(s != null && s.refreshToken))
      return s == null ? void 0 : s.accessToken;
    const n = await S.refresh(s == null ? void 0 : s.refreshToken);
    if (n != null && n.accessToken)
      return e == null || e(n), n.accessToken;
  }
), R = async (e, t) => {
  const [s, n] = ct();
  return s && e !== y(n.accessToken) ? n.accessToken : Rt(t);
}, Te = ({ onAuthUpdated: e }) => async (t, s) => {
  var n;
  if (a == null || a.service(
    z,
    3,
    "Error interceptor",
    t.key,
    s
  ), !t._retry && typeof s == "object" && ((s == null ? void 0 : s.status) === 401 || (s == null ? void 0 : s.code) === 401)) {
    const c = await R(
      (n = t.headers) == null ? void 0 : n.Authorization,
      e
    );
    return c ? (t._retry = !0, t.headers = {
      ...t.headers,
      Authorization: y(c)
    }, !0) : !1;
  }
  return !1;
}, Se = ({ onAuthUpdated: e }) => async (t, s) => {
  var n;
  if (a == null || a.service(
    z,
    3,
    "Retry interceptor",
    t.key,
    s
  ), s) {
    const c = await R(
      (n = t.headers) == null ? void 0 : n.Authorization,
      e
    );
    return c ? (t._retry = !0, t.headers = {
      ...t.headers,
      Authorization: y(c)
    }, !0) : !1;
  }
  return !1;
}, le = ({ onRetry: e, onAuthUpdated: t }) => async (s) => {
  var c, r;
  e == null || e(s);
  const n = s == null ? void 0 : s.config;
  if (a == null || a.logWith(3, "Axios auth interceptor", s), ((c = s == null ? void 0 : s.response) == null ? void 0 : c.status) === 401 && !(n != null && n._retry) && !String(n.url).startsWith(G)) {
    n._retry = !0;
    const i = (r = n.headers.Authorization) == null ? void 0 : r.toString(), u = await R(i, t);
    return a == null || a.service(
      _,
      3,
      "Axios auth interceptor",
      u
    ), u ? (n.headers.Authorization = y(u), at.client(n)) : Promise.reject(s);
  }
  return Promise.reject(s);
}, me = ({ onRetry: e, onAuthUpdated: t }) => async (s, n) => {
  var i, u;
  e == null || e(n);
  const c = s;
  if ((n && typeof n == "object" ? (n == null ? void 0 : n.code) || (n == null ? void 0 : n.status) : n) === 401 && !(c != null && c.retried) && !String(c.url).startsWith(G)) {
    c.retried = !0;
    const o = (u = (i = c.headers) == null ? void 0 : i.Authorization) == null ? void 0 : u.toString(), d = await R(o, t);
    return d ? (c.headers = {
      ...c.headers,
      Authorization: y(d)
    }, ot.fetch(c)) : Promise.reject(n);
  }
  return Promise.reject(n);
}, D = (e) => !!e.isAuthenticated && !!e.userId && !e.isGuest, k = Tt();
let A;
const Ct = (e, t) => new Promise(async (s) => {
  A && clearTimeout(A);
  const { expires: n, refreshToken: c } = e;
  if (!n || !c) return;
  const r = ~~(6e4 + Math.random() * 24e4), i = Math.max(0, n * 1e3 - Date.now() - r);
  a == null || a.service(
    _,
    3,
    "Refresh auth token setup timeout",
    {
      time: i
    }
  ), A = setTimeout(
    V(x, async () => {
      a == null || a.service(_, 3, "Refresh auth token timeout");
      const o = await S.refresh(e.refreshToken) || await S.logout();
      return o && (t.dispatch(l(o)), t.dispatch(
        o.isAuthenticated ? b() : I()
      )), s(o);
    }),
    i
  );
}), M = async (e, t) => {
  const s = await Ct(e, t);
  return s != null && s.isAuthenticated && M(s, t), s;
}, wt = 6e5;
let N;
const Nt = (e) => new Promise(
  async (t) => {
    N = setTimeout(async () => {
      const s = await L.getSubscriptionPlan(e);
      return t(s);
    }, wt);
  }
);
async function H(e) {
  N && clearTimeout(N);
  const t = h(e.getState());
  if (!D(t)) return;
  const s = await Nt(t.userId), n = O(s);
  n && s.success && e.dispatch(tt(n)), H(e);
}
k.startListening({
  type: p,
  effect: async (e, t) => {
    t.cancelActiveListeners();
    const s = await S.setup();
    s.refreshToken && t.fork(async () => {
      M(s, t);
    }), t.dispatch(v(s)), t.dispatch(
      s.isAuthenticated ? b() : I()
    );
  }
});
k.startListening({
  type: Z,
  effect: async (e, t) => {
    var r;
    t.cancelActiveListeners();
    const s = h(t.getState());
    if (!D(s)) return;
    const n = await F(
      () => ut(L.getCurrent, {
        retries: 2
      })
    )();
    if (n != null && n.code && n.code >= 400 && n.code <= 500) {
      t.dispatch(mt());
      return;
    }
    const c = (r = O(n)) == null ? void 0 : r.user;
    if (c) {
      const i = {
        id: c.userID,
        firstName: c.userFirstName,
        lastName: c.userLastName,
        ...c
      }, o = { ...h(t.getState()), currentUser: i };
      t.dispatch(v(o)), t.fork(() => H(t));
      return;
    }
  }
});
k.startListening({
  type: B,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  effect: async (e, t) => {
    t.cancelActiveListeners(), a == null || a.service(_, 1, "SETUP_AUTH");
    const { accessToken: s, refreshToken: n, onCompleted: c } = e.payload || {}, r = await S.setup({
      accessToken: s,
      refreshToken: n
    });
    r.refreshToken && t.fork(() => M(r, t)), r.isAuthenticated && t.fork(() => H(t)), t.dispatch(v(r)), t.dispatch(b()), t.dispatch(l({ status: "initialized" })), c && (ht(c) ? c() : t.dispatch(c));
  }
});
k.startListening({
  type: Y,
  effect: async (e, t) => {
    t.cancelActiveListeners(), t.dispatch(lt());
  }
});
k.startListening({
  type: j,
  effect: async (e, t) => {
    t.cancelActiveListeners(), t.dispatch(l({ status: "loading" })), A && clearTimeout(A), h(t.getState()).isAuthenticated && await S.logout(), t.dispatch(v(S.getAnonymous())), t.dispatch(I());
  }
});
k.startListening({
  type: X,
  effect: async (e, t) => {
    t.cancelActiveListeners();
    const s = h(t.getState());
    if (!D(s)) return;
    const n = await L.getSubscriptionPlan(s.userId), c = O(n);
    c && n.success && t.dispatch(tt(c));
  }
});
export {
  Y as AUTHENTICATED,
  Z as GET_CURRENT_USER,
  p as INIT_AUTH,
  j as LOGOUT,
  X as REFRESH_SUBSCRIPTION_PLAN,
  B as SETUP_AUTH,
  K as SET_AUTH_STATE,
  q as SET_CURRENT_USER,
  Bt as SET_CURRENT_USER_SUBSCRIPTION_PLAN,
  Q as SET_SUBSCRIPTION_PLAN,
  St as UN_AUTHENTICATED,
  J as UPDATE_AUTH_STATE,
  W as UPDATE_CURRENT_USER,
  st as authInitialState,
  k as authListenerMiddleware,
  Qt as authReducer,
  et as authResetState,
  S as authService,
  b as authenticated,
  le as axiosAuthInterceptor,
  me as braidOnError,
  Te as braidOnErrorInterceptor,
  Se as braidOnRetryInterceptor,
  R as getAccessToken,
  lt as getCurrentUser,
  jt as initAuth,
  Jt as initConfig,
  a as logger,
  mt as logout,
  It as logoutMutation,
  Kt as refreshSubscriptionPlan,
  x as refreshTokenLockName,
  yt as requestOTPMutation,
  re as selectAgentId,
  ie as selectAuthAccessToken,
  h as selectAuthState,
  Ut as selectAuthStatus,
  ce as selectAuthToken,
  Xt as selectAuthUserId,
  E as selectCurrentUser,
  Zt as selectCurrentUserCustomerId,
  $t as selectCurrentUserEmail,
  ne as selectCurrentUserEmailSetting,
  Yt as selectCurrentUserId,
  se as selectCurrentUserPushSetting,
  At as selectCurrentUserRoles,
  nt as selectCurrentUserSettings,
  te as selectCurrentUserSubscriptionPlan,
  ee as selectCurrentUserTeam,
  Et as selectIsAuthenticated,
  kt as selectIsCurrentUserGuest,
  v as setAuthState,
  qt as setCurrentUser,
  tt as setSubscriptionPlan,
  $ as setupAuth,
  I as unauthenticated,
  l as updateAuthState,
  Wt as updateCurrentUser,
  ae as useAuth,
  oe as useHasAccess,
  ue as useLogout,
  he as useRequestOTP,
  de as useVerifyAuthProvider,
  fe as useVerifyOTP,
  vt as verifyAuthProviderMutation,
  Pt as verifyOTPMutation
};
