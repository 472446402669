import {
  authService,
  AuthState,
  axiosAuthInterceptor,
  braidOnErrorInterceptor,
  braidOnRetryInterceptor,
  getAccessToken,
  initConfig as initAuthConfig,
  updateAuthState,
} from '@waitroom/auth';
import { initConfig as initBraidConfig } from '@waitroom/braid';
import { initConfig as initChatConfig } from '@waitroom/chat';
import {
  featureFlagsService,
  initConfig as initCommon,
} from '@waitroom/common';
import { braidService, httpService, initCommonApi } from '@waitroom/common-api';
import { errorService } from '@waitroom/error-service';
import { logger } from '@waitroom/logger';
import { initConfig as initRQConfig } from '@waitroom/react-query';
import { initConfig as initStreamConfig } from '@waitroom/stream';
import { config } from '.';
import { useGeneralSettingsStore } from '../modules/core/hooks/useGeneralSettings';
import { fetchEventSource } from '../modules/core/services/fetch/fetchEventSource';
import store from '../modules/core/services/redux/configureStore';
import { sentryService } from '../modules/core/services/sentry';
import { storageService } from '../modules/core/services/storage';
import { getFingerPrint } from '../modules/core/utils/finger-print';
import { getClientDetails } from './clientDetails';

const getFP = getFingerPrint();

// setup logger
logger.enabled = !config.isProd;
logger.config.logLevel =
  useGeneralSettingsStore.getState().debug?.logLevel ?? 1;
logger.logWith(1, `ENV: ${config.customEnv}`);
// setup other packages loader
const conf = { logger };
initAuthConfig(conf);
initBraidConfig(conf);
initChatConfig(conf);
initStreamConfig(conf);
initRQConfig(conf);

// enable all feature flags
featureFlagsService.override = config.isDev ? true : undefined;
// setup sentry and error service
sentryService.init();

const onAuthUpdated = (state: AuthState) => {
  // update auth state
  store.dispatch(updateAuthState(state));
};
// setup auth interceptor for token refresh
httpService.client.interceptors.response.use(
  (r) => r,
  axiosAuthInterceptor({
    onAuthUpdated,
  }),
);
// setup braid interceptor for token refresh
braidService.config.interceptors.onError = braidOnErrorInterceptor({
  onAuthUpdated,
});
braidService.config.interceptors.onRetry = braidOnRetryInterceptor({
  onAuthUpdated,
});

// setup auth service
authService.init({ storage: storageService });

// init api config
initCommonApi({
  baseURL: config.domains.wormhole,
  version: 'v1.0',
  getAuthToken: async () =>
    (await getAccessToken(undefined, (state) => {
      // update auth state
      store.dispatch(updateAuthState(state));
    })) || '',
  getFingerPrint: getFP,
  getClientDetails,
  fetchEventSource,
  sanityUrl: config.sanity.apiUrl,
});

// init common config
initCommon({
  domains: {
    luxor: config.domains.luxor,
    wormhole: config.domains.wormhole,
  },
  featureFlags: config.featureFlags,
  storage: storageService,
  dateTime: config.dateTime,
  reportError: errorService.report,
  logger: logger,
});
