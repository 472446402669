import { SessionAccessRuleStatus as J } from "@waitroom/models";
import { aiApiService as ge, buildSuccessRequestResponse as X, calendarApiService as Qe, cmsApiService as ve, sessionApiService as b, integrationsApiService as ae, meetingMemoryApiService as ce, recordingApiService as De, sessionAccessApiService as ee, userApiService as ye, authApiService as be, subscriptionPlanApiService as He, tokenApiService as Ie, userMeetingTypesService as Ge } from "@waitroom/common-api";
import { applyPatch as we, deepClone as L } from "fast-json-patch";
import { errorService as Be } from "@waitroom/error-service";
import { useInfiniteQuery as Le, useQuery as ke, useQueryClient as Ue, QueryObserver as Re } from "@tanstack/react-query";
import { useEffect as x, useRef as xe, useState as Me } from "react";
var We = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {}, Ve = { exports: {} };
(function(e, t) {
  (function(s, n) {
    e.exports = n();
  })(We, function() {
    var s = 1e3, n = 6e4, r = 36e5, o = "millisecond", i = "second", y = "minute", S = "hour", _ = "day", p = "week", A = "month", I = "quarter", P = "year", N = "date", Ce = "Invalid Date", Pe = /^(\d{4})[-/]?(\d{1,2})?[-/]?(\d{0,2})[Tt\s]*(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?[.:]?(\d+)?$/, qe = /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a|A|m{1,2}|s{1,2}|Z{1,2}|SSS/g, Ye = { name: "en", weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"), months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"), ordinal: function(d) {
      var c = ["th", "st", "nd", "rd"], u = d % 100;
      return "[" + d + (c[(u - 20) % 10] || c[u] || c[0]) + "]";
    } }, de = function(d, c, u) {
      var E = String(d);
      return !E || E.length >= c ? d : "" + Array(c + 1 - E.length).join(u) + d;
    }, Ne = { s: de, z: function(d) {
      var c = -d.utcOffset(), u = Math.abs(c), E = Math.floor(u / 60), a = u % 60;
      return (c <= 0 ? "+" : "-") + de(E, 2, "0") + ":" + de(a, 2, "0");
    }, m: function d(c, u) {
      if (c.date() < u.date()) return -d(u, c);
      var E = 12 * (u.year() - c.year()) + (u.month() - c.month()), a = c.clone().add(E, A), f = u - a < 0, m = c.clone().add(E + (f ? -1 : 1), A);
      return +(-(E + (u - a) / (f ? a - m : m - a)) || 0);
    }, a: function(d) {
      return d < 0 ? Math.ceil(d) || 0 : Math.floor(d);
    }, p: function(d) {
      return { M: A, y: P, w: p, d: _, D: N, h: S, m: y, s: i, ms: o, Q: I }[d] || String(d || "").toLowerCase().replace(/s$/, "");
    }, u: function(d) {
      return d === void 0;
    } }, V = "en", G = {};
    G[V] = Ye;
    var Ae = "$isDayjsObject", fe = function(d) {
      return d instanceof ne || !(!d || !d[Ae]);
    }, te = function d(c, u, E) {
      var a;
      if (!c) return V;
      if (typeof c == "string") {
        var f = c.toLowerCase();
        G[f] && (a = f), u && (G[f] = u, a = f);
        var m = c.split("-");
        if (!a && m.length > 1) return d(m[0]);
      } else {
        var C = c.name;
        G[C] = c, a = C;
      }
      return !E && a && (V = a), a || !E && V;
    }, R = function(d, c) {
      if (fe(d)) return d.clone();
      var u = typeof c == "object" ? c : {};
      return u.date = d, u.args = arguments, new ne(u);
    }, l = Ne;
    l.l = te, l.i = fe, l.w = function(d, c) {
      return R(d, { locale: c.$L, utc: c.$u, x: c.$x, $offset: c.$offset });
    };
    var ne = function() {
      function d(u) {
        this.$L = te(u.locale, null, !0), this.parse(u), this.$x = this.$x || u.x || {}, this[Ae] = !0;
      }
      var c = d.prototype;
      return c.parse = function(u) {
        this.$d = function(E) {
          var a = E.date, f = E.utc;
          if (a === null) return /* @__PURE__ */ new Date(NaN);
          if (l.u(a)) return /* @__PURE__ */ new Date();
          if (a instanceof Date) return new Date(a);
          if (typeof a == "string" && !/Z$/i.test(a)) {
            var m = a.match(Pe);
            if (m) {
              var C = m[2] - 1 || 0, O = (m[7] || "0").substring(0, 3);
              return f ? new Date(Date.UTC(m[1], C, m[3] || 1, m[4] || 0, m[5] || 0, m[6] || 0, O)) : new Date(m[1], C, m[3] || 1, m[4] || 0, m[5] || 0, m[6] || 0, O);
            }
          }
          return new Date(a);
        }(u), this.init();
      }, c.init = function() {
        var u = this.$d;
        this.$y = u.getFullYear(), this.$M = u.getMonth(), this.$D = u.getDate(), this.$W = u.getDay(), this.$H = u.getHours(), this.$m = u.getMinutes(), this.$s = u.getSeconds(), this.$ms = u.getMilliseconds();
      }, c.$utils = function() {
        return l;
      }, c.isValid = function() {
        return this.$d.toString() !== Ce;
      }, c.isSame = function(u, E) {
        var a = R(u);
        return this.startOf(E) <= a && a <= this.endOf(E);
      }, c.isAfter = function(u, E) {
        return R(u) < this.startOf(E);
      }, c.isBefore = function(u, E) {
        return this.endOf(E) < R(u);
      }, c.$g = function(u, E, a) {
        return l.u(u) ? this[E] : this.set(a, u);
      }, c.unix = function() {
        return Math.floor(this.valueOf() / 1e3);
      }, c.valueOf = function() {
        return this.$d.getTime();
      }, c.startOf = function(u, E) {
        var a = this, f = !!l.u(E) || E, m = l.p(u), C = function(B, F) {
          var Q = l.w(a.$u ? Date.UTC(a.$y, F, B) : new Date(a.$y, F, B), a);
          return f ? Q : Q.endOf(_);
        }, O = function(B, F) {
          return l.w(a.toDate()[B].apply(a.toDate("s"), (f ? [0, 0, 0, 0] : [23, 59, 59, 999]).slice(F)), a);
        }, M = this.$W, D = this.$M, q = this.$D, k = "set" + (this.$u ? "UTC" : "");
        switch (m) {
          case P:
            return f ? C(1, 0) : C(31, 11);
          case A:
            return f ? C(1, D) : C(0, D + 1);
          case p:
            var w = this.$locale().weekStart || 0, j = (M < w ? M + 7 : M) - w;
            return C(f ? q - j : q + (6 - j), D);
          case _:
          case N:
            return O(k + "Hours", 0);
          case S:
            return O(k + "Minutes", 1);
          case y:
            return O(k + "Seconds", 2);
          case i:
            return O(k + "Milliseconds", 3);
          default:
            return this.clone();
        }
      }, c.endOf = function(u) {
        return this.startOf(u, !1);
      }, c.$set = function(u, E) {
        var a, f = l.p(u), m = "set" + (this.$u ? "UTC" : ""), C = (a = {}, a[_] = m + "Date", a[N] = m + "Date", a[A] = m + "Month", a[P] = m + "FullYear", a[S] = m + "Hours", a[y] = m + "Minutes", a[i] = m + "Seconds", a[o] = m + "Milliseconds", a)[f], O = f === _ ? this.$D + (E - this.$W) : E;
        if (f === A || f === P) {
          var M = this.clone().set(N, 1);
          M.$d[C](O), M.init(), this.$d = M.set(N, Math.min(this.$D, M.daysInMonth())).$d;
        } else C && this.$d[C](O);
        return this.init(), this;
      }, c.set = function(u, E) {
        return this.clone().$set(u, E);
      }, c.get = function(u) {
        return this[l.p(u)]();
      }, c.add = function(u, E) {
        var a, f = this;
        u = Number(u);
        var m = l.p(E), C = function(D) {
          var q = R(f);
          return l.w(q.date(q.date() + Math.round(D * u)), f);
        };
        if (m === A) return this.set(A, this.$M + u);
        if (m === P) return this.set(P, this.$y + u);
        if (m === _) return C(1);
        if (m === p) return C(7);
        var O = (a = {}, a[y] = n, a[S] = r, a[i] = s, a)[m] || 1, M = this.$d.getTime() + u * O;
        return l.w(M, this);
      }, c.subtract = function(u, E) {
        return this.add(-1 * u, E);
      }, c.format = function(u) {
        var E = this, a = this.$locale();
        if (!this.isValid()) return a.invalidDate || Ce;
        var f = u || "YYYY-MM-DDTHH:mm:ssZ", m = l.z(this), C = this.$H, O = this.$m, M = this.$M, D = a.weekdays, q = a.months, k = a.meridiem, w = function(F, Q, z, se) {
          return F && (F[Q] || F(E, f)) || z[Q].slice(0, se);
        }, j = function(F) {
          return l.s(C % 12 || 12, F, "0");
        }, B = k || function(F, Q, z) {
          var se = F < 12 ? "AM" : "PM";
          return z ? se.toLowerCase() : se;
        };
        return f.replace(qe, function(F, Q) {
          return Q || function(z) {
            switch (z) {
              case "YY":
                return String(E.$y).slice(-2);
              case "YYYY":
                return l.s(E.$y, 4, "0");
              case "M":
                return M + 1;
              case "MM":
                return l.s(M + 1, 2, "0");
              case "MMM":
                return w(a.monthsShort, M, q, 3);
              case "MMMM":
                return w(q, M);
              case "D":
                return E.$D;
              case "DD":
                return l.s(E.$D, 2, "0");
              case "d":
                return String(E.$W);
              case "dd":
                return w(a.weekdaysMin, E.$W, D, 2);
              case "ddd":
                return w(a.weekdaysShort, E.$W, D, 3);
              case "dddd":
                return D[E.$W];
              case "H":
                return String(C);
              case "HH":
                return l.s(C, 2, "0");
              case "h":
                return j(1);
              case "hh":
                return j(2);
              case "a":
                return B(C, O, !0);
              case "A":
                return B(C, O, !1);
              case "m":
                return String(O);
              case "mm":
                return l.s(O, 2, "0");
              case "s":
                return String(E.$s);
              case "ss":
                return l.s(E.$s, 2, "0");
              case "SSS":
                return l.s(E.$ms, 3, "0");
              case "Z":
                return m;
            }
            return null;
          }(F) || m.replace(":", "");
        });
      }, c.utcOffset = function() {
        return 15 * -Math.round(this.$d.getTimezoneOffset() / 15);
      }, c.diff = function(u, E, a) {
        var f, m = this, C = l.p(E), O = R(u), M = (O.utcOffset() - this.utcOffset()) * n, D = this - O, q = function() {
          return l.m(m, O);
        };
        switch (C) {
          case P:
            f = q() / 12;
            break;
          case A:
            f = q();
            break;
          case I:
            f = q() / 3;
            break;
          case p:
            f = (D - M) / 6048e5;
            break;
          case _:
            f = (D - M) / 864e5;
            break;
          case S:
            f = D / r;
            break;
          case y:
            f = D / n;
            break;
          case i:
            f = D / s;
            break;
          default:
            f = D;
        }
        return a ? f : l.a(f);
      }, c.daysInMonth = function() {
        return this.endOf(A).$D;
      }, c.$locale = function() {
        return G[this.$L];
      }, c.locale = function(u, E) {
        if (!u) return this.$L;
        var a = this.clone(), f = te(u, E, !0);
        return f && (a.$L = f), a;
      }, c.clone = function() {
        return l.w(this.$d, this);
      }, c.toDate = function() {
        return new Date(this.valueOf());
      }, c.toJSON = function() {
        return this.isValid() ? this.toISOString() : null;
      }, c.toISOString = function() {
        return this.$d.toISOString();
      }, c.toString = function() {
        return this.$d.toUTCString();
      }, d;
    }(), Oe = ne.prototype;
    return R.prototype = Oe, [["$ms", o], ["$s", i], ["$m", y], ["$H", S], ["$W", _], ["$M", A], ["$y", P], ["$D", N]].forEach(function(d) {
      Oe[d[1]] = function(c) {
        return this.$g(c, d[0], d[1]);
      };
    }), R.extend = function(d, c) {
      return d.$i || (d(c, ne, R), d.$i = !0), R;
    }, R.locale = te, R.isDayjs = fe, R.unix = function(d) {
      return R(1e3 * d);
    }, R.en = G[V], R.Ls = G, R.p = {}, R;
  });
})(Ve);
const Te = (e) => typeof e == "function", Z = ({
  client: e,
  key: t,
  fn: s,
  skipEmpty: n
}) => {
  const r = e.getQueryData(t);
  n && r === void 0 || e.setQueryData(t, s(r));
}, re = (e) => Z({
  ...e,
  fn: (t) => ({
    code: 200,
    success: !0,
    ...t,
    data: Te(e.dataOrFn) ? e.dataOrFn(t == null ? void 0 : t.data) : e.dataOrFn
  })
}), je = {
  update: re,
  addToArray: (e) => re({
    ...e,
    dataOrFn: (t) => [
      ...Array.isArray(e.data) ? e.data : [e.data],
      ...t || []
    ]
  }),
  updateInArray: ({
    data: e,
    keyId: t,
    ...s
  }) => re({
    ...s,
    dataOrFn: (n) => n == null ? void 0 : n.map((r) => r[t] === e[t] ? e : r)
  }),
  removeFromArray: ({
    id: e,
    keyId: t,
    ...s
  }) => re({
    ...s,
    dataOrFn: (n) => n == null ? void 0 : n.filter((r) => r[t] !== e)
  }),
  addToPages: (e) => Z({
    ...e,
    fn: (t) => {
      var n, r, o;
      const s = {
        pageParams: [...(t == null ? void 0 : t.pageParams) || []],
        pages: [...(t == null ? void 0 : t.pages) || []]
      };
      return s.pages[0] = {
        ...s.pages[0],
        data: {
          code: 200,
          success: !0,
          ...(n = s.pages[0]) == null ? void 0 : n.data,
          data: e.updateFn((o = (r = s.pages[0]) == null ? void 0 : r.data) == null ? void 0 : o.data)
        }
      }, s;
    }
  }),
  updateInPages: (e) => Z({
    ...e,
    fn: (t) => {
      var n, r;
      if (!t || !((n = t.pages) != null && n.length)) return t;
      const s = {
        pageParams: [...t.pageParams],
        pages: [...t.pages]
      };
      for (let o = 0; o < s.pages.length; o++) {
        const i = (r = s.pages[o]) == null ? void 0 : r.data, y = i == null ? void 0 : i.data;
        if (!y) continue;
        const S = e.updateFn(y);
        if (i !== S && (s.pages[o] = {
          ...s.pages[o],
          data: {
            ...i,
            data: S
          }
        }, e.stopOnChange))
          break;
      }
      return s;
    }
  })
}, ze = (e) => Z({
  ...e,
  fn: (t) => {
    var s;
    return {
      code: 200,
      success: !0,
      ...t,
      data: {
        code: 200,
        success: !0,
        ...t == null ? void 0 : t.data,
        data: Te(e.dataOrFn) ? e.dataOrFn((s = t == null ? void 0 : t.data) == null ? void 0 : s.data) : e.dataOrFn
      }
    };
  }
}), Je = {
  update: ze
}, T = {
  update: Z,
  request: je,
  apiRequest: Je
}, _e = "private", Ke = "calendarEvent", Ze = "cmsPage", Xe = "cmsPages", Xt = "currentUser", et = "dashboardPastSessions", tt = "dashboardSessions", en = "featuredSessions", tn = "integrationsConnections", nn = "integrationsDirectURL", sn = "integrationsGetAction", nt = "integrationsProviders", st = "meetingMemoryMessages", rn = "meetingMemorySuggestions", rt = "meetingMemoryThread", ot = "meetingMemoryThreads", it = "paymentDetails", ut = "recording", on = "recordingStatus", at = "session", un = "sessionAccessRule", me = "sessionAccessRules", ct = "sessionAiFeed", yt = "sessionEndSummary", Et = "sessionEpisodes", an = "sessionPendingRulesStats", dt = "sessionPresence", $e = "sessionRequestAccess", cn = "sessionViewers", ft = "sessionCards", Fe = "streamToken", mt = "listeningModeSpeakers", St = "subscriptionPlans", gt = "subscriptionReceipts", _t = "user", lt = "integrationsGetByOwnerSessionRecurrence", pt = "userMeetingTypes-v2", yn = "salesforceProvider", En = "salesforceGetBindingById", dn = "salesforceGetAccountById", fn = "salesforceGetOpportunityById", mn = "salesforceListBindableRecordsAsOptions", Sn = "salesforceGetContacts", gn = "salesforceGetLeadById", _n = "hubspotProvider", ln = "hubspotGetBindingById", Ct = "hubspotGetCompanyById", pn = "hubspotGetDealById", Cn = "hubspotListBindableRecordsAsOptions", An = "hubspotGetContacts", On = "hubspotGetContactById", Rn = (e, t) => [_e, ...e, t], oe = (e, t = "") => [
  ct,
  e,
  t
], Se = (e, t = "") => [
  $e,
  e,
  t,
  J.IN_REVIEW
], At = (e, t, s) => [$e, e, t, s || ""], Ot = (e, t, s) => [at, e, t || "", s || ""], Rt = (e, t = "") => [
  dt,
  e,
  t
], Mt = (e) => [_t, e], le = (e) => [
  ot,
  e
], Mn = (e) => [
  rt,
  e
], U = (e) => [
  st,
  e
], vn = (e, t) => [mt, e, t], vt = (e, t) => [yt, e, t], Dt = {
  add: ({
    client: e,
    data: t,
    sessionId: s,
    recurrenceId: n
  }) => {
    t && T.request.addToArray({
      client: e,
      key: oe(s, n),
      data: [t]
    });
  },
  update: ({
    client: e,
    data: t,
    sessionId: s,
    recurrenceId: n
  }) => {
    t && T.request.update({
      client: e,
      key: oe(s, n),
      dataOrFn: (r) => r ? (r.messages = r.messages.map(
        (o) => o.id === t.id ? t : o
      ), r) : { messages: [t], totalSummaryCount: 1 }
    });
  },
  remove: ({
    client: e,
    sessionId: t,
    recurrenceId: s,
    id: n
  }) => {
    T.request.removeFromArray({
      client: e,
      key: oe(t, s),
      id: n,
      keyId: "id"
    });
  }
}, Dn = {
  ...Dt
}, Tt = {
  addMessage: ({ client: e, data: t, id: s }) => {
    t && T.apiRequest.update(
      {
        client: e,
        key: U(s),
        dataOrFn: (n) => ({
          hasMore: !1,
          ...n,
          messages: [...(n == null ? void 0 : n.messages) || [], t],
          total: ((n == null ? void 0 : n.total) || 0) + 1
        })
      }
    );
  },
  updateMessage: ({ client: e, data: t, id: s }) => {
    t && T.apiRequest.update(
      {
        client: e,
        key: U(s),
        dataOrFn: (n) => n != null && n.messages ? {
          ...n,
          messages: n.messages.map(
            (r) => r.id === t.id ? { ...r, ...t } : r
          )
        } : n
      }
    );
  },
  updateLastAiMessage: ({
    client: e,
    data: t,
    id: s
  }) => {
    t && T.apiRequest.update(
      {
        client: e,
        key: U(s),
        dataOrFn: (n) => {
          var S;
          const r = n && {
            ...n,
            messages: n.messages ? [...n.messages] : void 0
          };
          if (!(r != null && r.messages)) return n;
          const o = r.messages.findLastIndex(
            (_) => (!t.id || _.id === t.id) && _.role === "ai"
          );
          if (o === -1) return n;
          const i = r.messages[o], y = { ...i, ...t, id: t.id || i.id };
          return t.content && (y.content = `${i.content || ""}${t.content}`), t.todo && (y.todo = `${i.todo || ""}${t.todo}`), t.progress && (y.progress = `${i.progress || ""}${t.progress}`), r.messages[o] = y, !((S = y.content) != null && S.length) && t.isComplete && r.messages.splice(o, 1), r;
        }
      }
    );
  },
  removeMessage: ({
    client: e,
    id: t,
    messageId: s
  }) => {
    T.apiRequest.update(
      {
        client: e,
        key: U(t),
        dataOrFn: (n) => {
          var i;
          if (!(n != null && n.messages)) return n;
          const r = n.messages.length, o = (i = n.messages) == null ? void 0 : i.filter(
            (y) => y.id !== s
          );
          return {
            ...n,
            messages: o,
            total: Math.max(
              (n.total || 0) - (r !== o.length ? 1 : 0),
              0
            )
          };
        }
      }
    );
  },
  removeLastMessage: ({ client: e, id: t }) => {
    T.apiRequest.update(
      {
        client: e,
        key: U(t),
        dataOrFn: (s) => s != null && s.messages ? {
          ...s,
          messages: s.messages.slice(0, -1),
          total: Math.max((s.total || 0) - 1, 0)
        } : s
      }
    );
  },
  removeThread: ({
    client: e,
    id: t,
    userId: s
  }) => {
    T.apiRequest.update({
      client: e,
      key: le(s),
      dataOrFn: (n) => {
        var i;
        if (!(n != null && n.threads)) return n;
        const r = n.threads.length, o = (i = n.threads) == null ? void 0 : i.filter((y) => y.id !== t);
        return {
          ...n,
          threads: o,
          total: Math.max(
            (n.total || 0) - (r !== o.length ? 1 : 0),
            0
          )
        };
      }
    });
  }
}, Tn = Tt;
let ie;
const Kn = (e) => {
  ie = e.logger;
}, Kt = (e) => {
  var t;
  return (t = e == null ? void 0 : e.data) == null ? void 0 : t.data;
}, $n = (e) => Kt(e.data), Fn = (e, t = 0) => {
  var s, n, r;
  return (r = (n = (s = e.data) == null ? void 0 : s.pages[t]) == null ? void 0 : n.data) == null ? void 0 : r.data;
}, W = (e, t, s = void 0, ...n) => {
  try {
    return we(e, t, ...n).newDocument;
  } catch (r) {
    return ie == null || ie.logWith(3, r, e, t), Be.report(r), s;
  }
}, $t = (e, t, s) => {
  if (e.op !== "add" || !e.path.endsWith("/-")) return !0;
  const n = e.path.split("/").slice(1, -1);
  let r = s;
  for (const o of n)
    r[o] === null && (r[o] = []), r = r[o];
  return !0;
}, hn = {
  refetchOnMount: !1,
  refetchOnReconnect: !1,
  refetchOnWindowFocus: !1
}, pe = (e) => (t) => ({
  gcTime: t,
  staleTime: t,
  retry: 2,
  refetchOnMount: !0,
  refetchOnReconnect: e,
  refetchOnWindowFocus: e
}), g = {
  "7d": 6048e5,
  "3d": 2592e5,
  "1d": 864e5,
  "6h": 216e5,
  "1h": 36e5,
  "30m": 18e5,
  "15m": 9e5,
  "10m": 6e5,
  "5m": 3e5,
  "3m": 18e4,
  "1m": 6e4,
  "30s": 3e4,
  "15s": 15e3,
  "5s": 5e3,
  "3s": 3e3,
  0: 0
}, v = [""], Y = () => {
}, K = pe(!0), Ee = {
  "7d": K(g["7d"]),
  "3d": K(g["3d"]),
  "1d": K(g["1d"]),
  "6h": K(g["6h"]),
  "1h": K(g["1h"]),
  "30m": K(g["30m"]),
  "15m": K(g["15m"]),
  "10m": K(g["10m"]),
  "5m": K(g["5m"]),
  "3m": K(g["3m"]),
  "1m": K(g["1m"]),
  "30s": K(g["30s"]),
  "15s": K(g["15s"]),
  "3s": K(g["3s"]),
  0: K(0)
}, $ = pe(!1), h = {
  "7d": $(g["7d"]),
  "3d": $(g["3d"]),
  "1d": $(g["1d"]),
  "6h": $(g["6h"]),
  "1h": $(g["1h"]),
  "30m": $(g["30m"]),
  "15m": $(g["15m"]),
  "10m": $(g["10m"]),
  "5m": $(g["5m"]),
  "3m": $(g["3m"]),
  "1m": $(g["1m"]),
  "30s": $(g["30s"]),
  "15s": $(g["15s"]),
  "3s": $(g["3s"]),
  0: $(0)
}, H = (e) => ({
  ...pe(!1)(e),
  refetchOnReconnect: !0
}), Pn = ({
  sessionId: e,
  recurrenceId: t = "",
  queryClient: s,
  onFetched: n,
  onPatch: r,
  options: o,
  params: i
}) => {
  const y = !!e, S = y ? oe(e, t) : v;
  return {
    ...H(g["3s"]),
    ...o,
    queryKey: S,
    queryFn: y ? ({ signal: _ }) => ge.getFeed(e, t, {
      ...i,
      signal: _,
      params: {
        limit: 200,
        sortOrder: "asc",
        ...i == null ? void 0 : i.params
      },
      onFetched: n,
      onPatch: (p) => {
        r && r(p), s.setQueryData(
          S,
          (A = X(
            {
              messages: [],
              totalSummaryCount: 0
            }
          )) => W(L(A), p) ?? A
        );
      }
    }) : Y
  };
}, qn = ({
  sessionId: e,
  recurrenceId: t,
  options: s,
  params: n
}) => ({
  ...s,
  mutationFn: (r) => ge.event(e, t, {
    ...n,
    data: r
  })
}), Yn = ({
  sessionId: e,
  recurrenceId: t
}) => ({
  queryKey: vt(e, t),
  queryFn: () => ge.getSummary(e, t),
  ...h["5m"]
}), Nn = (e) => [
  Ke,
  e
], Qn = (e) => ({
  queryKey: [Ke, e],
  queryFn: () => Qe.getCalndrLinkEvent({ data: e })
}), Ft = (e) => [Ze, e], bn = ({
  slug: e,
  isPreview: t
}) => ({
  queryKey: e ? Ft(e) : v,
  queryFn: () => ve.getPage(e || ""),
  ...h[t ? 0 : "5m"],
  enabled: !!e
}), ht = (e) => [
  Xe,
  e
], Hn = ({
  type: e,
  isPreview: t
}) => ({
  queryKey: ht(e),
  queryFn: () => ve.getPages(e),
  ...h[t ? 0 : "15m"],
  gcTime: t ? 0 : 864e5
  // 1 day
}), Pt = (e, t) => [ft, e, t], In = (e, t, s, n, r) => {
  const o = !!e && !!t, i = o ? Pt(e, t) : v;
  return {
    queryKey: i,
    enabled: o,
    ...H(g[0]),
    queryFn: o ? () => b.cards(e, t, {
      onFetched: (y) => {
        T.request.update({
          client: s,
          key: i,
          dataOrFn: y
        });
      },
      onPatch: (y) => {
        s.setQueryData(
          i,
          (S = {}) => W(L(S), y) ?? S
        );
      },
      ...n
    }) : Y,
    ...r
  };
}, qt = (e, t) => [
  lt,
  e,
  t
], Gn = ({
  sessionId: e,
  recurrenceId: t,
  queryClient: s,
  options: n,
  params: r
}) => {
  const o = !!e && !!t, i = o ? qt(e, t) : v;
  return {
    queryKey: i,
    enabled: o,
    ...H(g[0]),
    ...n,
    queryFn: o ? () => ae.getBindingsByOwnerSessionRecurrencePerProvider(
      e,
      t,
      {
        onFetched: (y) => {
          T.request.update({
            client: s,
            key: i,
            dataOrFn: y
          });
        },
        onPatch: (y) => {
          s.setQueryData(
            i,
            (S = {}) => W(L(S), y) ?? S
          );
        },
        ...r
      }
    ) : Y
  };
}, wn = ({
  params: e,
  ...t
}) => ({
  ...t,
  mutationFn: ({
    sessionId: s,
    sessionRecurrenceId: n,
    record: { id: r, type: o }
  }) => ae.hubspot.createBinding({
    ...e,
    data: {
      sessionId: s,
      sessionRecurrenceId: n,
      record: {
        id: r,
        type: o
      }
    }
  })
}), Yt = (e) => [
  Ct,
  e
], Bn = ({
  id: e,
  params: t,
  ...s
}) => {
  const n = !!e;
  return {
    ...h["1m"],
    ...s,
    enabled: n,
    queryKey: n ? Yt(e) : v,
    queryFn: n ? () => ae.hubspot.getCompanyById(e) : Y
  };
}, Nt = [
  nt
], Ln = {
  queryKey: Nt,
  queryFn: ae.getProviders,
  ...h["1h"]
}, kn = ({
  userId: e,
  options: t,
  params: s
}) => {
  const n = !!e, r = n ? le(e) : v;
  return {
    ...Ee["3m"],
    ...t,
    queryKey: r,
    queryFn: n ? () => ce.threads(s) : Y
  };
}, Un = ({
  id: e,
  options: t,
  params: s
}) => {
  const n = !!e, r = n ? le(e) : v;
  return {
    ...h["3m"],
    ...t,
    queryKey: r,
    enabled: n,
    queryFn: n ? () => ce.thread(e, s) : Y
  };
}, xn = ({
  id: e,
  options: t,
  params: s
}) => {
  const n = !!e, r = n ? U(e) : v;
  return {
    ...h["3m"],
    queryKey: r,
    enabled: n,
    ...t,
    queryFn: n ? () => ce.threadMessages(e, s) : Y
  };
}, Wn = ({
  id: e,
  options: t,
  params: s
}) => ({
  ...t,
  mutationFn: (n) => ce.deleteThread(e, {
    ...s,
    data: n
  })
}), Qt = (e) => [
  Et,
  e
], Vn = (e) => ({
  queryKey: e ? Qt(e) : v,
  queryFn: e ? () => De.getEpisodes(e) : Y,
  ...h["5m"],
  enabled: !!e
}), bt = (e, t) => [ut, e, t], jn = (e, t) => {
  const s = !!e && !!t;
  return {
    queryKey: s ? bt(e, t) : v,
    ...h["15m"],
    queryFn: s ? () => De.getRecording(e, t) : Y,
    enabled: s
  };
}, zn = ({
  sessionId: e,
  recurrenceId: t = "",
  queryClient: s,
  onPatch: n,
  options: r,
  params: o
}) => {
  const i = !!e, y = i ? Se(e, t) : v;
  return {
    ...H(g["3s"]),
    ...r,
    queryKey: y,
    enabled: i,
    queryFn: ({ signal: S }) => ee.inReview({
      ...o,
      signal: S,
      params: {
        ...o == null ? void 0 : o.params,
        sessionID: e || "",
        sessionRecurrenceID: t
      },
      onPatch: (_) => {
        n && n(_), s.setQueryData(
          y,
          (p = X(
            void 0
          )) => W(L(p), _) ?? p
        );
      }
    })
  };
}, Jn = ({
  userId: e,
  sessionId: t,
  recurrenceId: s = "",
  queryClient: n,
  onPatch: r,
  options: o,
  params: i
}) => {
  const y = !!e && !!t, S = y ? At(e, t, s) : v;
  return {
    ...H(g["3s"]),
    ...o,
    queryKey: S,
    enabled: y,
    queryFn: y ? ({ signal: _ }) => ee.userSessionRequest({
      ...i,
      signal: _,
      params: {
        ...i == null ? void 0 : i.params,
        sessionID: t,
        ...s ? { sessionRecurrenceID: s } : {}
      },
      onPatch: (p) => {
        r && r(p), n.setQueryData(
          S,
          (A = X(
            void 0
          )) => W(L(A), L(p)) ?? A
        );
      }
    }) : Y
  };
}, Zn = ({
  options: e,
  params: t
} = {}) => ({
  ...e,
  mutationFn: (s) => ee.requestAccess({
    ...t,
    data: s
  })
}), Xn = ({
  options: e,
  params: t
}) => ({
  ...e,
  mutationFn: (s) => ee.update({
    ...t,
    data: s
  })
}), Ht = (e, t) => [
  me,
  e,
  t,
  J.GRANTED
], es = (e, t, s) => ({
  queryKey: Ht(e, t),
  queryFn: () => ee.getViewer({
    params: {
      limit: 50,
      ...s,
      sessionID: e,
      sessionRecurrenceID: t,
      restrictionStatus: J.GRANTED
    }
  }),
  ...Ee["3m"]
}), ts = ({
  sessionId: e,
  recurrenceId: t = "",
  userId: s,
  onFetched: n,
  onPatch: r,
  onError: o,
  options: i,
  params: y
}) => {
  const S = e ? Ot(e, t, s) : v;
  return {
    ...H(g[0]),
    // force no caching because of problems with redux sync
    initialData: void 0,
    retry: (_, p) => (p == null ? void 0 : p.code) !== 401,
    enabled: !!e,
    ...i,
    queryKey: S,
    queryFn: e ? ({ signal: _ }) => b.getById(e, {
      ...y,
      signal: _,
      onFetched: n,
      onPatch: r,
      onError: o
    }) : void 0
  };
}, ns = ({
  sessionId: e,
  recurrenceId: t = "",
  options: s,
  params: n
}) => ({
  ...s,
  mutationFn: (r) => b.update(e, t, {
    ...n,
    data: r
  })
}), ss = ({
  sessionId: e
}) => ({
  mutationFn: () => b.end(e)
}), rs = ({
  sessionId: e,
  recurrenceId: t = "",
  queryClient: s,
  onPatch: n,
  options: r,
  params: o
}) => {
  const i = e ? Rt(e, t) : v;
  return {
    ...H(g["3s"]),
    enabled: !!e,
    ...r,
    queryKey: i,
    queryFn: e ? ({ signal: y }) => b.presence(e, {
      ...o,
      signal: y,
      onPatch: (S) => {
        S && (n && n(S), s.setQueryData(
          i,
          (_ = X(
            void 0
          )) => W(
            L(_),
            S,
            void 0,
            $t
          ) ?? _
        ));
      }
    }) : void 0
  };
}, os = ({
  sessionId: e,
  params: t,
  options: s
}) => ({
  ...s,
  mutationFn: (n) => b.updateParticipantMetadata(e, {
    ...t,
    data: n
  })
}), he = 50, ue = (e) => [
  _e,
  tt,
  e
], is = (e, {
  limit: t = he
} = {}) => ({
  queryKey: e ? ue(e) : v,
  queryFn: async ({ pageParam: s = 0 }) => b.getSessionsLibraryUpcoming({
    params: {
      limit: t,
      cursor: s * t
    }
  }),
  initialPageParam: 0,
  ...Ee["15s"],
  getNextPageParam: (s, n) => n.length + 1,
  enabled: !!e
}), It = (e) => [
  _e,
  et,
  e
], us = (e, {
  limit: t = he
} = {}) => ({
  queryKey: e ? It(e) : v,
  queryFn: async ({ pageParam: s = 0 }) => b.getSessionsLibraryHistory({
    params: {
      limit: t,
      cursor: s * t
    }
  }),
  initialPageParam: 0,
  ...Ee["30s"],
  getNextPageParam: (s, n) => n.length + 1,
  enabled: !!e
}), as = ({
  userId: e,
  accessToken: t,
  params: s,
  ...n
}) => ({
  ...n,
  mutationFn: () => {
    if (!e || !t) throw new Error("Unauthorized");
    return ye.activateTrial(e, {
      ...s,
      data: { token: t }
    });
  }
}), cs = ({
  accessToken: e,
  params: t,
  ...s
}) => ({
  ...s,
  mutationFn: () => be.hostOptIn({
    data: { accessToken: e },
    ...t
  })
}), Gt = () => [St], ys = () => ({
  queryKey: Gt(),
  queryFn: He.getPlans,
  ...h["1h"]
}), Es = (e, t) => [
  Fe,
  e,
  t
], ds = (e, t) => {
  const s = !!e && !!t;
  return {
    queryKey: s ? [Fe, e, t] : v,
    queryFn: s ? () => Ie.getStream(t) : void 0,
    enabled: s,
    ...h["5m"]
  };
}, fs = ({
  id: e,
  options: t,
  params: s
}) => {
  const n = Mt(e);
  return {
    ...H(g["3m"]),
    ...t,
    queryKey: n,
    queryFn: ({ signal: r }) => ye.getById(e, { ...s, signal: r })
  };
}, wt = (e, t) => [
  gt,
  e,
  t
], ms = (e, t, s) => ({
  queryKey: wt(e, t),
  queryFn: () => ye.getTransactions(e, {
    params: { includeInvoices: "true", ...s }
  }),
  ...h["3m"]
}), Bt = (e) => [
  it,
  e
], Ss = (e, t) => {
  const s = !!e && !!t;
  return {
    queryKey: s ? Bt(e) : v,
    queryFn: e ? () => ye.getPaymentDetails(e || "") : Y,
    ...h["3m"],
    enabled: s
  };
}, Lt = () => [pt], gs = () => ({
  queryKey: Lt(),
  queryFn: Ge.get,
  ...h["1h"]
}), kt = {
  add: ({ client: e, data: t, userId: s }) => {
    t && T.request.addToPages({
      client: e,
      key: ue(s),
      updateFn: (n) => ({
        ...n,
        count: ((n == null ? void 0 : n.count) || 0) + 1,
        sessions: [t, ...(n == null ? void 0 : n.sessions) || []]
      })
    });
  },
  update: ({ client: e, data: t, userId: s }) => {
    t && T.request.updateInPages({
      client: e,
      key: ue(s),
      stopOnChange: !0,
      updateFn: (n) => {
        if (!(n != null && n.sessions)) return n;
        const r = n.sessions.findIndex(
          (i) => i.sessionID === t.sessionID
        );
        if (r === -1) return n;
        const o = {
          ...n,
          sessions: [...n.sessions]
        };
        return o.sessions[r] = t, o;
      }
    });
  },
  remove: ({
    client: e,
    userId: t,
    id: s
  }) => {
    let n;
    return T.request.updateInPages({
      client: e,
      key: ue(t),
      stopOnChange: !0,
      updateFn: (r) => {
        if (!(r != null && r.sessions)) return r;
        const o = r.sessions.findIndex((y) => y.sessionID === s);
        if (o === -1) return r;
        const i = { ...r, sessions: [...r.sessions] };
        return i.count = Math.max(0, (i.count || 1) - 1), n = i.sessions.splice(o, 1)[0], i;
      }
    }), n;
  }
}, Ut = {
  add: ({ client: e, data: t = [] }) => {
    if (!t.length) return;
    const { sessionID: s, sessionRecurrenceID: n } = t[0];
    T.request.update({
      client: e,
      key: [
        me,
        s,
        n,
        J.GRANTED
      ],
      dataOrFn: (r = {}) => {
        var A, I;
        const o = { ...r }, i = (P, N) => (N.type === "domain" ? P[0].push(N) : P[1].push(N), P), [y, S] = ((I = (A = o == null ? void 0 : o.data) == null ? void 0 : A.viewerAccessRules) == null ? void 0 : I.reduce(i, [[], []])) || [], [_, p] = t.reduce(i, [[], []]);
        return {
          data: {
            viewerAccessRules: [
              ..._,
              ...y || [],
              ...p,
              ...S || []
            ]
          }
        };
      }
    });
  },
  remove: ({
    client: e,
    sessionId: t,
    sessionRecurrenceId: s,
    id: n
  }) => {
    T.request.update({
      client: e,
      key: [
        me,
        t,
        s,
        J.GRANTED
      ],
      dataOrFn: (r) => {
        var i;
        const o = ((i = r == null ? void 0 : r.data.viewerAccessRules) == null ? void 0 : i.filter((y) => y.id !== n)) || [];
        return {
          ...r,
          data: {
            ...r == null ? void 0 : r.data,
            viewerAccessRules: o
          }
        };
      }
    });
  },
  // requests
  addRequest: ({
    client: e,
    sessionId: t,
    sessionRecurrenceId: s,
    data: n
  }) => {
    var y;
    const r = Se(t, s), o = e.getQueryData(r) || X(void 0), i = {
      ...o,
      data: {
        ...o.data,
        accessRules: [...((y = o.data) == null ? void 0 : y.accessRules) || [], ...n]
      }
    };
    e.setQueryData(r, i);
  },
  removeRequest: ({
    client: e,
    sessionId: t,
    sessionRecurrenceId: s,
    id: n
  }) => {
    var p, A;
    const r = Se(t, s), o = e.getQueryData(r);
    if (!((p = o == null ? void 0 : o.data) != null && p.accessRules)) return;
    const i = o.data.accessRules, y = i == null ? void 0 : i.findIndex((I) => I.id === n);
    if (y === -1) return;
    const S = {
      ...o,
      data: {
        ...o.data,
        accessRules: [...((A = o.data) == null ? void 0 : A.accessRules) || []]
      }
    }, _ = S.data.accessRules.splice(y, 1)[0];
    return e.setQueryData(r, S), _;
  }
}, _s = {
  session: kt,
  viewerAccess: Ut
}, ls = (e) => {
  const t = Le(e), { onSuccess: s, onError: n } = e, { isError: r, isSuccess: o, error: i, data: y } = t;
  return x(() => {
    o && s && s(y);
  }, [y, o, s]), x(() => {
    r && n && n(i);
  }, [i, r, n]), t;
}, ps = (e) => {
  const t = ke(e), { onSuccess: s, onError: n } = e, { isError: r, isSuccess: o, error: i, data: y } = t;
  return x(() => {
    o && s && s(y);
  }, [y, o, s]), x(() => {
    r && n && n(i);
  }, [i, r, n]), t;
}, xt = {
  refetchOnMount: !1,
  refetchOnReconnect: !1,
  refetchOnWindowFocus: !1,
  refetchInterval: !1,
  useErrorBoundary: !1,
  enabled: !1
}, Cs = (e, t) => {
  const s = Ue(), n = {
    queryKey: e,
    ...xt,
    ...t
  }, r = xe(n);
  r.current = n;
  const [o, i] = Me(
    () => new Re(s, n)
  ), [y, S] = Me(
    o == null ? void 0 : o.getOptimisticResult(n)
  );
  return x(() => {
    if (!(e != null && e.length)) return;
    const _ = new Re(
      s,
      r.current
    );
    i(_);
    const p = _.getOptimisticResult(r.current);
    S(p);
  }, [s, e]), x(() => {
    const _ = o.subscribe((p) => {
      S(p);
    });
    return () => {
      _();
    };
  }, [o]), y;
};
export {
  Ke as CACHE_KEY_CALENDAR_EVENT,
  Ze as CACHE_KEY_CMS_PAGE,
  Xe as CACHE_KEY_CMS_PAGES,
  Xt as CACHE_KEY_CURRENT_USER,
  et as CACHE_KEY_DASHBOARD_PAST_SESSIONS,
  tt as CACHE_KEY_DASHBOARD_SESSIONS,
  en as CACHE_KEY_FEATURED_SESSIONS,
  ln as CACHE_KEY_HUBSPOT_GET_BINDING_BY_ID,
  Ct as CACHE_KEY_HUBSPOT_GET_COMPANY_BY_ID,
  An as CACHE_KEY_HUBSPOT_GET_CONTACTS,
  On as CACHE_KEY_HUBSPOT_GET_CONTACT_BY_ID,
  pn as CACHE_KEY_HUBSPOT_GET_DEAL_BY_ID,
  Cn as CACHE_KEY_HUBSPOT_LIST_BINDABLE_RECORDS_AS_OPTIONS,
  _n as CACHE_KEY_HUBSPOT_PROVIDER,
  tn as CACHE_KEY_INTEGRATIONS_CONNECTIONS,
  nn as CACHE_KEY_INTEGRATIONS_DIRECT_URL,
  sn as CACHE_KEY_INTEGRATIONS_GET_ACTION,
  lt as CACHE_KEY_INTEGRATIONS_GET_BY_OWNER_SESSION_RECURRENCE,
  nt as CACHE_KEY_INTEGRATIONS_PROVIDERS,
  mt as CACHE_KEY_LISTENING_MODE_SPEAKERS,
  st as CACHE_KEY_MEETING_MEMORY_MESSAGES,
  rn as CACHE_KEY_MEETING_MEMORY_SUGGESTIONS,
  rt as CACHE_KEY_MEETING_MEMORY_THREAD,
  ot as CACHE_KEY_MEETING_MEMORY_THREADS,
  it as CACHE_KEY_PAYMENT_DETAILS,
  _e as CACHE_KEY_PRIVATE_BASE,
  ut as CACHE_KEY_RECORDING,
  on as CACHE_KEY_RECORDING_STATUS,
  dn as CACHE_KEY_SALESFORCE_GET_ACCOUNT_BY_ID,
  En as CACHE_KEY_SALESFORCE_GET_BINDING_BY_ID,
  Sn as CACHE_KEY_SALESFORCE_GET_CONTACTS,
  gn as CACHE_KEY_SALESFORCE_GET_LEAD_BY_ID,
  fn as CACHE_KEY_SALESFORCE_GET_OPPORTUNITY_BY_ID,
  mn as CACHE_KEY_SALESFORCE_LIST_BINDABLE_RECORDS_AS_OPTIONS,
  yn as CACHE_KEY_SALESFORCE_PROVIDER,
  at as CACHE_KEY_SESSION,
  un as CACHE_KEY_SESSION_ACCESS_RULE,
  me as CACHE_KEY_SESSION_ACCESS_RULES,
  ct as CACHE_KEY_SESSION_AI_FEED,
  yt as CACHE_KEY_SESSION_END_SUMMARY,
  Et as CACHE_KEY_SESSION_EPISODES,
  ft as CACHE_KEY_SESSION_GET_CARDS,
  an as CACHE_KEY_SESSION_PENDING_RULES_STATS,
  dt as CACHE_KEY_SESSION_PRESENCE,
  $e as CACHE_KEY_SESSION_REQUEST_ACCESS,
  cn as CACHE_KEY_SESSION_VIEWERS,
  Fe as CACHE_KEY_STREAM_TOKEN,
  St as CACHE_KEY_SUBSCRIPTION_PLANS,
  gt as CACHE_KEY_SUBSCRIPTION_RECEIPTS,
  _t as CACHE_KEY_USER,
  pt as CACHE_KEY_USER_MEETING_TYPES,
  as as activateTrialMutation,
  Ee as activeOptions,
  Dn as aiFeedCacheService,
  qn as aiFeedEventMutation,
  H as baseBraidOptions,
  Rn as buildPrivateQueryKey,
  T as cacheService,
  Qn as calendarEventQuery,
  bn as cmsPageQuery,
  Hn as cmsPagesQuery,
  wn as createCRMBindingMutation,
  hn as disabledFetchOptions,
  v as emptyCacheKey,
  Y as emptyFn,
  ss as endSessionMutation,
  K as getActiveOptions,
  oe as getAiFeedQueryKey,
  Gn as getCRMProviderBindingsForSessionQuery,
  qt as getCRMProviderBindingsForSessionQueryKey,
  Nn as getCalendarEventQueryKey,
  In as getCardsQuery,
  Pt as getCardsQueryKey,
  Ft as getCmsPageQueryKey,
  ht as getCmsPagesQueryKey,
  $ as getInactiveOptions,
  Fn as getInfinityRequestData,
  Nt as getIntegrationsProvidersQueryKey,
  vn as getListeningModeSpeakersQueryKey,
  U as getMeetingMemoryThreadMessagesQueryKey,
  Mn as getMeetingMemoryThreadQueryKey,
  le as getMeetingMemoryThreadsQueryKey,
  It as getPastSessionQueryKey,
  Bt as getPaymentDetailsQueryKey,
  ys as getPlansQuery,
  Gt as getPlansQueryKey,
  $n as getQueryRequestData,
  Qt as getRecordingEpisodesQueryKey,
  bt as getRecordingQueryKey,
  Kt as getRequestData,
  Pn as getSessionAiFeedQuery,
  vt as getSessionEndSummaryQueryKey,
  Ht as getSessionGrantedAccessRulesQueryKey,
  Rt as getSessionPresenceKey,
  ts as getSessionQuery,
  Ot as getSessionQueryKey,
  Es as getStreamTokenQueryKey,
  ue as getUpcomingSessionQueryKey,
  Lt as getUserMeetingTypesQueryKey,
  Mt as getUserQueryKey,
  wt as getUserTransactionsQueryKey,
  cs as hostOptInMutation,
  Yt as hubspotGetCompanyQueryKey,
  Bn as hubstopGetCompanyQuery,
  h as inactiveOptions,
  Kn as initConfig,
  Ln as integrationsProvidersQuery,
  W as jsonPatch,
  $t as jsonPatchArrayFallbackValidator,
  ie as logger,
  Tn as meetingMemoryCacheService,
  Wn as meetingMemoryDeleteThreadMutation,
  xn as meetingMemoryThreadMessagesQuery,
  Un as meetingMemoryThreadQuery,
  kn as meetingMemoryThreadsQuery,
  g as milliseconds,
  us as pastSessionQuery,
  Ss as paymentDetailsQuery,
  xt as queryDataDefaultOptions,
  Vn as recordingEpisodesQuery,
  jn as recordingQuery,
  Zn as requestAccessMutation,
  _s as sessionCacheService,
  Yn as sessionEndSummaryQuery,
  es as sessionGrantedAccessRulesQuery,
  zn as sessionPendingAccessQuery,
  Se as sessionPendingAccessQueryKey,
  rs as sessionPresenceQuery,
  ds as streamTokenQuery,
  is as upcomingSessionQuery,
  os as updateParticipantMetadataMutation,
  Xn as updateSessionAccessMutation,
  ns as updateSessionMutation,
  ls as useInfiniteQuery,
  ps as useQuery,
  Cs as useQueryData,
  gs as userMeetingTypesQuery,
  fs as userQuery,
  Jn as userSessionRequestAccessQuery,
  At as userSessionRequestAccessQueryKey,
  ms as userTransactionsQuery
};
