import { SessionCalendarType as Qt, SessionStatus as Ne, TeamMemberRole as Ze, ERROR_SESSION_ACCESS_UNAUTHORIZED_LOCKED as Yt, ERROR_SESSION_ACCESS_UNAUTHORIZED_UNLOCKED as Vt, ERROR_SESSION_UNAUTHENTICATED as Wt, ERROR_SESSION_ACCESS_INACTIVE as xt, SessionAccessRuleStatus as Ie, STORAGE_LAST_USED_INTEGRATION as He, ERROR_SESSION_ACCESS_ALREADY_APPROVED as Zt, LOGGER_MEETING_MEMORY as Jt } from "@waitroom/models";
import { isValidUrl as Xt, toBoolean as es, parseJson as Je, decodeHtmlEntities as Ae, buildKey as Ue } from "@waitroom/utils";
import { useMutation as O, useQueryClient as R, useQuery as Q } from "@tanstack/react-query";
import { selectAuthState as Xe, authService as et, setSubscriptionPlan as Ge, updateAuthState as tt, selectCurrentUserId as ne, selectAuthUserId as Se, selectAuthStatus as ts, selectIsAuthenticated as ss, selectIsCurrentUserGuest as ns, authReducer as os } from "@waitroom/auth";
import { activateTrialMutation as as, getRequestData as ke, getCRMProviderBindingsForSessionQuery as rs, getCRMProviderBindingsForSessionQueryKey as is, useQueryData as oe, endSessionMutation as cs, getSessionQueryKey as st, getSessionQuery as us, jsonPatch as ds, emptyCacheKey as ce, userSessionRequestAccessQuery as ls, userSessionRequestAccessQueryKey as ms, CACHE_KEY_SESSION as gs, hostOptInMutation as ps, createCRMBindingMutation as fs, useQuery as Y, hubstopGetCompanyQuery as Ss, CACHE_KEY_HUBSPOT_GET_DEAL_BY_ID as ys, inactiveOptions as x, CACHE_KEY_HUBSPOT_LIST_BINDABLE_RECORDS_AS_OPTIONS as hs, CACHE_KEY_HUBSPOT_GET_CONTACTS as vs, CACHE_KEY_HUBSPOT_GET_CONTACT_BY_ID as bs, activeOptions as $s, CACHE_KEY_INTEGRATIONS_GET_ACTION as As, CACHE_KEY_INTEGRATIONS_CONNECTIONS as Es, updateParticipantMetadataMutation as Cs, sessionCacheService as ws, meetingMemoryCacheService as J, buildPrivateQueryKey as nt, CACHE_KEY_MEETING_MEMORY_SUGGESTIONS as _s, meetingMemoryThreadMessagesQuery as Ts, getMeetingMemoryThreadMessagesQueryKey as Is, getMeetingMemoryThreadsQueryKey as ks, getQueryRequestData as Os, CACHE_KEY_SALESFORCE_PROVIDER as Ee, CACHE_KEY_SALESFORCE_GET_ACCOUNT_BY_ID as Ds, CACHE_KEY_SALESFORCE_GET_OPPORTUNITY_BY_ID as Ps, CACHE_KEY_SALESFORCE_LIST_BINDABLE_RECORDS_AS_OPTIONS as Rs, CACHE_KEY_SALESFORCE_GET_CONTACTS as Ms, CACHE_KEY_SALESFORCE_GET_LEAD_BY_ID as Ns, getSessionAiFeedQuery as zs, getAiFeedQueryKey as Bs, getCardsQueryKey as ze, getCardsQuery as Fs, sessionPendingAccessQuery as Ks, sessionPendingAccessQueryKey as qs, CACHE_KEY_SESSION_PENDING_RULES_STATS as ot, sessionPresenceQuery as Ls, getSessionPresenceKey as js, aiFeedCacheService as Hs, updateSessionMutation as Us } from "@waitroom/react-query";
import { useDispatch as Oe, useSelector as T } from "react-redux";
import { braidService as ue, userApiService as Gs, integrationsApiService as M, sessionAccessApiService as at, meetingMemoryApiService as De, getApiResponseData as Qs, sessionRecordingsApiService as Qe, aiApiService as Ys } from "@waitroom/common-api";
import { useEffect as j, useMemo as K, useRef as Me, useCallback as ie, useState as be } from "react";
import { deepClone as Ye } from "fast-json-patch";
import { createAction as V } from "@waitroom/react-utils";
import { produce as rt } from "immer";
import { createSelector as N } from "reselect";
import { nanoid as Vs } from "nanoid";
let G;
const fc = (e) => {
  G = { ...G, ...e };
}, Ws = (e, t) => {
  var s;
  return Number(((s = e.data) == null ? void 0 : s.code) || e.code) === t;
}, Sc = (e, t) => {
  var s;
  return t.includes(Number(((s = e.data) == null ? void 0 : s.code) || e.code));
}, it = (e, t) => t.find((s) => s.name === e), ct = (e, t) => t.find((s) => s.name === e), ut = (e, t) => t.find(({ provider: s }) => s.name === e), dt = (e, t) => {
  for (let s = 0; s < t.length; s++) {
    const n = t[s], { provider: o } = ut(e.providerName, n.connectedProviders) || {};
    if (!o)
      continue;
    const a = ct(
      e.integrationName,
      o.integrations
    );
    return a && it(e.actionName, a.actions) ? o : void 0;
  }
}, xs = (e) => e ? ["account", "opportunity"].includes(e.record.type) : !1, yc = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  findAction: it,
  findIntegration: ct,
  findLastUsedIntegrationProvider: dt,
  findProvider: ut,
  hasCardsForBinding: xs
}, Symbol.toStringTag, { value: "Module" })), hc = (e) => !!e.calendarID && e.calendarType === Qt.Google, lt = (e, t) => {
  if (e.length === 1 || !t) return e;
  let s = e[0];
  const n = e.filter((o) => o.id !== t ? !0 : (s = o, !1));
  return [s, ...n];
}, Zs = {
  facebook: "https://www.facebook.com/",
  instagram: "https://www.instagram.com/",
  twitter: "https://twitter.com/",
  linkedin: "https://www.linkedin.com/in/",
  tiktok: "https://www.tiktok.com/@",
  youtube: "https://www.youtube.com/c/",
  website: "//"
}, Js = (e, t) => Xs(Zs[e], t), Xs = (e, t) => !e || !t || Xt(t) ? t || e : `${e}${t.trim().replace(/^@/, "")}`, vc = (e) => !e || !e.length ? e : e.map((t) => ({
  ...t,
  url: Js(t.platform, t.url)
})), en = async (e, t, s, n, o) => {
  const a = ke(t);
  if (!a) throw new Error("Something went wrong");
  const { authToken: u, refreshToken: l, userSubscriptionPlan: g } = a;
  if (u) {
    const y = {
      accessToken: u,
      refreshToken: l
    }, v = await et.setup(y);
    if (!v.isAuthenticated) throw new Error("Unauthorized");
    g && e(Ge(g)), v && e(tt(v));
  } else
    g && e(Ge(g));
  o && o(t, s, n);
}, bc = (e) => {
  const t = Oe(), { userId: s, accessToken: n } = T(Xe);
  return O(
    as({
      userId: s,
      accessToken: n,
      ...e,
      onSuccess: (o, a, u) => en(t, o, a, u, e.onSuccess)
    })
  );
};
var mt = /* @__PURE__ */ ((e) => (e.Salesforce = "salesforce", e.Hubspot = "hubspot", e))(mt || {});
const $c = (e) => {
  const t = R(), s = `crmBindings-${e.sessionId}-${e.recurrenceId}`;
  Q(
    rs({
      ...e,
      queryClient: t,
      params: {
        ...e.params,
        key: s
      }
    })
  ), j(() => () => {
    ue.close(s);
  }, [s]);
}, gt = (e, t) => {
  const s = K(
    () => is(e, t),
    [e, t]
  );
  return oe(s);
}, Ac = (e, t, s) => {
  const { data: n } = gt(
    e,
    t
  ), o = n == null ? void 0 : n.data;
  return o == null ? void 0 : o[s];
}, Ec = (e, t) => {
  const { data: s } = gt(
    e,
    t
  ), n = s == null ? void 0 : s.data;
  return Object.values(mt).some((o) => !!(n != null && n[o]));
}, Cc = (e) => O(cs(e)), I = /* @__PURE__ */ (() => {
  const e = (n, ...o) => {
    t.console && console[n](...o);
  };
  let t = { console: !0 };
  const s = (n) => {
    throw n;
  };
  return {
    init: (n) => {
      t = { ...t, ...n };
    },
    c: e,
    console: e,
    show: (n, ...o) => console[n](...o),
    report: (n, o = "error") => {
      t.log && t.log(n), e(o, n);
    },
    raise: s,
    rescue: (n, o) => n instanceof o ? n : s(n)
  };
})(), wc = {
  override: void 0,
  has: function(t) {
    const s = G.featureFlags;
    return this.override !== void 0 ? this.override : !!s && (s[t] === !0 || s[t] === "true");
  }
}, pt = "SET_MODAL", _c = V(pt), tn = {
  type: void 0,
  props: {}
}, sn = (e = tn, t) => rt(e, () => {
  switch (t.type) {
    case pt:
      return t.payload || {};
    default:
      return;
  }
}), Tc = (e) => e.modal, ft = "app/NAVIGATE_TO", Ic = V(ft), nn = {}, on = (e = nn, { type: t, payload: s }) => {
  switch (t) {
    case ft:
      return typeof s == "string" ? {
        path: s
      } : s || {};
    default:
      return e;
  }
}, kc = (e) => e.navigation, an = "session/SETUP_CURRENT_SESSION", St = "session/SET_CURRENT_SESSION", yt = "session/SET_ACTIVE_SESSION", ht = "session/SET_BOTH_SESSIONS", vt = "session/SET_SESSION", bt = "session/CLEAR_SESSION", $t = "session/UPDATE_CURRENT_SESSION", At = "session/SET_SESSION_MODAL", Et = "session/CLOSE_SESSION_MODAL", Ct = "session/GRANT_CONSENT_TO_BROADCAST", wt = "session/REVOKE_CONSENT_TO_BROADCAST", Oc = V(an), rn = V(St), cn = V(ht), Dc = V(yt), un = V(vt), Pc = V(bt), dn = V($t), Rc = (e) => ({
  type: At,
  payload: e
}), Mc = (e) => ({
  type: Et,
  payload: e
}), Nc = V(Ct), zc = V(wt), ln = (e, t) => {
  if (t)
    return e && t.find((s) => e === s.id) || t[0];
}, mn = (e) => e ? e.primaryHost || ln(e.primaryHostUserID, e.hosts) : void 0, Bc = (e) => e === Ne.IN_SESSION, _t = (e, t) => e.findLast((s) => s.state === t), gn = (e) => {
  var t;
  return (t = _t(e, Ne.IN_SESSION)) == null ? void 0 : t.updatedAt;
}, pn = (e) => {
  var t;
  return (t = _t(e, Ne.ENDED)) == null ? void 0 : t.updatedAt;
}, Fc = (e, t) => (e == null ? void 0 : e.primaryHostUserID) === (t == null ? void 0 : t.id) && (t == null ? void 0 : t.id) !== void 0;
var fn = /* @__PURE__ */ ((e) => (e[e.HOST = 1] = "HOST", e[e.TOPIC = 2] = "TOPIC", e[e.HOW_IT_WORKS = 3] = "HOW_IT_WORKS", e[e.CAM_MIC_PERMISSIONS = 4] = "CAM_MIC_PERMISSIONS", e[e.TEST_DEVICES = 5] = "TEST_DEVICES", e[e.INTERRUPTED = 6] = "INTERRUPTED", e[e.VIEWER_ACCESS = 7] = "VIEWER_ACCESS", e[e.DATA_VISIBILITY = 8] = "DATA_VISIBILITY", e))(fn || {}), Sn = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function Tt(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
var yn = function(t) {
  return hn(t) && !vn(t);
};
function hn(e) {
  return !!e && typeof e == "object";
}
function vn(e) {
  var t = Object.prototype.toString.call(e);
  return t === "[object RegExp]" || t === "[object Date]" || An(e);
}
var bn = typeof Symbol == "function" && Symbol.for, $n = bn ? Symbol.for("react.element") : 60103;
function An(e) {
  return e.$$typeof === $n;
}
function En(e) {
  return Array.isArray(e) ? [] : {};
}
function $e(e, t) {
  return t.clone !== !1 && t.isMergeableObject(e) ? fe(En(e), e, t) : e;
}
function Cn(e, t, s) {
  return e.concat(t).map(function(n) {
    return $e(n, s);
  });
}
function wn(e, t) {
  if (!t.customMerge)
    return fe;
  var s = t.customMerge(e);
  return typeof s == "function" ? s : fe;
}
function _n(e) {
  return Object.getOwnPropertySymbols ? Object.getOwnPropertySymbols(e).filter(function(t) {
    return Object.propertyIsEnumerable.call(e, t);
  }) : [];
}
function Ve(e) {
  return Object.keys(e).concat(_n(e));
}
function It(e, t) {
  try {
    return t in e;
  } catch {
    return !1;
  }
}
function Tn(e, t) {
  return It(e, t) && !(Object.hasOwnProperty.call(e, t) && Object.propertyIsEnumerable.call(e, t));
}
function In(e, t, s) {
  var n = {};
  return s.isMergeableObject(e) && Ve(e).forEach(function(o) {
    n[o] = $e(e[o], s);
  }), Ve(t).forEach(function(o) {
    Tn(e, o) || (It(e, o) && s.isMergeableObject(t[o]) ? n[o] = wn(o, s)(e[o], t[o], s) : n[o] = $e(t[o], s));
  }), n;
}
function fe(e, t, s) {
  s = s || {}, s.arrayMerge = s.arrayMerge || Cn, s.isMergeableObject = s.isMergeableObject || yn, s.cloneUnlessOtherwiseSpecified = $e;
  var n = Array.isArray(t), o = Array.isArray(e), a = n === o;
  return a ? n ? s.arrayMerge(e, t, s) : In(e, t, s) : $e(t, s);
}
fe.all = function(t, s) {
  if (!Array.isArray(t))
    throw new Error("first argument should be an array");
  return t.reduce(function(n, o) {
    return fe(n, o, s);
  }, {});
};
var kn = fe, On = kn;
const Dn = /* @__PURE__ */ Tt(On), Pn = (e, t = "") => !e || !e.firstName && !e.fullName && !e.userFirstName && !e.userLastName ? t : e.fullName ? e.fullName : `${e.firstName || e.userFirstName} ${e.lastName || e.userLastName || ""}`, Kc = (e, t) => `${e}${t ? ` ${t}` : ""}`, qc = (e, t = "") => {
  var o, a;
  if (!e || !e.firstName && !e.fullName && !e.userFirstName && !e.userLastName)
    return t;
  const s = e.firstName || e.userFirstName || "", n = ((o = e.lastName) == null ? void 0 : o.charAt(0)) || ((a = e.userLastName) == null ? void 0 : a.charAt(0)) || "";
  return `${s} ${n}`;
}, Rn = (e) => e && {
  ...e,
  fullName: Pn(e)
}, kt = (e) => e && e.map((t) => Rn(t)), Lc = (e, t) => !!e && !!t && e.id === t.id, jc = (e) => ({
  id: e.id,
  firstName: "Anonymous",
  fullName: "Anonymous"
}), se = {
  current: {},
  active: {
    consentToBroadcast: !1
  },
  allMap: {},
  summaryMap: {},
  modal: {}
}, _e = (e) => {
  const t = lt(
    kt(e.hosts),
    e.primaryHostUserID
  );
  return {
    ...e,
    primaryHost: mn(e),
    hosts: t
  };
}, Hc = (e = se, t) => rt(e, (s) => {
  switch (t.type) {
    case vt:
      t.payload && (s.allMap[t.payload.sessionID] = _e(t.payload));
      break;
    case bt:
      t.payload && (delete s.allMap[t.payload], s.active.id === t.payload && (s.active = se.active), s.current.id === t.payload && (s.current = se.current));
      break;
    case St:
      t.payload ? typeof t.payload == "string" ? s.current.id = t.payload : (s.current.id = t.payload.sessionID, s.allMap[t.payload.sessionID] = _e(t.payload)) : s.current = se.current;
      break;
    case yt:
      t.payload ? typeof t.payload == "string" ? s.active.id = t.payload : (s.active.id = t.payload.sessionID, s.allMap[t.payload.sessionID] = _e(t.payload)) : s.active = se.active;
      break;
    case ht:
      t.payload ? typeof t.payload == "string" ? (s.current.id = t.payload, s.active.id = t.payload) : (s.current.id = t.payload.sessionID, s.active.id = t.payload.sessionID, s.allMap[t.payload.sessionID] = _e(t.payload)) : (s.current = se.current, s.active = se.active);
      break;
    case $t:
      const { sessionID: n, ...o } = t.payload || {};
      t.payload.sessionID && (s.allMap[n] = Dn(
        s.allMap[n],
        o,
        {
          // TODO: issue with deepMerge - https://github.com/TehShrike/deepmerge/issues/264
          arrayMerge: (a, u) => a.length > 0 ? a : u
        }
      ), o.hosts && (s.allMap[n].hosts = lt(
        kt(o.hosts),
        o.primaryHostUserID || s.allMap[n].primaryHostUserID
      )));
      break;
    case At:
      s.modal = t.payload || {};
      break;
    case Et:
      s.modal.type === t.payload && (s.modal = {});
      break;
    case Ct: {
      s.active.consentToBroadcast = !0;
      break;
    }
    case wt: {
      s.active.consentToBroadcast = !1;
      break;
    }
  }
}), de = (e) => e.sessions || se, Ot = (e) => de(e).allMap, Uc = (e, t) => de(e).allMap[t], Gc = (e, t) => !!de(e).allMap[t], Qc = (e) => de(e).modal, Yc = [
  "free",
  "premium",
  "pro",
  "enterprise"
], Vc = {
  free: ["meetings", "timeLimit", "integrations", "stream"],
  premium: [
    "meetingMemory",
    "aiFeed",
    "recording",
    "integrations",
    "stream",
    "aiFeedLocal",
    "meetingSummary",
    "offTheRecord",
    "support"
  ],
  pro: void 0,
  enterprise: void 0
};
var Dt = { exports: {} };
(function(e, t) {
  (function(s, n) {
    e.exports = n();
  })(Sn, function() {
    var s = 1e3, n = 6e4, o = 36e5, a = "millisecond", u = "second", l = "minute", g = "hour", y = "day", v = "week", m = "month", h = "quarter", A = "year", b = "date", U = "Invalid Date", C = /^(\d{4})[-/]?(\d{1,2})?[-/]?(\d{0,2})[Tt\s]*(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?[.:]?(\d+)?$/, F = /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a|A|m{1,2}|s{1,2}|Z{1,2}|SSS/g, Z = { name: "en", weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"), months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"), ordinal: function(p) {
      var c = ["th", "st", "nd", "rd"], r = p % 100;
      return "[" + p + (c[(r - 20) % 10] || c[r] || c[0]) + "]";
    } }, X = function(p, c, r) {
      var d = String(p);
      return !d || d.length >= c ? p : "" + Array(c + 1 - d.length).join(r) + p;
    }, Re = { s: X, z: function(p) {
      var c = -p.utcOffset(), r = Math.abs(c), d = Math.floor(r / 60), i = r % 60;
      return (c <= 0 ? "+" : "-") + X(d, 2, "0") + ":" + X(i, 2, "0");
    }, m: function p(c, r) {
      if (c.date() < r.date()) return -p(r, c);
      var d = 12 * (r.year() - c.year()) + (r.month() - c.month()), i = c.clone().add(d, m), f = r - i < 0, S = c.clone().add(d + (f ? -1 : 1), m);
      return +(-(d + (r - i) / (f ? i - S : S - i)) || 0);
    }, a: function(p) {
      return p < 0 ? Math.ceil(p) || 0 : Math.floor(p);
    }, p: function(p) {
      return { M: m, y: A, w: v, d: y, D: b, h: g, m: l, s: u, ms: a, Q: h }[p] || String(p || "").toLowerCase().replace(/s$/, "");
    }, u: function(p) {
      return p === void 0;
    } }, le = "en", W = {};
    W[le] = Z;
    var ye = "$isDayjsObject", q = function(p) {
      return p instanceof Ce || !(!p || !p[ye]);
    }, me = function p(c, r, d) {
      var i;
      if (!c) return le;
      if (typeof c == "string") {
        var f = c.toLowerCase();
        W[f] && (i = f), r && (W[f] = r, i = f);
        var S = c.split("-");
        if (!i && S.length > 1) return p(S[0]);
      } else {
        var E = c.name;
        W[E] = c, i = E;
      }
      return !d && i && (le = i), i || !d && le;
    }, _ = function(p, c) {
      if (q(p)) return p.clone();
      var r = typeof c == "object" ? c : {};
      return r.date = p, r.args = arguments, new Ce(r);
    }, $ = Re;
    $.l = me, $.i = q, $.w = function(p, c) {
      return _(p, { locale: c.$L, utc: c.$u, x: c.$x, $offset: c.$offset });
    };
    var Ce = function() {
      function p(r) {
        this.$L = me(r.locale, null, !0), this.parse(r), this.$x = this.$x || r.x || {}, this[ye] = !0;
      }
      var c = p.prototype;
      return c.parse = function(r) {
        this.$d = function(d) {
          var i = d.date, f = d.utc;
          if (i === null) return /* @__PURE__ */ new Date(NaN);
          if ($.u(i)) return /* @__PURE__ */ new Date();
          if (i instanceof Date) return new Date(i);
          if (typeof i == "string" && !/Z$/i.test(i)) {
            var S = i.match(C);
            if (S) {
              var E = S[2] - 1 || 0, w = (S[7] || "0").substring(0, 3);
              return f ? new Date(Date.UTC(S[1], E, S[3] || 1, S[4] || 0, S[5] || 0, S[6] || 0, w)) : new Date(S[1], E, S[3] || 1, S[4] || 0, S[5] || 0, S[6] || 0, w);
            }
          }
          return new Date(i);
        }(r), this.init();
      }, c.init = function() {
        var r = this.$d;
        this.$y = r.getFullYear(), this.$M = r.getMonth(), this.$D = r.getDate(), this.$W = r.getDay(), this.$H = r.getHours(), this.$m = r.getMinutes(), this.$s = r.getSeconds(), this.$ms = r.getMilliseconds();
      }, c.$utils = function() {
        return $;
      }, c.isValid = function() {
        return this.$d.toString() !== U;
      }, c.isSame = function(r, d) {
        var i = _(r);
        return this.startOf(d) <= i && i <= this.endOf(d);
      }, c.isAfter = function(r, d) {
        return _(r) < this.startOf(d);
      }, c.isBefore = function(r, d) {
        return this.endOf(d) < _(r);
      }, c.$g = function(r, d, i) {
        return $.u(r) ? this[d] : this.set(i, r);
      }, c.unix = function() {
        return Math.floor(this.valueOf() / 1e3);
      }, c.valueOf = function() {
        return this.$d.getTime();
      }, c.startOf = function(r, d) {
        var i = this, f = !!$.u(d) || d, S = $.p(r), E = function(re, z) {
          var ee = $.w(i.$u ? Date.UTC(i.$y, z, re) : new Date(i.$y, z, re), i);
          return f ? ee : ee.endOf(y);
        }, w = function(re, z) {
          return $.w(i.toDate()[re].apply(i.toDate("s"), (f ? [0, 0, 0, 0] : [23, 59, 59, 999]).slice(z)), i);
        }, k = this.$W, D = this.$M, L = this.$D, ge = "set" + (this.$u ? "UTC" : "");
        switch (S) {
          case A:
            return f ? E(1, 0) : E(31, 11);
          case m:
            return f ? E(1, D) : E(0, D + 1);
          case v:
            var ae = this.$locale().weekStart || 0, he = (k < ae ? k + 7 : k) - ae;
            return E(f ? L - he : L + (6 - he), D);
          case y:
          case b:
            return w(ge + "Hours", 0);
          case g:
            return w(ge + "Minutes", 1);
          case l:
            return w(ge + "Seconds", 2);
          case u:
            return w(ge + "Milliseconds", 3);
          default:
            return this.clone();
        }
      }, c.endOf = function(r) {
        return this.startOf(r, !1);
      }, c.$set = function(r, d) {
        var i, f = $.p(r), S = "set" + (this.$u ? "UTC" : ""), E = (i = {}, i[y] = S + "Date", i[b] = S + "Date", i[m] = S + "Month", i[A] = S + "FullYear", i[g] = S + "Hours", i[l] = S + "Minutes", i[u] = S + "Seconds", i[a] = S + "Milliseconds", i)[f], w = f === y ? this.$D + (d - this.$W) : d;
        if (f === m || f === A) {
          var k = this.clone().set(b, 1);
          k.$d[E](w), k.init(), this.$d = k.set(b, Math.min(this.$D, k.daysInMonth())).$d;
        } else E && this.$d[E](w);
        return this.init(), this;
      }, c.set = function(r, d) {
        return this.clone().$set(r, d);
      }, c.get = function(r) {
        return this[$.p(r)]();
      }, c.add = function(r, d) {
        var i, f = this;
        r = Number(r);
        var S = $.p(d), E = function(D) {
          var L = _(f);
          return $.w(L.date(L.date() + Math.round(D * r)), f);
        };
        if (S === m) return this.set(m, this.$M + r);
        if (S === A) return this.set(A, this.$y + r);
        if (S === y) return E(1);
        if (S === v) return E(7);
        var w = (i = {}, i[l] = n, i[g] = o, i[u] = s, i)[S] || 1, k = this.$d.getTime() + r * w;
        return $.w(k, this);
      }, c.subtract = function(r, d) {
        return this.add(-1 * r, d);
      }, c.format = function(r) {
        var d = this, i = this.$locale();
        if (!this.isValid()) return i.invalidDate || U;
        var f = r || "YYYY-MM-DDTHH:mm:ssZ", S = $.z(this), E = this.$H, w = this.$m, k = this.$M, D = i.weekdays, L = i.months, ge = i.meridiem, ae = function(z, ee, ve, we) {
          return z && (z[ee] || z(d, f)) || ve[ee].slice(0, we);
        }, he = function(z) {
          return $.s(E % 12 || 12, z, "0");
        }, re = ge || function(z, ee, ve) {
          var we = z < 12 ? "AM" : "PM";
          return ve ? we.toLowerCase() : we;
        };
        return f.replace(F, function(z, ee) {
          return ee || function(ve) {
            switch (ve) {
              case "YY":
                return String(d.$y).slice(-2);
              case "YYYY":
                return $.s(d.$y, 4, "0");
              case "M":
                return k + 1;
              case "MM":
                return $.s(k + 1, 2, "0");
              case "MMM":
                return ae(i.monthsShort, k, L, 3);
              case "MMMM":
                return ae(L, k);
              case "D":
                return d.$D;
              case "DD":
                return $.s(d.$D, 2, "0");
              case "d":
                return String(d.$W);
              case "dd":
                return ae(i.weekdaysMin, d.$W, D, 2);
              case "ddd":
                return ae(i.weekdaysShort, d.$W, D, 3);
              case "dddd":
                return D[d.$W];
              case "H":
                return String(E);
              case "HH":
                return $.s(E, 2, "0");
              case "h":
                return he(1);
              case "hh":
                return he(2);
              case "a":
                return re(E, w, !0);
              case "A":
                return re(E, w, !1);
              case "m":
                return String(w);
              case "mm":
                return $.s(w, 2, "0");
              case "s":
                return String(d.$s);
              case "ss":
                return $.s(d.$s, 2, "0");
              case "SSS":
                return $.s(d.$ms, 3, "0");
              case "Z":
                return S;
            }
            return null;
          }(z) || S.replace(":", "");
        });
      }, c.utcOffset = function() {
        return 15 * -Math.round(this.$d.getTimezoneOffset() / 15);
      }, c.diff = function(r, d, i) {
        var f, S = this, E = $.p(d), w = _(r), k = (w.utcOffset() - this.utcOffset()) * n, D = this - w, L = function() {
          return $.m(S, w);
        };
        switch (E) {
          case A:
            f = L() / 12;
            break;
          case m:
            f = L();
            break;
          case h:
            f = L() / 3;
            break;
          case v:
            f = (D - k) / 6048e5;
            break;
          case y:
            f = (D - k) / 864e5;
            break;
          case g:
            f = D / o;
            break;
          case l:
            f = D / n;
            break;
          case u:
            f = D / s;
            break;
          default:
            f = D;
        }
        return i ? f : $.a(f);
      }, c.daysInMonth = function() {
        return this.endOf(m).$D;
      }, c.$locale = function() {
        return W[this.$L];
      }, c.locale = function(r, d) {
        if (!r) return this.$L;
        var i = this.clone(), f = me(r, d, !0);
        return f && (i.$L = f), i;
      }, c.clone = function() {
        return $.w(this.$d, this);
      }, c.toDate = function() {
        return new Date(this.valueOf());
      }, c.toJSON = function() {
        return this.isValid() ? this.toISOString() : null;
      }, c.toISOString = function() {
        return this.$d.toISOString();
      }, c.toString = function() {
        return this.$d.toUTCString();
      }, p;
    }(), je = Ce.prototype;
    return _.prototype = je, [["$ms", a], ["$s", u], ["$m", l], ["$H", g], ["$W", y], ["$M", m], ["$y", A], ["$D", b]].forEach(function(p) {
      je[p[1]] = function(c) {
        return this.$g(c, p[0], p[1]);
      };
    }), _.extend = function(p, c) {
      return p.$i || (p(c, Ce, _), p.$i = !0), _;
    }, _.locale = me, _.isDayjs = q, _.unix = function(p) {
      return _(1e3 * p);
    }, _.en = W[le], _.Ls = W, _.p = {}, _;
  });
})(Dt);
var Mn = Dt.exports;
const We = /* @__PURE__ */ Tt(Mn), Wc = 100, xc = [
  "premium",
  "pro",
  "enterprise"
], Nn = (e) => {
  var t;
  return !!e && ((t = e.scheduledChange) == null ? void 0 : t.action) === "cancel";
}, Zc = (e, t, s) => {
  var o;
  const n = (o = e == null ? void 0 : e.planConfig) == null ? void 0 : o.paddleProductName;
  return !!n && (Array.isArray(t) ? t.includes(n) : n === t) && (!s || Pt(e));
}, Jc = (e) => !e || e === "free", zn = (e) => {
  var t;
  return !e || ((t = e.planConfig) == null ? void 0 : t.paddleProductName) === "free";
}, Pt = (e) => !!e && e.status === "trialing", Bn = (e) => Pt(e) && !(e != null && e.nextBilledAt) && !Nn(e), Xc = (e) => zn(e) || Bn(e), Be = (e, t) => {
  var s, n;
  return e ? ((s = e.planConfigOverrides) == null ? void 0 : s[t]) || ((n = e.planConfig) == null ? void 0 : n[t]) : void 0;
}, Fn = (e, t) => {
  var s;
  return !!((s = Be(e, t)) != null && s.enabled);
}, eu = (e, t) => {
  var s;
  return !((s = Be(e, t)) != null && s.enabled);
}, Kn = (e) => e && e.status === "trialing" && e.trialEndsAt ? Math.max(
  Math.ceil(We.unix(e.trialEndsAt).diff(We(), "hour") / 24),
  0
) : 0, tu = (e) => !(e != null && e.id) || Kn(e) > 0, qn = (e, t, s) => {
  var a, u, l, g;
  if (!((a = e == null ? void 0 : e.prices) != null && a.length)) return;
  let n, o;
  for (let y = 0; y < e.prices.length; y++) {
    const v = e.prices[y];
    if (((u = v.billingCycle) == null ? void 0 : u.interval) === t && v.status !== "archived" && (s === void 0 || !!((l = v.trialPeriod) != null && l.frequency) === s) && (o = v, es((g = v.customData) == null ? void 0 : g.default))) {
      n = v;
      break;
    }
  }
  return n || o;
}, su = (e, t, s) => {
  var n;
  if (e)
    for (let o = 0; o < e.length; o++) {
      if (((n = e[o].config) == null ? void 0 : n.paddleProductName) !== t) continue;
      const a = qn(e[o], s);
      if (a) return a;
    }
}, nu = (e) => {
  var t;
  return e ? ["error", "action_required"].includes((t = e.lastPayment) == null ? void 0 : t.status) : !1;
}, ou = (e, t) => e == null ? void 0 : e.find((s) => s.id === t), au = (e, t) => e == null ? void 0 : e.find((s) => s.config.paddleProductName === t), Fe = (e) => de(e).active.id, B = N(
  Fe,
  Ot,
  (e, t) => e ? t[e] : void 0
), ru = (e) => {
  var t;
  return (t = B(e)) == null ? void 0 : t.sessionRecurrenceID;
}, iu = (e) => {
  var t;
  return (t = B(e)) == null ? void 0 : t.sessionTitle;
}, cu = (e) => {
  var t;
  return (t = B(e)) == null ? void 0 : t.about;
}, uu = (e) => {
  var t;
  return (t = B(e)) == null ? void 0 : t.cover;
}, du = (e) => {
  var t;
  return (t = B(e)) == null ? void 0 : t.startTimestamp;
}, lu = (e) => {
  var t;
  return (t = B(e)) == null ? void 0 : t.endTimestamp;
}, Ln = (e) => {
  var t;
  return (t = B(e)) == null ? void 0 : t.subscriptionPlan;
}, mu = (e) => {
  var t, s;
  return (s = Be((t = B(e)) == null ? void 0 : t.subscriptionPlan, "stream")) == null ? void 0 : s.quality;
}, gu = (e) => {
  var t;
  return (t = B(e)) == null ? void 0 : t.sessionState;
}, pu = (e) => {
  const t = B(e);
  return t ? t.branchURL || t.publicURL : void 0;
}, Pe = (e) => {
  var t;
  return (t = B(e)) == null ? void 0 : t.hosts;
}, jn = (e) => {
  var t;
  return (t = B(e)) == null ? void 0 : t.sessionSettings;
}, Rt = N(
  [B],
  (e) => e == null ? void 0 : e.primaryHostUserID
), fu = N(
  [Rt, ne],
  (e, t) => !!e && e === t
), Su = N(
  [Rt, Pe],
  (e, t) => t == null ? void 0 : t.find((s) => s.id === e)
), yu = N(
  [Pe, ne],
  (e = [], t) => !!t && e.some((s) => s.id === t)
), hu = (e) => N(
  [Pe],
  (t = []) => !!e && t.some((s) => s.id === e)
), Hn = (e) => de(e).active.consentToBroadcast, vu = N(
  [Pe],
  (e) => (e == null ? void 0 : e.length) || 0
), Mt = (e) => {
  var t;
  return (t = B(e)) == null ? void 0 : t.stateUpdatedAt;
}, bu = N(
  [Mt],
  (e) => e ? gn(e) : void 0
), $u = N(
  [Mt],
  (e) => e ? pn(e) : void 0
), Un = (e) => de(e).current.id, Au = (e) => {
  var t;
  return (t = H(e)) == null ? void 0 : t.sessionRecurrenceID;
}, H = N(
  Un,
  Ot,
  (e, t) => e ? t[e] : void 0
), Eu = (e) => {
  var t;
  return (t = H(e)) == null ? void 0 : t.sessionTitle;
}, Cu = (e) => {
  var t;
  return (t = H(e)) == null ? void 0 : t.about;
}, wu = (e) => {
  var t;
  return (t = H(e)) == null ? void 0 : t.cover;
}, _u = (e) => {
  const t = H(e);
  return t ? t.branchURL || t.publicURL : void 0;
}, Tu = (e) => {
  var t;
  return (t = H(e)) == null ? void 0 : t.subscriptionPlan;
}, Iu = (e) => {
  var t;
  return (t = H(e)) == null ? void 0 : t.sessionSettings;
}, ku = (e) => {
  var t;
  return (t = H(e)) == null ? void 0 : t.startTimestamp;
}, Ou = (e) => {
  var t;
  return (t = H(e)) == null ? void 0 : t.endTimestamp;
}, Du = (e) => {
  var t;
  return (t = H(e)) == null ? void 0 : t.sessionState;
}, Ke = (e) => {
  var t;
  return (t = H(e)) == null ? void 0 : t.hosts;
}, Pu = N(
  [Ke, ne],
  (e = [], t) => !!t && e.some((s) => s.id === t)
), Nt = N(
  [H],
  (e) => e == null ? void 0 : e.primaryHostUserID
), Ru = N(
  [Nt, ne],
  (e, t) => !!e && e === t
), Mu = N(
  [Nt, Ke],
  (e, t) => t == null ? void 0 : t.find((s) => s.id === e)
), Nu = N(
  [Ke],
  (e) => (e == null ? void 0 : e.length) || 0
), zu = "sessions", Bu = (e) => e.role === Ze.Member, Fu = (e) => e.role === Ze.Owner, Gn = ({
  options: e
}) => ({
  ...e,
  mutationFn: Gs.requestDelete
}), Qn = "get-session", Yn = (e) => {
  var v;
  const t = R(), s = T(Se), n = T(ts) === "loading", o = Oe(), a = ((v = e.params) == null ? void 0 : v.key) || `${Qn}-${e.sessionId}-${e.recurrenceId}-${s}`, u = st(
    e.sessionId || "",
    e.recurrenceId,
    s
  ), { onPatch: l, onPatched: g, onFetched: y } = e;
  return j(() => () => {
    ue.close(a);
  }, [a]), Q(
    us({
      ...e,
      // disable until auth is loading
      sessionId: n ? void 0 : e.sessionId,
      userId: s,
      onFetched: (m) => {
        y && y(m), o && (m != null && m.data) && o(rn(m.data));
      },
      onPatch: (m) => {
        l && l(m), t.setQueryData(
          u,
          (h) => {
            try {
              if (!h) return;
              const A = Ye(h), b = ds(A, Ye(m)) ?? h;
              return g && g(h, b), o && (b != null && b.data) && o(un(b.data)), b;
            } catch (A) {
              return I.report(A), h;
            }
          }
        );
      },
      onError: async (m, h) => {
        var A, b, U;
        if (typeof h == "object" && (h != null && h.error)) {
          const C = (b = (A = h == null ? void 0 : h.error) == null ? void 0 : A.details) == null ? void 0 : b.errCode;
          if (C === Yt || C === Vt || C === Wt || C === xt)
            throw (U = G.logger) == null || U.logWith(3, "Reset sessions state", { code: C }), o == null || o(cn(void 0)), h;
        }
      },
      params: {
        ...e.params,
        key: a
      }
    })
  );
}, Ku = ({
  sessionId: e,
  recurrenceId: t
}) => {
  const s = T(Se), n = K(
    () => e && s ? st(e, t, s) : ce,
    [t, e, s]
  );
  return oe(
    n
  );
}, Vn = "get-session-request-access", Wn = (e) => {
  const t = T(Se), s = R(), n = `${Vn}-${e.sessionId}-${e.recurrenceId}-${t}`;
  return j(() => () => {
    ue.close(n);
  }, [n]), Q(
    ls({
      ...e,
      userId: t,
      queryClient: s,
      params: {
        key: n
      }
    })
  );
}, qu = ({
  sessionId: e,
  recurrenceId: t
}) => {
  const s = T(Se), n = K(
    () => s && e ? ms(s, e, t) : ce,
    [t, e, s]
  );
  return oe(n);
}, xn = (e) => {
  var a, u;
  const t = R(), { sessionId: s } = e, n = Wn(e), o = ((u = (a = n.data) == null ? void 0 : a.data) == null ? void 0 : u.restrictionStatus) === Ie.GRANTED;
  return j(() => {
    var l;
    !o || !s || ((l = G.logger) == null || l.log("Refetching session on granted access"), t.refetchQueries({
      queryKey: [gs, s],
      exact: !1
    }));
  }, [o, t, s]), [o, n];
}, Lu = (e) => {
  const { sessionId: t } = e, s = T(ss), n = Yn(e), o = n.isError && // errorCode === ERROR_SESSION_ACCESS_UNAUTHORIZED_UNLOCKED &&
  s, [, a] = xn({
    sessionId: o ? t : void 0
  }), u = Me(!0);
  return (n.isSuccess || n.isError) && (u.current = !1), n.isFirstLoad = u.current && n.isLoading, [n, a];
}, ju = ({
  onSuccess: e
}) => {
  const { accessToken: t } = T(Xe), s = Oe();
  return O(
    ps({
      accessToken: t,
      onSuccess: async (n) => {
        const { authToken: o, refreshToken: a } = ke(n) || {};
        if (!o) return;
        const u = await et.setup({
          accessToken: o,
          refreshToken: a
        });
        u.isAuthenticated && (s(tt(u)), e && e(n));
      },
      onError: (n) => {
        I.report(n);
      }
    })
  );
}, Zn = (e) => O(
  fs({
    onError: (t) => I.report(t),
    ...e
  })
), Jn = (e, t) => O({
  mutationFn: () => M.hubspot.deleteBinding(e.id),
  onError: (s) => I.report(s),
  ...t
}), Xn = (e, t) => Y({
  ...Ss({ id: e, ...t }),
  onError: I.report
}), eo = (e, t) => Y({
  queryKey: [ys, e],
  queryFn: () => M.hubspot.getDealById(e),
  onError: I.report,
  ...x["1m"],
  ...t
}), to = (e, t, s, n) => Y({
  queryKey: [
    hs,
    e,
    t,
    s
  ],
  queryFn: async () => await M.hubspot.listBindableRecordsAsOptions({
    params: {
      search: e,
      sessionId: t,
      sessionRecurrenceId: s
    }
  }),
  onError: I.report,
  ...n
}), so = (e, t, s) => Y({
  queryKey: [vs, e, t],
  queryFn: async () => await M.hubspot.getContacts(
    e,
    t
  ),
  onError: I.report,
  ...x["15s"],
  ...s
}), no = (e, t) => Y({
  queryKey: [bs, e],
  queryFn: async () => await M.hubspot.getContactById(e),
  onError: I.report,
  ...x["1m"],
  ...t
}), Hu = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  useCreateBinding: Zn,
  useDeleteBinding: Jn,
  useGetCompanyById: Xn,
  useGetContactById: no,
  useGetContacts: so,
  useGetDealById: eo,
  useListBindableRecordsAsOptions: to
}, Symbol.toStringTag, { value: "Module" })), qe = (e) => [
  Es,
  e
], oo = ({
  userId: e,
  enabled: t
}) => Q({
  queryKey: t ? qe(e) : ce,
  queryFn: () => M.getConnections({ throwError: !0 }),
  enabled: t,
  ...$s["5m"],
  staleTime: 2500
  // use 2.5s because it can refetch in 3sec interval
}), Uu = (e) => {
  const t = K(() => qe(e), [e]);
  return oe(t);
}, ao = (e) => {
  const t = R(), s = ie(() => new Promise((a) => {
    setTimeout(async () => {
      await t.refetchQueries({
        queryKey: qe(e),
        exact: !0
      }), a(!0);
    }, 3500);
  }), [t, e]), n = ie(() => s(), [s]), o = ie(() => s(), [s]);
  return {
    onConnect: n,
    onDisconnect: o
  };
}, Gu = ({
  isEnabled: e = !0
}) => {
  var W, ye;
  const t = T(ne), s = !!t && e, {
    data: n,
    isSuccess: o,
    error: a,
    isLoading: u,
    refetch: l
  } = oo({ userId: t, enabled: s }), g = ke(n), y = G.storage.getParsed(
    He
  ), v = (q) => G.storage.set(He, q);
  K(() => {
    !g || !y || dt(
      y,
      g
    ) || v(void 0);
  }, [g, y]);
  const [m, h] = be(), [A, b] = be(), {
    data: U,
    isSuccess: C,
    isFetching: F,
    refetch: Z
  } = Q({
    queryKey: [
      As,
      A,
      m == null ? void 0 : m.providerName,
      m == null ? void 0 : m.actionName
    ],
    queryFn: ({ queryKey: q }) => {
      const [, me, _ = "", $ = ""] = q;
      return M.getAction(
        String(_),
        String($),
        {
          params: { aiFeedItemId: me }
        }
      );
    },
    enabled: !1
  }), X = (q) => {
    h(q), v(q), b(q.aiSessionFeedMessageId);
  };
  j(() => {
    m && Z();
  }, [m, Z]);
  const { onConnect: Re } = ao(t);
  return {
    providerCategories: g,
    errorProviderCategories: a,
    isFetchingProviderCategories: u,
    isSuccessProviderCategories: o,
    selectedIntegration: m,
    lastUsedIntegration: y,
    onClickProvider: X,
    onClickConnect: () => Re(),
    blockKitSchema: !!U && C && ((ye = (W = U.data) == null ? void 0 : W.data) == null ? void 0 : ye.schema) || void 0,
    isFetchingGetAction: F,
    selectedSessionFeedMessageId: A,
    refetchProviderCategories: l
  };
}, ro = (e) => O(
  Cs({
    ...e,
    options: {
      retry: 10,
      retryDelay: (t) => Math.min(t > 1 ? 2 ** t * 1e3 : 1e3, 30 * 1e3)
    }
  })
), Qu = ({
  isConnected: e,
  isLocal: t,
  hasJoined: s,
  params: n
}) => {
  const o = T(Fe) || "", a = T(Hn), { mutate: u } = ro({
    sessionId: o,
    params: n
  });
  return j(() => {
    t && e && a && !s && u({ isLoaded: !0 });
  }, [
    a,
    s,
    e,
    u,
    t
  ]), null;
}, xe = ws.viewerAccess, Yu = ({
  request: e,
  options: t
}) => {
  const s = R(), n = T(ne), { id: o, sessionID: a, sessionRecurrenceID: u } = e, l = O({
    mutationFn: (m) => at.update({
      data: m
    }),
    ...t,
    onSuccess: (m, h, A) => {
      var b;
      (b = t == null ? void 0 : t.onSuccess) == null || b.call(t, m, h, A), m && h.restrictionStatus === Ie.GRANTED && xe.add({
        client: s,
        data: [e]
      });
    },
    onError: (m, h, A) => {
      var b;
      n && Ws(m, Zt) && xe.removeRequest({
        client: s,
        sessionId: a,
        sessionRecurrenceId: u,
        id: o
      }), (b = t == null ? void 0 : t.onError) == null || b.call(t, m, h, A);
    }
  }), { mutate: g } = l;
  return { mutation: l, approveClick: () => g({
    restrictionStatus: Ie.GRANTED,
    id: `${o}`
  }), ignoreClick: () => g({
    restrictionStatus: Ie.DENIED,
    id: `${o}`
  }) };
};
let io = "useandom-26T198340PX75pxJACKVERYMINDBUSHWOLF_GQZbfghjklqvwyzrict", pe = (e = 21) => {
  let t = "", s = e;
  for (; s--; )
    t += io[Math.random() * 64 | 0];
  return t;
};
const zt = (e, t) => {
  if (e) {
    for (let s = e.length - 1; s >= 0; s--)
      if (e[s].role === t) return e[s];
  }
}, Vu = (e) => zt(e, "user"), Wu = (e) => zt(e, "ai"), xu = (e) => e != null && e.length ? e[e.length - 1] : void 0, Bt = ({
  id: e,
  event: t,
  client: s,
  throwOnError: n
}) => {
  const { data: o, type: a } = t;
  switch (a) {
    case "stream_started":
      J.addMessage({
        id: e,
        client: s,
        data: {
          id: o.messageId,
          role: "ai",
          content: "",
          todo: "",
          progress: "",
          isStreaming: !0,
          isComplete: !1,
          feedback: 0
        }
      });
      break;
    case "text_generation_delta":
      J.updateLastAiMessage({
        id: e,
        client: s,
        data: { id: o.messageId, content: o.content }
      });
      break;
    case "tool_use_detected":
      J.updateLastAiMessage({
        id: e,
        client: s,
        data: {
          id: o.messageId,
          todo: o.content
        }
      });
      break;
    case "tool_use_submitted":
      J.updateLastAiMessage({
        id: e,
        client: s,
        data: {
          id: o.messageId,
          progress: o.content,
          sources: o.sources
        }
      });
      break;
    case "sources_updated":
      J.updateLastAiMessage({
        id: e,
        client: s,
        data: {
          id: o.messageId,
          sources: o.sources
        }
      });
      break;
    case "stream_failed":
    case "stream_ended":
      if (J.updateLastAiMessage({
        id: e,
        client: s,
        data: {
          id: o.messageId,
          isStreaming: !1,
          isComplete: !0,
          todo: ""
        }
      }), a === "stream_failed" && n) throw t;
      break;
  }
}, Zu = (e) => {
  const t = T(Se);
  return K(
    () => nt(e, t || ""),
    [e, t]
  );
}, co = (e) => {
  const t = T(Se);
  return K(() => {
    const s = nt(e, t || "");
    return [t ? { queryKey: s } : { queryKey: s, enabled: !1 }, t];
  }, [e, t]);
}, Le = (e) => {
  I.report(e);
}, Ft = (e, t, s) => O({
  mutationFn: (n) => De.ai(e, { data: n }, s),
  onError: Le,
  ...t
}), uo = (e) => O({
  mutationFn: ({ messageId: t, feedback: s }) => De.feedback({
    data: { messageId: t, feedback: s }
  }),
  onError: Le,
  ...e
}), Ju = (e) => O({
  mutationFn: ({ threadId: t }) => De.stop({
    data: { threadId: t }
  }),
  onError: Le,
  ...e
}), Xu = (e, t) => {
  const [s] = co([
    _s,
    e
  ]);
  return Q({
    queryFn: () => De.suggestions(e),
    ...x["30m"],
    ...t,
    ...s
  });
}, ed = ({
  threadId: e,
  onError: t
}) => {
  var U;
  const s = T(ne), n = R(), o = Me(), [a, u] = be(e), l = Me(pe()), g = !!e, y = Q(
    g ? Ts({
      id: e || l.current
    }) : (
      // fallback to empty result for new threads without threadId
      {
        queryKey: Is(
          e || l.current
        ),
        queryFn: () => ({
          code: 200,
          success: !0,
          data: void 0,
          total: 0
        }),
        ...x["1h"]
      }
    )
  );
  j(() => {
    e || (l.current = pe()), u(e);
  }, [e]);
  const v = Ft(
    (C) => {
      var X;
      (X = G.logger) == null || X.service(Jt, 5, "Received event", C);
      const F = Je(C.data);
      if (!(F != null && F.data)) return;
      const Z = F.data.threadId;
      Z && a !== Z && u(F.data.threadId), Bt({
        id: e || l.current,
        event: F,
        client: n,
        throwOnError: !0
      });
    },
    {
      onError: (C) => {
        I.report(C), t == null || t(C.message);
      },
      onSuccess: () => {
        s && setTimeout(() => {
          n.invalidateQueries({
            queryKey: ks(s)
          });
        }, 100);
      }
    }
  ), { mutate: m } = v, h = ie(() => {
    u(void 0);
    const C = pe();
    return l.current = C, C;
  }, []), A = ie(
    async (C, F) => {
      if (!C) return;
      const Z = F ? h() : e || l.current;
      J.addMessage({
        id: Z,
        client: n,
        data: {
          id: pe(),
          role: "user",
          content: C,
          isComplete: !0,
          isStreaming: !1,
          sources: void 0,
          feedback: 0
        }
      }), o.current = C, m({
        query: C,
        threadId: F ? void 0 : a,
        requestId: pe()
      });
    },
    [h, e, n, m, a]
  ), b = ie(() => {
    o.current && m({
      query: o.current,
      threadId: a,
      requestId: pe(),
      retry: !0
    });
  }, [m, a]);
  return {
    isQueryEnabled: g,
    cacheId: e || l.current,
    threadId: a,
    messagesQuery: y,
    messages: (U = Os(y)) == null ? void 0 : U.messages,
    onNewThread: h,
    submitQuery: A,
    askMutation: v,
    onRetry: b
  };
}, td = (e, t, s) => {
  const n = R(), { mutate: o, isPending: a } = Ft((l) => {
    try {
      const g = Je(l.data);
      if (!(g != null && g.data)) throw new Error("Invalid event data");
      Bt({
        id: e,
        event: g,
        client: n,
        throwOnError: !0
      });
    } catch (g) {
      I.report(g);
    }
  });
  return { refreshLastResponse: ie(() => {
    s && (J.removeMessage({
      id: e,
      client: n,
      messageId: s.id
    }), o({
      messageId: s.id,
      refresh: !0,
      threadId: t,
      requestId: Vs()
    }));
  }, [s, e, n, o, t]), isPending: a };
}, sd = (e, t) => {
  const s = R(), { mutate: n, isPending: o } = uo({
    onSuccess: (y) => {
      const { message: v } = Qs(y) || {};
      v && J.updateMessage({
        id: e,
        client: s,
        data: v
      });
    }
  }), [a, u] = be();
  return {
    thumbsUp: () => {
      u(1), n({ messageId: t.id, feedback: 1 });
    },
    thumbsDown: () => {
      u(2), n({ messageId: t.id, feedback: 2 });
    },
    isPending: o ? a : 0
  };
}, lo = (e) => {
  const t = T(Ln);
  return [Fn(t, e), t];
}, mo = () => {
  const e = T(ns), { summaAI: t, enableRecording: s } = T(jn) || {}, [n] = lo("offTheRecord");
  return {
    isEnabled: !!(t || s) && !e && n,
    aiEnabled: t,
    recordingEnabled: s
  };
}, nd = ({
  onError: e,
  onSuccess: t
}) => {
  const s = T(Fe), n = mo(), { mutate: o, isPending: a } = O({
    mutationFn: (l) => l ? Qe.enableOffTheRecord({
      data: { sessionID: s }
    }) : Qe.disableOffTheRecord({
      data: { sessionID: s }
    }),
    onSuccess: (l, g) => {
      t && t(g);
    },
    onError: (l, g) => {
      var y;
      (y = G.reportError) == null || y.call(G, l), e && e(g);
    }
  });
  return {
    ...n,
    onToggle: (l) => {
      o(!!l);
    },
    isInProgress: a
  };
}, od = (e) => O(Gn(e)), go = (e) => O({
  mutationFn: ({
    sessionId: t,
    sessionRecurrenceId: s,
    record: { id: n, type: o }
  }) => M.salesforce.createBinding({
    data: {
      sessionId: t,
      sessionRecurrenceId: s,
      record: {
        id: n,
        type: o
      }
    }
  }),
  onError: (t) => {
    I.report(t);
  },
  ...e
}), po = (e, t) => O({
  mutationFn: () => M.salesforce.deleteBinding(e.id),
  onError: (s) => {
    I.report(s);
  },
  ...t
}), fo = (e, t) => Y({
  queryKey: [
    Ee,
    Ds,
    e
  ],
  queryFn: async () => {
    var n, o;
    const s = await M.salesforce.getAccountById(e);
    return (o = (n = s.data) == null ? void 0 : n.data) != null && o.Name && (s.data.data.Name = Ae(s.data.data.Name)), s;
  },
  onError: (s) => {
    I.report(s);
  },
  ...x["1m"],
  ...t
}), So = (e, t) => Y({
  queryKey: [
    Ee,
    Ps,
    e
  ],
  queryFn: async () => {
    var n, o;
    const s = await M.salesforce.getOpportunityById(e);
    return (o = (n = s.data) == null ? void 0 : n.data) != null && o.Name && (s.data.data.Name = Ae(
      s.data.data.Name
    )), s;
  },
  onError: (s) => {
    I.report(s);
  },
  ...x["1m"],
  ...t
}), yo = (e, t, s, n) => Y({
  queryKey: [
    Ee,
    Rs,
    e,
    t,
    s
  ],
  queryFn: async () => {
    var a;
    const o = await M.salesforce.listBindableRecordsAsOptions({
      params: {
        search: e,
        sessionId: t,
        sessionRecurrenceId: s
      }
    });
    return (a = o.data) != null && a.data && (o.data.data = o.data.data.map((u) => (u.name && (u.name = Ae(u.name)), u))), o;
  },
  onError: (o) => {
    I.report(o);
  },
  ...n
}), ho = (e) => O({
  mutationFn: ({
    firstName: t,
    lastName: s,
    email: n
  }) => M.salesforce.createContact({
    firstName: t,
    lastName: s,
    email: n
  }),
  onError: (t) => {
    I.report(t);
  },
  ...e
}), vo = (e, t, s) => Y({
  queryKey: [
    Ee,
    Ms,
    e,
    t
  ],
  queryFn: async () => {
    var o;
    const n = await M.salesforce.getContacts(
      e,
      t
    );
    return (o = n.data) != null && o.data && (n.data.data = n.data.data.map((a) => (a.Name && (a.Name = Ae(a.Name)), a))), n;
  },
  onError: (n) => {
    I.report(n);
  },
  ...x["15s"],
  ...s
}), bo = (e, t) => Y({
  queryKey: [
    Ee,
    Ns,
    e
  ],
  queryFn: async () => {
    var n, o;
    const s = await M.salesforce.getLeadById(e);
    return (o = (n = s.data) == null ? void 0 : n.data) != null && o.Name && (s.data.data.Name = Ae(s.data.data.Name)), s;
  },
  onError: (s) => {
    I.report(s);
  },
  ...x["1m"],
  ...t
}), ad = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  useCreateBinding: go,
  useCreateContact: ho,
  useDeleteBinding: po,
  useGetAccountById: fo,
  useGetContacts: vo,
  useGetLeadById: bo,
  useGetOpportunityById: So,
  useListBindableRecordsAsOptions: yo
}, Symbol.toStringTag, { value: "Module" })), $o = "get-session-ai-feed", rd = (e) => {
  const t = R(), s = `${$o}-${e.sessionId}-${e.recurrenceId}`;
  return j(() => () => {
    ue.close(s);
  }, [s]), Q(
    zs({
      ...e,
      queryClient: t,
      params: {
        key: s
      }
    })
  );
}, id = ({
  sessionId: e,
  recurrenceId: t
}) => {
  const s = K(
    () => e ? Bs(e, t) : ce,
    [t, e]
  );
  return oe(
    s
  );
}, cd = (e, t, s) => {
  const o = K(
    () => ze(e, t),
    [e, t]
  ).join("-"), a = R();
  Q(
    Fs(
      e,
      t,
      a,
      { key: o },
      s
    )
  ), j(
    () => () => {
      ue.close(o);
    },
    [o]
  );
}, ud = (e, t) => {
  const s = K(
    () => ze(e, t),
    [e, t]
  );
  return oe(
    s
  );
}, dd = (e, t, s) => {
  const n = R(), o = ze(e, t);
  j(() => {
    s && n.resetQueries({ queryKey: o });
  }, [s, n, o]);
}, Ao = "get-session-pending-access", ld = (e) => {
  const t = R(), s = `${Ao}-${e.sessionId}-${e.recurrenceId}`;
  return j(() => () => {
    ue.close(s);
  }, [s]), Q(
    Ks({
      ...e,
      queryClient: t,
      params: {
        key: s
      }
    })
  );
}, md = ({
  sessionId: e,
  recurrenceId: t
}) => {
  const s = K(
    () => e ? qs(e, t) : ce,
    [t, e]
  );
  return oe(s);
}, Te = [ot], gd = ({
  sessions: e,
  enabled: t = !0
}) => {
  const s = T(ne), n = R(), [o, a] = be(
    n.getQueryData(Te) || {}
  ), u = K(() => {
    if (!e || !s) return;
    const y = n.getQueryData(Te), v = e.reduce(
      (m, h) => (h.accessStatus === "locked" && (!y || !y[Ue([h.sessionID, h.sessionRecurrenceID])]) && h.primaryHostUserID === s && m.push({
        id: h.sessionID,
        recurrenceID: h.sessionRecurrenceID
      }), m),
      []
    );
    return v.length > 0 ? v : void 0;
  }, [n, s, e]), l = t !== !1 && !!u;
  return { query: Y({
    queryKey: l ? [ot, u] : ce,
    queryFn: u ? () => at.getInReviewViewerAccessStats({
      data: {
        sessions: u
      }
    }) : () => null,
    enabled: l,
    onSuccess: (y) => {
      const { data: v } = (y == null ? void 0 : y.data) || {};
      !v || !Array.isArray(v) || a((m) => {
        const h = v.reduce((A, b) => (b && (A[Ue([b.id, b.recurrenceID])] = b.count || 0), A), m);
        return n.setQueryData(Te, h), n.setQueryDefaults(Te, x["5m"]), h;
      });
    }
  }), rules: o };
}, Eo = "get-session-presence", pd = (e) => {
  var n;
  const t = R(), s = ((n = e.params) == null ? void 0 : n.key) || `${Eo}-${e.sessionId}-${e.recurrenceId}`;
  return j(() => () => {
    ue.close(s);
  }, [s]), Q(
    Ls({
      ...e,
      queryClient: t,
      params: {
        ...e.params,
        key: s
      }
    })
  );
}, fd = ({
  sessionId: e,
  recurrenceId: t
}) => {
  const s = K(
    () => e ? js(e, t) : ce,
    [t, e]
  );
  return oe(
    s
  );
}, Sd = ({
  id: e,
  sessionId: t,
  recurrenceId: s
}, n) => {
  const o = R();
  return O({
    mutationFn: (a) => Ys.updateFeed(e, {
      data: {
        ...a,
        sessionID: t,
        sessionRecurrenceID: s
      }
    }),
    ...n,
    onSuccess: (...a) => {
      var l, g;
      const u = (g = (l = a[0]) == null ? void 0 : l.data) == null ? void 0 : g.data;
      u && (Hs.update({
        client: o,
        data: u,
        sessionId: t,
        recurrenceId: s
      }), n != null && n.onSuccess && n.onSuccess(...a));
    }
  });
}, yd = (e) => {
  var n;
  const t = Oe(), s = (n = e.options) == null ? void 0 : n.onSuccess;
  return O(
    Us({
      ...e,
      options: {
        ...e.options,
        onSuccess: (o, a, u) => {
          var g;
          s && s(o, a, u);
          const l = (g = ke(o)) == null ? void 0 : g.session;
          t && l && t(dn(l));
        }
      }
    })
  );
}, hd = {
  auth: os,
  modal: sn,
  navigation: on
}, Co = "Agree", wo = "Celebrate", _o = "Completed", To = "Disagree", Io = "Funny", ko = "Hi", Oo = "Love", Do = "Wow", Po = {
  agree: Co,
  celebrate: wo,
  completed: _o,
  disagree: To,
  funny: Io,
  hi: ko,
  love: Oo,
  wow: Do
}, Ro = "Connect $t(_company) to other tools you use", Mo = "Connect another account", No = "Disconnect account", zo = "Connect more integrations", Bo = "Streamline your work by connecting $t(_company) to other tools you use", Fo = "Active connections", Ko = "Integrated platform", qo = "Category", Lo = "Compatibility", jo = "Revoke access", Ho = {
  ticketing: "Ticketing",
  ticketingDesc: "Effectively handle and track tasks that come out of your meetings.",
  messaging: "Messaging",
  messagingDesc: "Boosts team communication by sending real-time updates straight from your meetings.",
  scheduling: "Scheduling",
  schedulingDesc: "Easily schedule and create $t(_company) meeting links in with one click, synced with your calendar.",
  crm: "CRM",
  crmDesc: "Save time by keeping your CRM updated automatically."
}, Uo = "Insight", Go = "Action item", Qo = {
  "logged-ticket-private": "{{name}} logged this",
  view: "View",
  "view-ticket": "View ticket",
  meeting: "Meeting",
  "shared-private": "{{name}} shared this",
  "logged-ticket-public": "{{name}} logged this",
  "shared-public": "{{name}} logged this",
  "crated-meeting-public": "{{name}} has shared a meeting",
  "crated-meeting-private": "{{name}} created a meeting"
}, Yo = {
  label: "Google Calendar add-on",
  desc: "Schedule and invite others to Rumi meetings with one click, straight from your calendar"
}, Vo = {
  label: "Google Chrome extension",
  desc: "Quickly access & start Rumi meetings and easily add meeting links to Google Calendar"
}, Wo = {
  "unableToSendTo{integration}": "Unable to send to {{integration}}. Something unexpectedly went wrong. Please try again."
}, xo = "Please reconnect your {{provider}}", Zo = {
  sendToSalesforce: "Send to Salesforce",
  dismiss: "Dismiss",
  cancel: "Cancel",
  name: "Name",
  namePlaceholder: "Full name",
  email: "Email",
  emailPlaceholder: "Email address",
  role: "Role",
  rolePlaceholder: "What's their role?",
  title: "Title",
  titlePlaceholder: "What's their title?",
  new: "New",
  view: "View",
  addNewContact: "Add new contact",
  addNewContactRole: "Add new contact role",
  addContactAndRolesHeader: "Add contacts / contact roles",
  addContactAndRolesDescription: "☝️ Link this call to an opportunity or account to streamline updating Salesforce",
  contactCreated: "Contact created",
  contactUpdated: "Contact updated",
  salesforce: {
    addContactAndRolesDescription: "☝️ Link this call to an opportunity or account to streamline updating Salesforce"
  },
  hubspot: {
    addContactAndRolesDescription: "☝️ Link this call to an opportunity or account to streamline updating Hubspot"
  }
}, Jo = {
  required: "Required",
  fold: "Fold",
  sentSuccessfully: "Sent successfully",
  failedToSend: "Failed to send"
}, Kt = {
  connect_companyToOtherToolsYouUse: Ro,
  connectAnotherAccount: Mo,
  disconnectAccount: No,
  connectMoreIntegrations: zo,
  streamlineYourWorkByConnectingIntegrations: Bo,
  activeConnections: Fo,
  integratedPlatform: Ko,
  category: qo,
  compatibility: Lo,
  revokeAccess: jo,
  "revokeAccessDescription{connection}": "Are you sure you want to revoke access for {{provider}} ({{connection}})",
  categories: Ho,
  "catch-up": "Catch-up",
  insight: Uo,
  actionItem: Go,
  link: Qo,
  gCal: Yo,
  gChrome: Vo,
  errors: Wo,
  reconnectProvider: xo,
  crm: Zo,
  global: Jo
}, Xo = {
  guestSkippedSelf: "{{name}} skipped themselves",
  hostSkippedGuest: "{{name}} was skipped",
  failedToSendMessage: "Failed to send message"
}, ea = {
  session: Xo
}, ta = "Action timed out. Please check your internet connection and try again.", sa = {
  400: "This email address is not registered on $t(_company). Please make sure it is correct or sign up below.",
  404: "$t(responses.login.400)",
  500: "$t(responses.default)",
  2009: "Please check your email address or sign up"
}, na = {
  400: "$t(responses.default)",
  500: "$t(responses.default)"
}, oa = {
  2033: "Successfully created session.",
  2064: "Successfully updated session."
}, aa = {
  200: "Successfully created your first session.",
  201: "$t(responses.dashboardSession.200)"
}, ra = {
  2084: "Session access request was already in approved state",
  2088: "You've already requested access to this session.",
  email: {
    200: "People added"
  },
  domain: {
    200: "Group added"
  }
}, ia = {
  200: "Great! We'll keep you up to date."
}, ca = {
  400: "The verification code is incorrect. Please double check.",
  404: "$t(responses.default)",
  405: "The verification code is incorrect. Please double check.",
  500: "$t(responses.default)"
}, ua = {
  500: "Sorry, something went wrong. Please try again.",
  avatar: {
    400: "Error saving avatar. Please try again."
  }
}, da = {
  default: "Sorry, something went wrong. Please try again.",
  timeout: ta,
  login: sa,
  register: na,
  session: oa,
  dashboardSession: aa,
  sessionAccess: ra,
  subscribe: ia,
  verify: ca,
  user: ua
}, la = "Select meeting type", ma = "General", ga = "1-on-1", pa = "All Hands", fa = "Interview", Sa = "Planning Session", ya = "Catchup / Chat", ha = "Knowledge Sharing", va = "Team Sync", ba = "Client Meeting", $a = "Workshop", Aa = "Board Meeting", Ea = "Technical Review", Ca = "Crisis Management", wa = {
  selectMeetingType: la,
  general_meeting: ma,
  one_on_one: ga,
  all_hands: pa,
  interview: fa,
  planning_session: Sa,
  catchup_coffee: ya,
  knowledge_sharing: ha,
  team_sync: va,
  client_meeting: ba,
  workshop: $a,
  board_meeting: Aa,
  technical_review: Ea,
  crisis_management: Ca
}, _a = `Here are the translations into Italian:

Agree - D'accordo`, Ta = "Celebrate - Festeggia", Ia = "Completed - Completato", ka = "Disagree - Non d'accordo", Oa = "Funny - Divertente", Da = "Hi - Ciao", Pa = "Love - Amore", Ra = 'Wow - Wow (Note: "Wow" is often used in Italian as an exclamation of surprise or amazement, much like in English)', Ma = {
  agree: _a,
  celebrate: Ta,
  completed: Ia,
  disagree: ka,
  funny: Oa,
  hi: Da,
  love: Pa,
  wow: Ra
}, Na = {
  "logged-ticket-private": "{{name}} ha registrato questo",
  view: "Visualizza",
  "view-ticket": "Visualizza ticket",
  meeting: "Incontro",
  "shared-private": "{{name}} ha condiviso questo",
  "logged-ticket-public": "{{name}} ha registrato questo",
  "shared-public": "{{name}} ha registrato questo",
  "crated-meeting-public": "{{name}} ha condiviso un incontro",
  "crated-meeting-private": "{{name}} ha creato un incontro"
}, za = {
  ticketing: "Emissione di biglietti",
  messaging: "Messaggistica"
}, qt = {
  link: Na,
  category: za
}, Ba = {
  guestSkippedSelf: "{{name}} si è saltato/a",
  hostSkippedGuest: "{{name}} è stato/a saltato/a",
  messageCode: {
    0: "⭐️ <span/> Stai facendo <em>benissimo!</em>",
    1: "👍 <span/> Ti <em>sentiamo</em> e vediamo",
    2: "☎️ <span/> Non possiamo <em>sentirti</em>",
    3: "🔴 <span/> Non possiamo <em>vederti</em>",
    4: "🔇 <span/> Per favore, <em>silenzia</em> il tuo microfono",
    5: "📢 <span/> Regola il tuo <em>audio</em>",
    6: "💡 <span/> Regola la tua <em>illuminazione</em>",
    7: "🔄 <span/> <em>Aggiorna</em> il tuo browser",
    8: "💪 <span/> Per favore, <em>rientra</em> nella coda",
    9: "⚠️ <span/>  Testa <em>camera e audio</em>",
    10: "⭐️ <span/> Sei stato/a <em>fantastico/a!</em>",
    11: "🎧 <span/> Prova a sistemare il tuo <em>audio</em>",
    12: "💡 <span/> Prova a sistemare la tua <em>illuminazione</em>",
    13: "🎯 <span/> <em>Invita persone</em> a unirsi",
    14: "⭐️ <span/> Sembri <em>fantastico/a!</em>",
    15: "👀 <span/> Controlla <em>il tuo telefono</em>",
    16: "🔴 <span/> Il tuo <em>video</em> ha problemi",
    17: "🎬 <span/> Sei il prossimo/a",
    18: "⏸ <span/> Ho <em>mess in pausa</em> la coda",
    19: "▶️ <span/> Devi <em>cliccare per riprendere</em>",
    100: "👍",
    101: "👎",
    102: "❤️",
    103: "👏️"
  },
  failedToSendMessage: "Impossibile inviare il messaggio",
  botsAddedToQueue: "Bot aggiunti alla coda"
}, Fa = {
  session: Ba
}, Ka = "Azione scaduta. Si prega di controllare la propria connessione internet e riprovare.", qa = {
  400: "Questo indirizzo email non è registrato su $t(_company). Si prega di assicurarsi che sia corretto o di registrarsi qui sotto.",
  404: "$t(responses.login.400)",
  500: "$t(responses.default)"
}, La = {
  400: "$t(responses.default)",
  500: "$t(responses.default)"
}, ja = {
  2033: "Sessione creata con successo.",
  2064: "Sessione aggiornata con successo."
}, Ha = {
  200: "Creata con successo la tua prima sessione.",
  201: "$t(responses.dashboardSession.200)"
}, Ua = {
  2084: "La richiesta di accesso alla sessione era già in stato approvato",
  2088: "Hai già richiesto l'accesso a questa sessione.",
  email: {
    200: "Persone aggiunte"
  },
  domain: {
    200: "Gruppo aggiunto"
  }
}, Ga = {
  200: "Ottimo! Ti terremo aggiornato."
}, Qa = {
  404: "$t(responses.default)",
  405: "Il codice di verifica non è corretto. Per favore, ricontrolla.",
  500: "$t(responses.default)"
}, Ya = {
  500: "Mi dispiace, si è verificato un errore. Per favore, riprova.",
  avatar: {
    400: "Errore nel salvataggio dell'avatar. Per favore, riprova."
  }
}, Va = {
  default: "Mi dispiace, si è verificato un errore. Per favore, riprova.",
  timeout: Ka,
  login: qa,
  register: La,
  session: ja,
  dashboardSession: Ha,
  sessionAccess: Ua,
  subscribe: Ga,
  verify: Qa,
  user: Ya
}, Wa = "Einverstanden", xa = "Feiern", Za = "Abgeschlossen", Ja = "Nicht einverstanden", Xa = "Lustig", er = "Hallo", tr = "Liebe", sr = "Wow", nr = {
  agree: Wa,
  celebrate: xa,
  completed: Za,
  disagree: Ja,
  funny: Xa,
  hi: er,
  love: tr,
  wow: sr
}, or = {
  "logged-ticket-private": "{{name}} hat dies protokolliert",
  view: "Ansehen",
  "view-ticket": "Ticket anzeigen",
  meeting: "Besprechung",
  "shared-private": "{{name}} hat dies geteilt",
  "logged-ticket-public": "{{name}} hat dies protokolliert",
  "shared-public": "{{name}} hat dies protokolliert",
  "crated-meeting-public": "{{name}} hat eine Besprechung geteilt",
  "crated-meeting-private": "{{name}} hat eine Besprechung erstellt"
}, ar = {
  ticketing: "Ticketing",
  messaging: "Nachrichtenversand"
}, Lt = {
  link: or,
  category: ar
}, rr = {
  guestSkippedSelf: "{{name}} hat sich selbst ausgelassen",
  hostSkippedGuest: "{{name}} wurde übersprungen",
  messageCode: {
    0: "⭐️ <span/> Du machst das <em>großartig!</em>",
    1: "👍 <span/> Wir <em>hören</em> & sehen dich",
    2: "☎️ <span/> Können dich nicht <em>hören</em>",
    3: "🔴 <span/> Können dich nicht <em>sehen</em>",
    4: "🔇 <span/> Bitte schalte dein Mikrofon <em>stumm</em>",
    5: "📢 <span/> Behebe dein <em>Audio</em>-Problem",
    6: "💡 <span/> Korrigiere deine <em>Beleuchtung</em>",
    7: "🔄 <span/> Aktualisiere deinen Browser <em>neu</em>",
    8: "💪 <span/> Bitte trete der Warteschlange <em>erneut bei</em>",
    9: "⚠️ <span/> Teste <em>Kamera & Ton</em>",
    10: "⭐️ <span/> Du warst <em>großartig!</em>",
    11: "🎧 <span/> Versuche, dein <em>Audio</em>-Problem zu beheben",
    12: "💡 <span/> Versuche, deine <em>Beleuchtung</em> zu verbessern",
    13: "🎯 <span/> Bitte <em>leute</em>, der Sitzung beizutreten",
    14: "⭐️ <span/> Sieht <em>gut aus!</em>",
    15: "👀 <span/> Überprüfe <em>dein Handy</em>",
    16: "🔴 <span/> Dein <em>Video</em> funktioniert nicht richtig",
    17: "🎬 <span/> Du bist als Nächster dran",
    18: "⏸ <span/> Ich habe die Warteschlange <em>angehalten</em>",
    19: "▶️ <span/> Du musst <em>weiter</em> klicken",
    100: "👍",
    101: "👎",
    102: "❤️",
    103: "👏️"
  },
  failedToSendMessage: "Nachricht konnte nicht gesendet werden",
  botsAddedToQueue: "Bots wurden zur Warteschlange hinzugefügt"
}, ir = {
  session: rr
}, cr = "Aktion abgelaufen. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut.", ur = {
  400: "Diese E-Mail-Adresse ist auf $t(_company) nicht registriert. Bitte stellen Sie sicher, dass sie korrekt ist, oder melden Sie sich unten an.",
  404: "$t(responses.login.400)",
  500: "$t(responses.default)"
}, dr = {
  400: "$t(responses.default)",
  500: "$t(responses.default)"
}, lr = {
  2033: "Sitzung erfolgreich erstellt.",
  2064: "Sitzung erfolgreich aktualisiert."
}, mr = {
  200: "Ihre erste Sitzung wurde erfolgreich erstellt.",
  201: "$t(responses.dashboardSession.200)"
}, gr = {
  2084: "Die Anfrage auf Zugang zur Sitzung war bereits im genehmigten Zustand",
  2088: "Sie haben bereits Zugang zu dieser Sitzung beantragt.",
  email: {
    200: "Personen hinzugefügt"
  },
  domain: {
    200: "Gruppe hinzugefügt"
  }
}, pr = {
  200: "Großartig! Wir halten Sie auf dem Laufenden."
}, fr = {
  404: "$t(responses.default)",
  405: "Der Verifizierungscode ist falsch. Bitte überprüfen Sie noch einmal.",
  500: "$t(responses.default)"
}, Sr = {
  500: "Entschuldigung, etwas ist schiefgelaufen. Bitte versuchen Sie es erneut.",
  avatar: {
    400: "Fehler beim Speichern des Avatars. Bitte versuchen Sie es erneut."
  }
}, yr = {
  default: "Entschuldigung, etwas ist schiefgelaufen. Bitte versuchen Sie es erneut.",
  timeout: cr,
  login: ur,
  register: dr,
  session: lr,
  dashboardSession: mr,
  sessionAccess: gr,
  subscribe: pr,
  verify: fr,
  user: Sr
}, hr = "A continuación, la traducción solicitada de los textos al español:", vr = "Acuerdo", br = "Celebrar", $r = "Completado", Ar = "Desacuerdo", Er = "Divertido", Cr = "Hola", wr = "Amor", _r = {
  agree: hr,
  celebrate: vr,
  completed: br,
  disagree: $r,
  funny: Ar,
  hi: Er,
  love: Cr,
  wow: wr
}, Tr = {
  "logged-ticket-private": "{{name}} registró esto",
  view: "Ver",
  "view-ticket": "Ver ticket",
  meeting: "Reunión",
  "shared-private": "{{name}} compartió esto",
  "logged-ticket-public": "{{name}} registró esto",
  "shared-public": "{{name}} registró esto",
  "crated-meeting-public": "{{name}} ha compartido una reunión",
  "crated-meeting-private": "{{name}} creó una reunión"
}, Ir = {
  ticketing: "Gestión de tickets",
  messaging: "Mensajería"
}, jt = {
  link: Tr,
  category: Ir
}, kr = {
  guestSkippedSelf: "{{name}} se ha saltado a sí mismo",
  hostSkippedGuest: "{{name}} fue saltado",
  messageCode: {
    0: "⭐️ <span/> Estás haciendo un <em>gran trabajo</em>!",
    1: "👍 <span/> Te <em>escuchamos</em> y te vemos",
    2: "☎️ <span/> No podemos <em>oirte</em>",
    3: "🔴 <span/> No podemos <em>verte</em>",
    4: "🔇 <span/> Por favor, <em>silencia</em> tu micrófono",
    5: "📢 <span/> Arregla tu <em>audio</em>",
    6: "💡 <span/> Arregla tu <em>iluminación</em>",
    7: "🔄 <span/> <em>Actualiza</em> tu navegador",
    8: "💪 <span/> Por favor, <em>vuelve a</em> la cola",
    9: "⚠️ <span/>  Prueba la <em>cámara y el sonido</em>",
    10: "⭐️ <span/> ¡Estuviste <em>genial</em>!",
    11: "🎧 <span/> Intenta arreglar tu <em>audio</em>",
    12: "💡 <span/> Intenta arreglar tu <em>iluminación</em>",
    13: "🎯 <span/> <em>Pide a la gente</em> que se una",
    14: "⭐️ <span/> Te ves <em>genial</em>",
    15: "👀 <span/> Revisa <em>tu teléfono</em>",
    16: "🔴 <span/> <em>Tu vídeo</em> tiene un problema",
    17: "🎬 <span/> Tú sigues",
    18: "⏸ <span/> <em>He pausado</em> la cola",
    19: "▶️ <span/> Necesitas <em>hacer clic en reanudar</em>",
    100: "👍",
    101: "👎",
    102: "❤️",
    103: "👏️"
  },
  failedToSendMessage: "No se pudo enviar el mensaje",
  botsAddedToQueue: "Se agregaron bots a la cola"
}, Or = {
  session: kr
}, Dr = "Acción agotada por tiempo. Por favor, verifica tu conexión a internet e intenta de nuevo.", Pr = {
  400: "Esta dirección de correo electrónico no está registrada en $t(_company). Por favor, asegúrate de que es correcta o regístrate a continuación.",
  404: "$t(responses.login.400)",
  500: "$t(responses.default)"
}, Rr = {
  400: "$t(responses.default)",
  500: "$t(responses.default)"
}, Mr = {
  2033: "Sesión creada exitosamente.",
  2064: "Sesión actualizada exitosamente."
}, Nr = {
  200: "Has creado tu primera sesión exitosamente.",
  201: "$t(responses.dashboardSession.200)"
}, zr = {
  2084: "La solicitud de acceso a la sesión ya estaba en estado aprobado",
  2088: "Ya has solicitado acceso a esta sesión.",
  email: {
    200: "Personas añadidas"
  },
  domain: {
    200: "Grupo añadido"
  }
}, Br = {
  200: "¡Genial! Te mantendremos informado."
}, Fr = {
  404: "$t(responses.default)",
  405: "El código de verificación es incorrecto. Por favor, comprueba de nuevo.",
  500: "$t(responses.default)"
}, Kr = {
  500: "Lo sentimos, algo salió mal. Por favor, intenta de nuevo.",
  avatar: {
    400: "Error al guardar el avatar. Por favor, intenta de nuevo."
  }
}, qr = {
  default: "Lo sentimos, algo salió mal. Por favor, intenta de nuevo.",
  timeout: Dr,
  login: Pr,
  register: Rr,
  session: Mr,
  dashboardSession: Nr,
  sessionAccess: zr,
  subscribe: Br,
  verify: Fr,
  user: Kr
}, Lr = "Zgadzam się", jr = "Świętować", Hr = "Ukończony", Ur = "Nie zgadzam się", Gr = "Śmieszny", Qr = "Cześć", Yr = "Kochać", Vr = "Wow", Wr = {
  agree: Lr,
  celebrate: jr,
  completed: Hr,
  disagree: Ur,
  funny: Gr,
  hi: Qr,
  love: Yr,
  wow: Vr
}, xr = {
  "logged-ticket-private": "{{name}} zalogował to",
  view: "Widok",
  "view-ticket": "Zobacz zgłoszenie",
  meeting: "Spotkanie",
  "shared-private": "{{name}} udostępnił to",
  "logged-ticket-public": "{{name}} zalogował to",
  "shared-public": "{{name}} zalogował to",
  "crated-meeting-public": "{{name}} udostępnił spotkanie",
  "crated-meeting-private": "{{name}} utworzył spotkanie"
}, Zr = {
  ticketing: "System zgłoszeń",
  messaging: "Wiadomości"
}, Ht = {
  link: xr,
  category: Zr
}, Jr = {
  guestSkippedSelf: "{{name}} pominął sam siebie",
  hostSkippedGuest: "{{name}} został pominięty",
  messageCode: {
    0: "⭐️ <span/> Robisz to <em>świetnie!</em>",
    1: "👍 <span/> <em>Słyszymy</em> Cię i widzimy",
    2: "☎️ <span/> Nie <em>słyszymy</em> Cię",
    3: "🔴 <span/> Nie <em>widzimy</em> Ciebie",
    4: "🔇 <span/> Proszę, <em>wycisz</em> się",
    5: "📢 <span/> Napraw swoje <em>dźwięki</em>",
    6: "💡 <span/> Napraw swoje <em>oświetlenie</em>",
    7: "🔄 <span/> Odśwież swoją <em>przeglądarkę</em>",
    8: "💪 <span/> Proszę, <em>dołącz</em> ponownie do kolejki",
    9: "⚠️ <span/> Przetestuj <em>kamerę i dźwięk</em>",
    10: "⭐️ <span/> Byłeś <em>świetny!</em>",
    11: "🎧 <span/> Spróbuj naprawić swój <em>dźwięk</em>",
    12: "💡 <span/> Spróbuj naprawić swoje <em>oświetlenie</em>",
    13: "🎯 <span/> <em>Zaproś ludzi</em> do dołączenia",
    14: "⭐️ <span/> Wyglądasz <em>świetnie!</em>",
    15: "👀 <span/> Sprawdź <em>swoją komórkę</em>",
    16: "🔴 <span/> <em>Twoje wideo</em> ma usterki",
    17: "🎬 <span/> Jesteś następny",
    18: "⏸ <span/> <em>Zatrzymałem</em> kolejkę",
    19: "▶️ <span/> Musisz <em>kliknąć wznowienie</em>",
    100: "👍",
    101: "👎",
    102: "❤️",
    103: "👏️"
  },
  failedToSendMessage: "Nie udało się wysłać wiadomości",
  botsAddedToQueue: "Boty zostały dodane do kolejki"
}, Xr = {
  session: Jr
}, ei = "Czas działania minął. Proszę sprawdzić swoje połączenie internetowe i spróbować ponownie.", ti = {
  400: "Ten adres e-mail nie jest zarejestrowany w $t(_company). Upewnij się, że jest poprawny lub zarejestruj się poniżej.",
  404: "$t(responses.login.400)",
  500: "$t(responses.default)"
}, si = {
  400: "$t(responses.default)",
  500: "$t(responses.default)"
}, ni = {
  2033: "Pomyślnie utworzono sesję.",
  2064: "Pomyślnie zaktualizowano sesję."
}, oi = {
  200: "Pomyślnie utworzono Twoją pierwszą sesję.",
  201: "$t(responses.dashboardSession.200)"
}, ai = {
  2084: "Prośba o dostęp do sesji została już zatwierdzona",
  2088: "Już wysłałeś prośbę o dostęp do tej sesji.",
  email: {
    200: "Osoby dodane"
  },
  domain: {
    200: "Grupa dodana"
  }
}, ri = {
  200: "Świetnie! Będziemy Cię na bieżąco informować."
}, ii = {
  404: "$t(responses.default)",
  405: "Kod weryfikacyjny jest nieprawidłowy. Proszę sprawdzić ponownie.",
  500: "$t(responses.default)"
}, ci = {
  500: "Przepraszamy, coś poszło nie tak. Proszę spróbować ponownie.",
  avatar: {
    400: "Błąd zapisu awatara. Proszę spróbować ponownie."
  }
}, ui = {
  default: "Przepraszamy, coś poszło nie tak. Proszę spróbować ponownie.",
  timeout: ei,
  login: ti,
  register: si,
  session: ni,
  dashboardSession: oi,
  sessionAccess: ai,
  subscribe: ri,
  verify: ii,
  user: ci
}, di = "동의", li = "축하하다", mi = "완료됨", gi = "동의하지 않음", pi = "웃긴", fi = "안녕", Si = "사랑", yi = "와우", hi = {
  agree: di,
  celebrate: li,
  completed: mi,
  disagree: gi,
  funny: pi,
  hi: fi,
  love: Si,
  wow: yi
}, vi = {
  "logged-ticket-private": "{{name}}님이 이것을 기록했습니다",
  view: "보기",
  "view-ticket": "티켓 보기",
  meeting: "미팅",
  "shared-private": "{{name}}님이 이것을 공유했습니다",
  "logged-ticket-public": "{{name}}님이 이것을 기록했습니다",
  "shared-public": "{{name}}님이 이것을 기록했습니다",
  "crated-meeting-public": "{{name}}님이 미팅을 공유했습니다",
  "crated-meeting-private": "{{name}}님이 미팅을 만들었습니다"
}, bi = {
  ticketing: "티켓 발급",
  messaging: "메시징"
}, Ut = {
  link: vi,
  category: bi
}, $i = {
  guestSkippedSelf: "{{name}} 님이 자신을 건너뛰었습니다",
  hostSkippedGuest: "{{name}} 님이 건너뛰어졌습니다",
  messageCode: {
    0: "⭐️ <span/> 잘하고 있어요!<em>대단해!</em>",
    1: "👍 <span/> 여러분의 목소리를 <em>듣고</em> 보고 있습니다",
    2: "☎️ <span/> 당신의 목소리를 <em>들을 수</em> 없습니다",
    3: "🔴 <span/> 당신을 <em>볼 수</em> 없습니다",
    4: "🔇 <span/> 마이크를 <em>음소거</em>해 주세요",
    5: "📢 <span/> 오디오를 <em>고치세요</em>",
    6: "💡 <span/> 조명을 <em>고치세요</em>",
    7: "🔄 <span/> 브라우저를 <em>새로고침</em>하세요",
    8: "💪 <span/> 다시 대기열에 <em>들어가</em> 주세요",
    9: "⚠️ <span/>  카메라와 소리를 <em>테스트하세요</em>",
    10: "⭐️ <span/> 정말 <em>잘했어요!</em>",
    11: "🎧 <span/> 오디오를 고쳐 <em>보세요</em>",
    12: "💡 <span/> 조명을 고쳐 <em>보세요</em>",
    13: "🎯 <span/> 사람들에게 참여할 것을 <em>요청하세요</em>",
    14: "⭐️ <span/> 멋져 <em>보여요!</em>",
    15: "👀 <span/> 휴대폰을 <em>확인해보세요</em>",
    16: "🔴 <span/> <em>비디오</em>에 문제가 있습니다",
    17: "🎬 <span/> 곧 당신 차례입니다",
    18: "⏸ <span/> 대기열을 <em>일시 중지했습니다</em>",
    19: "▶️ <span/> 다시 시작하려면 <em>클릭하세요</em>",
    100: "👍",
    101: "👎",
    102: "❤️",
    103: "👏️"
  },
  failedToSendMessage: "메시지 전송 실패",
  botsAddedToQueue: "대기열에 봇이 추가되었습니다"
}, Ai = {
  session: $i
}, Ei = "작업 시간이 초과되었습니다. 인터넷 연결을 확인하고 다시 시도해 주세요.", Ci = {
  400: "이 이메일 주소는 $t(_company)에 등록되어 있지 않습니다. 올바른지 확인하거나 아래에서 가입해 주세요.",
  404: "$t(responses.login.400)",
  500: "$t(responses.default)"
}, wi = {
  400: "$t(responses.default)",
  500: "$t(responses.default)"
}, _i = {
  2033: "세션을 성공적으로 생성했습니다.",
  2064: "세션을 성공적으로 업데이트했습니다."
}, Ti = {
  200: "첫 세션을 성공적으로 생성했습니다.",
  201: "$t(responses.dashboardSession.200)"
}, Ii = {
  2084: "세션 접근 요청이 이미 승인된 상태입니다",
  2088: "이미 이 세션에 접근을 요청했습니다.",
  email: {
    200: "사람들이 추가되었습니다"
  },
  domain: {
    200: "그룹이 추가되었습니다"
  }
}, ki = {
  200: "좋습니다! 최신 정보를 알려드리겠습니다."
}, Oi = {
  404: "$t(responses.default)",
  405: "인증 코드가 정확하지 않습니다. 다시 확인해 주세요.",
  500: "$t(responses.default)"
}, Di = {
  500: "죄송합니다, 문제가 발생했습니다. 다시 시도해 주세요.",
  avatar: {
    400: "아바타 저장에 오류가 발생했습니다. 다시 시도해 주세요."
  }
}, Pi = {
  default: "죄송합니다, 문제가 발생했습니다. 다시 시도해 주세요.",
  timeout: Ei,
  login: Ci,
  register: wi,
  session: _i,
  dashboardSession: Ti,
  sessionAccess: Ii,
  subscribe: ki,
  verify: Oi,
  user: Di
}, Ri = "Concordo", Mi = "Celebre", Ni = "Concluído", zi = "Discordo", Bi = "Engraçado", Fi = "Oi", Ki = "Amor", qi = "Uau", Li = {
  agree: Ri,
  celebrate: Mi,
  completed: Ni,
  disagree: zi,
  funny: Bi,
  hi: Fi,
  love: Ki,
  wow: qi
}, ji = {
  "logged-ticket-private": "{{name}} registrou isto",
  view: "Visualizar",
  "view-ticket": "Ver bilhete/ingresso",
  meeting: "Reunião",
  "shared-private": "{{name}} compartilhou isto",
  "logged-ticket-public": "{{name}} registrou isto",
  "shared-public": "{{name}} registrou isto",
  "crated-meeting-public": "{{name}} compartilhou uma reunião",
  "crated-meeting-private": "{{name}} criou uma reunião"
}, Hi = {
  ticketing: "Emissão de bilhetes/ingressos",
  messaging: "Mensagens"
}, Gt = {
  link: ji,
  category: Hi
}, Ui = {
  guestSkippedSelf: "{{name}} pulou a sua vez",
  hostSkippedGuest: "{{name}} foi pulado",
  messageCode: {
    0: "⭐️ <span/> Você está indo <em>ótimo!</em>",
    1: "👍 <span/> Nós <em>ouvimos</em> e vemos você",
    2: "☎️ <span/> Não conseguimos <em>ouvir</em> você",
    3: "🔴 <span/> Não conseguimos <em>ver</em> você",
    4: "🔇 <span/> Por favor, <em>silencie-se</em>",
    5: "📢 <span/> Arrume o seu <em>áudio</em>",
    6: "💡 <span/> Ajuste a sua <em>iluminação</em>",
    7: "🔄 <span/> <em>Atualize</em> o seu navegador",
    8: "💪 <span/> Por favor, <em>volte</em> para a fila",
    9: "⚠️ <span/> Teste <em>câmera e som</em>",
    10: "⭐️ <span/> Você foi <em>ótimo!</em>",
    11: "🎧 <span/> Tente arrumar o seu <em>áudio</em>",
    12: "💡 <span/> Tente arrumar a sua <em>iluminação</em>",
    13: "🎯 <span/> <em>Peça para as pessoas</em> entrarem",
    14: "⭐️ <span/> Está <em>ótimo!</em>",
    15: "👀 <span/> Verifique <em>o seu celular</em>",
    16: "🔴 <span/> O seu <em>vídeo</em> está com defeito",
    17: "🎬 <span/> Você é o próximo",
    18: "⏸ <span/> <em>Eu pausei</em> a fila",
    19: "▶️ <span/> Você precisa <em>clicar em continuar</em>",
    100: "👍",
    101: "👎",
    102: "❤️",
    103: "👏️"
  },
  failedToSendMessage: "Falha ao enviar mensagem",
  botsAddedToQueue: "Bots foram adicionados à fila"
}, Gi = {
  session: Ui
}, Qi = "Ação esgotou o tempo. Por favor, verifique sua conexão com a internet e tente novamente.", Yi = {
  400: "Este endereço de email não está registrado no $t(_company). Por favor, verifique se está correto ou inscreva-se abaixo.",
  404: "$t(responses.login.400)",
  500: "$t(responses.default)"
}, Vi = {
  400: "$t(responses.default)",
  500: "$t(responses.default)"
}, Wi = {
  2033: "Sessão criada com sucesso.",
  2064: "Sessão atualizada com sucesso."
}, xi = {
  200: "Sua primeira sessão foi criada com sucesso.",
  201: "$t(responses.dashboardSession.200)"
}, Zi = {
  2084: "Solicitação de acesso à sessão já estava em estado aprovado",
  2088: "Você já solicitou acesso a esta sessão.",
  email: {
    200: "Pessoas adicionadas"
  },
  domain: {
    200: "Grupo adicionado"
  }
}, Ji = {
  200: "Ótimo! Vamos mantê-lo(a) atualizado(a)."
}, Xi = {
  404: "$t(responses.default)",
  405: "O código de verificação está incorreto. Por favor, verifique novamente.",
  500: "$t(responses.default)"
}, ec = {
  500: "Desculpe, algo deu errado. Por favor, tente novamente.",
  avatar: {
    400: "Erro ao salvar avatar. Por favor, tente novamente."
  }
}, tc = {
  default: "Desculpe, algo deu errado. Por favor, tente novamente.",
  timeout: Qi,
  login: Yi,
  register: Vi,
  session: Wi,
  dashboardSession: xi,
  sessionAccess: Zi,
  subscribe: Ji,
  verify: Xi,
  user: ec
}, vd = {
  en: {
    responses: da,
    notifications: ea,
    emojiReactions: Po,
    integrations: Kt,
    userMeetingTypes: wa
  },
  it: {
    responses: Va,
    notifications: Fa,
    emojiReactions: Ma,
    integrations: qt
  },
  de: {
    responses: yr,
    notifications: ir,
    emojiReactions: nr,
    integrations: Lt
  },
  es: {
    responses: qr,
    notifications: Or,
    emojiReactions: _r,
    integrations: jt
  },
  pl: {
    responses: ui,
    notifications: Xr,
    emojiReactions: Wr,
    integrations: Ht
  },
  ko: {
    responses: Pi,
    notifications: Ai,
    emojiReactions: hi,
    integrations: Ut
  },
  pt: {
    responses: tc,
    notifications: Gi,
    emojiReactions: Li,
    integrations: Gt
  }
}, bd = {
  en: Kt,
  it: qt,
  de: Lt,
  es: jt,
  pl: Ht,
  ko: Ut,
  pt: Gt
}, te = {
  "✅": "emojiReactions.completed",
  "❤️": "emojiReactions.love",
  "👋": "emojiReactions.hi",
  "👍": "emojiReactions.agree",
  "👎": "emojiReactions.disagree",
  "👏": "emojiReactions.celebrate",
  "😆": "emojiReactions.funny",
  "😮": "emojiReactions.wow"
}, P = [
  "👍",
  "😆",
  "❤️",
  "👋",
  "👎",
  "👏",
  "😮",
  "✅"
], $d = [
  {
    label: te[P[0]],
    symbol: P[0]
  },
  {
    label: te[P[1]],
    symbol: P[1]
  },
  {
    label: te[P[2]],
    symbol: P[2]
  },
  {
    label: te[P[3]],
    symbol: P[3]
  },
  {
    label: te[P[4]],
    symbol: P[4]
  },
  {
    label: te[P[5]],
    symbol: P[5]
  },
  {
    label: te[P[6]],
    symbol: P[6]
  },
  {
    label: te[P[7]],
    symbol: P[7]
  }
];
export {
  bt as CLEAR_SESSION,
  Et as CLOSE_SESSION_MODAL,
  mt as CrmProvider,
  Ct as GRANT_CONSENT_TO_BROADCAST,
  ft as NAVIGATE_TO,
  wt as REVOKE_CONSENT_TO_BROADCAST,
  an as SETUP_CURRENT_SESSION,
  yt as SET_ACTIVE_SESSION,
  ht as SET_BOTH_SESSIONS,
  St as SET_CURRENT_SESSION,
  pt as SET_MODAL,
  vt as SET_SESSION,
  At as SET_SESSION_MODAL,
  fn as SessionModalType,
  zu as SessionStateKey,
  $t as UPDATE_SESSION,
  Rn as addFullName,
  kt as addFullNames,
  jc as anonymize,
  Lc as areEqual,
  Js as buildSocialProfileLink,
  vc as buildSocialUrls,
  Pc as clearSession,
  Mc as closeSessionModal,
  vd as commonTranslations,
  G as config,
  hu as createIsActiveSessionHost,
  $d as emojiReactionsOptions,
  wc as featureFlagsService,
  au as findPlan,
  ou as findPlanById,
  su as findPrice,
  Pn as fullName,
  Kc as fullName2,
  Be as getFeature,
  qc as getFirstNameAndInitial,
  xu as getLastMessage,
  zt as getLastMessageByRole,
  Vu as getLastUserMessage,
  qn as getPrice,
  ln as getPrimaryHost,
  Xs as getProfileUrl,
  pn as getSessionEndedAt,
  mn as getSessionPrimaryHost,
  gn as getSessionStartedAt,
  _t as getSessionStateChangeAt,
  Kn as getTrialDaysLeft,
  Nc as grantConsentToBroadcast,
  Bt as handleMessageEvent,
  hc as hasGoogleCalendarIntegration,
  Hu as hubspot,
  fc as initConfig,
  yc as integrations,
  Sc as isAnyRequestErrorCode,
  eu as isFeatureDisabled,
  Fn as isFeatureEnabled,
  Xc as isFreeOrFreeTrialPlan,
  zn as isFreePlan,
  Bn as isFreeTrialPlan,
  Jc as isFreeType,
  nu as isPaymentFailing,
  Nn as isPlanCanceling,
  Zc as isPlanType,
  Ws as isRequestErrorCode,
  Bc as isSessionActive,
  Fc as isSessionPrimaryHost,
  Bu as isTeamMember,
  Fu as isTeamOwner,
  tu as isTrialAvailable,
  Pt as isTrialPlan,
  bd as l10nIntegrations,
  tn as modalInitialState,
  sn as modalReducer,
  Ic as navigateTo,
  nn as navigationInitialState,
  on as navigationReducer,
  Vc as planFeatures,
  Yc as planOrder,
  xc as premiumSubscriptionPlans,
  hd as reducers,
  Gn as requestDeletionMutation,
  zc as revokeConsentToBroadcast,
  ad as salesforce,
  B as selectActiveSession,
  cu as selectActiveSessionAbout,
  uu as selectActiveSessionCover,
  lu as selectActiveSessionEndTimestamps,
  $u as selectActiveSessionEndedAtState,
  Pe as selectActiveSessionHosts,
  vu as selectActiveSessionHostsCount,
  Fe as selectActiveSessionId,
  Su as selectActiveSessionOrganizer,
  Rt as selectActiveSessionOrganizerId,
  ru as selectActiveSessionRecurrenceId,
  jn as selectActiveSessionSettings,
  pu as selectActiveSessionShareUrl,
  du as selectActiveSessionStartTimestamps,
  bu as selectActiveSessionStartedAtState,
  Mt as selectActiveSessionStateUpdates,
  gu as selectActiveSessionStatus,
  Ln as selectActiveSessionSubscriptionPlan,
  mu as selectActiveSessionSubscriptionPlanStreamQuality,
  iu as selectActiveSessionTitle,
  Ot as selectAllSessions,
  Hn as selectConsentToBroadcast,
  H as selectCurrentSession,
  Cu as selectCurrentSessionAbout,
  wu as selectCurrentSessionCover,
  Ou as selectCurrentSessionEndTimestamps,
  Ke as selectCurrentSessionHosts,
  Nu as selectCurrentSessionHostsCount,
  Un as selectCurrentSessionId,
  Mu as selectCurrentSessionOrganizer,
  Nt as selectCurrentSessionOrganizerId,
  Au as selectCurrentSessionRecurrenceId,
  Iu as selectCurrentSessionSettings,
  _u as selectCurrentSessionShareUrl,
  ku as selectCurrentSessionStartTimestamps,
  Du as selectCurrentSessionStatus,
  Tu as selectCurrentSessionSubscriptionPlan,
  Eu as selectCurrentSessionTitle,
  yu as selectIsActiveSessionHost,
  fu as selectIsActiveSessionOrganizer,
  Pu as selectIsCurrentSessionHost,
  Ru as selectIsCurrentSessionOrganizer,
  Tc as selectModal,
  kc as selectNavigation,
  Uc as selectSessionById,
  Gc as selectSessionExists,
  Qc as selectSessionModal,
  de as selectSessionState,
  se as sessionInitialState,
  Hc as sessionsReducer,
  Dc as setActiveSession,
  cn as setBothSessions,
  rn as setCurrentSession,
  _c as setModal,
  un as setSession,
  Rc as setSessionModal,
  Oc as setupCurrentSession,
  _e as setupSession,
  Zs as socialProfileUrls,
  lt as sortHosts,
  dn as updateSession,
  Wc as usdDivisor,
  bc as useActivateTrial,
  Ft as useAi,
  cd as useCards,
  ud as useCardsData,
  dd as useClearCardsCache,
  Cc as useEndSession,
  sd as useFeedback,
  uo as useFeedbackMutation,
  $c as useGetBindingsByOwnerSessionRecurrence,
  gt as useGetBindingsByOwnerSessionRecurrenceData,
  Ac as useGetBindingsData,
  oo as useGetConnections,
  Uu as useGetConnectionsData,
  Yn as useGetSession,
  Ku as useGetSessionData,
  Lu as useGetSessionWithAccess,
  Ec as useHasBinding,
  ju as useHostOptIn,
  Gu as useIntegrations,
  Wu as useLastAiMessage,
  Qu as useLocalParticipantHasJoined,
  Yu as useManageSessionPendingAccess,
  ed as useMeetingMemory,
  nd as useOffTheRecord,
  mo as useOffTheRecordEnabled,
  co as usePrivateQueryConfig,
  Zu as usePrivateQueryKey,
  td as useRefreshLastResponse,
  xn as useRequestAccessStatus,
  od as useRequestDeletion,
  rd as useSessionAIFeed,
  id as useSessionAIFeedData,
  ld as useSessionPendingAccess,
  md as useSessionPendingAccessData,
  gd as useSessionPendingAccessStats,
  pd as useSessionPresence,
  fd as useSessionPresenceData,
  Ju as useStopGeneration,
  Xu as useSuggestions,
  Sd as useUpdateAiFeed,
  ao as useUpdateGetConnectionsData,
  ro as useUpdateParticipantMetadata,
  yd as useUpdateSession,
  Wn as useUserSessionRequestAccess,
  qu as useUserSessionRequestAccessData
};
