import f from "dayjs";
import { VideoFileExtension as S, SocialKeys as $ } from "@waitroom/models";
const R = (t) => t != null, l = (t) => typeof t == "function", M = l, j = (t) => M(t) ? t() : t, x = (t, ...n) => n.some((e) => typeof t === e), N = (t, n) => n in t, Y = (t, n) => t instanceof n, L = (...t) => (n) => t.reduceRight((e, r) => r(e), n), b = (...t) => (n) => t.reduce((e, r) => r(e), n), U = (t) => (...n) => Promise.resolve(t(...n)), v = (t) => (n) => {
  try {
    return t();
  } catch (e) {
    return n ? n(e) : e;
  }
}, P = (t) => async (n) => {
  try {
    return await t();
  } catch (e) {
    return n ? n(e) : e;
  }
}, C = async (t, n) => {
  var o;
  const e = {
    delay: 300,
    retries: 2,
    ...n
  };
  let r;
  for (let s = 1; s <= e.retries; s++)
    try {
      return await t();
    } catch (i) {
      if (r = i, (o = e.shouldSkip) != null && o.call(e, i)) throw i;
      s < e.retries && await new Promise(
        (c) => setTimeout(c, (e.delay ?? 300) * s)
      );
    }
  throw r;
}, _ = (t, n) => {
  let e = Date.now();
  const r = async (o = 1) => {
    var c, h, m;
    const s = {
      initialDelay: 300,
      maxDelay: 5e3,
      retries: 3,
      resetTime: 1e4,
      // 10s
      ...n
    }, i = Date.now();
    i - e > s.resetTime && (o = 1), e = i;
    try {
      return await t();
    } catch (u) {
      if ((c = s.onError) == null || c.call(s, u), (h = s.shouldSkip) != null && h.call(s, u) || s.retries !== -1 && o >= s.retries) throw u;
      return (m = s.onRetry) == null || m.call(s, u, o), new Promise(
        (w) => setTimeout(
          async () => w(r(o + 1)),
          Math.min(s.initialDelay * 2 ** (o - 1), s.maxDelay)
        )
      );
    }
  };
  return r();
}, q = (t) => (n) => (...e) => t(e) ? n(e) : void 0, z = () => typeof window < "u", H = (t, n) => {
  const e = {};
  if (!t) return e;
  for (let r = 0; r < t.length; r++) {
    const o = t[r];
    n ? n(e, o) : e[String(o)] = o;
  }
  return e;
}, I = (t, n) => t ? (Array.isArray(n) ? n : [n]).every((r) => t.includes(r)) : !1, J = (t, n) => t ? (Array.isArray(n) ? n : [n]).some((r) => t.includes(r)) : !1, V = (t, n, e = ", ") => {
  if (!t) return "";
  const r = l(n) ? n : (o) => String(o[n] ?? "");
  return t.map(r).filter(Boolean).join(e);
}, Z = (t, n, e) => {
  if (!t) return "";
  const r = l(n) ? n : (c) => String(c[n] ?? ""), o = t.map(r).filter(Boolean);
  if (o.length <= 1) return o.join(", ");
  const s = [...o], i = s.pop();
  return `${s.join(", ")} ${e} ${i}`;
};
function k(t, n) {
  return [
    ...t.filter((e) => n.indexOf(e) === -1),
    ...n.filter((e) => t.indexOf(e) === -1)
  ];
}
function W(t) {
  const n = Math.floor(Math.random() * t.length);
  return t[n];
}
function G(t, n) {
  const e = {}, r = [];
  for (const o of t) {
    const s = o[n];
    e[s] || (e[s] = !0, r.push(o));
  }
  return r;
}
const a = [
  "B",
  "KB",
  "MB",
  "GB",
  "TB",
  "PB",
  "EB",
  "ZB",
  "YB"
], d = 1024, Q = (t, n = 2) => {
  if (t === 0) return "0 B";
  const e = n < 0 ? 0 : n, r = Math.floor(Math.log(t) / Math.log(d));
  return `${parseFloat((t / d ** r).toFixed(e))} ${a[r]}`;
}, K = (t, n = "B", e = "MB", r = 2) => {
  if (!t || n === e) return t;
  const o = r < 0 ? 0 : r, s = a.indexOf(e), i = a.indexOf(n);
  if (s < 0 || i < 0) return t;
  const c = s - i;
  return parseFloat((t / d ** c).toFixed(o));
}, g = /:00/, X = 864e5, tt = 36e5, nt = 6e4, et = (t, n, {
  separator: e = " — ",
  date: r = "dddd, MMM D, YYYY",
  time: o = "h:mm A"
} = {}) => {
  const s = f(t).format(`${r}${e}${o}`).replace(g, ""), i = f(n).format(o).replace(g, "");
  return `${s} - ${i}`;
}, p = (t) => {
  const e = ~~((t < 0 ? 0 : t) / 1e3), r = ~~(e / 60), o = ~~(r / 60);
  return [e % 60, r % 60, o % 24, ~~(o / 24)];
}, y = ["s", "m", "h", "d"], A = (t, n = y) => {
  let e = "";
  for (let r = t.length - 1; r >= 0; r--) {
    const o = t[r], s = n[r];
    o > 0 && s && (e += `${o}${n[r]} `);
  }
  return e.trimEnd();
}, rt = (t, n = y) => {
  const e = p(t);
  return A(e, n);
}, ot = (t) => typeof t == "number" ? t * 1e3 : t, st = (t, n) => {
  const e = f(t), r = [`FREQ=${n}`];
  return n === "weekly" ? r.push(`BYDAY=${e.format("dd")}`) : n === "monthly" ? r.push(`BYMONTHDAY=${e.format("D")}`) : n === "yearly" && r.push(`BYMONTH=${e.format("M")};BYMONTHDAY=${e.format("D")}`), r.join(";").toUpperCase();
}, it = (t, n = ":") => {
  const [e, r, o, s] = p(t), i = [];
  let c = o;
  return s > 0 && (c += s * 24), c > 0 ? (i.push(String(c)), i.push(String(r).padStart(2, "0"))) : i.push(String(r)), i.push(String(e).padStart(2, "0")), i.join(n);
}, ct = (t, n, e, r) => {
  n.forEach((o) => {
    t.addEventListener(o, e, r);
  });
}, ut = (t, n, e, r) => {
  n.forEach((o) => {
    t.removeEventListener(o, e, r);
  });
}, ft = (t = "") => new DOMParser().parseFromString(t, "text/html").documentElement.textContent || t, at = (t, n, e, r = {}) => t.reduce((o, s) => {
  const i = e[s] ?? n[s];
  return o[s] = s in r || i === void 0 ? i : String(i), o;
}, {});
function dt(t) {
  try {
    return JSON.parse(t), !0;
  } catch {
    return !1;
  }
}
function lt(t, n) {
  if (!t) return n;
  try {
    return JSON.parse(t);
  } catch {
    return n;
  }
}
const B = typeof navigator < "u" && "locks" in navigator, ht = (t, n, e = {}) => B ? (...r) => navigator.locks.request(t, e, async () => await n(...r)) : n, mt = (t = 0, n = Number.MAX_SAFE_INTEGER) => Math.floor(Math.random() * (n - t + 1)) + t, gt = (t, n, e = "+ ") => t > n ? `${e}${n}` : `${t}`, pt = (t) => t < 1e3 ? `${t}` : t < 1e6 ? `${Number((t / 1e3).toFixed(1))}k` : `${Number((t / 1e6).toFixed(1))}m`, E = (t) => t != null && (typeof t == "string" || typeof t == "number"), yt = (t) => t !== null && typeof t == "object" && !Array.isArray(t), O = (t) => Object.keys(t);
function wt(t, ...n) {
  return n.length ? n.reduce((r, o) => {
    const { [o]: s, ...i } = r;
    return i;
  }, t) : t;
}
function St(t, n, e) {
  if (!t) return e;
  const o = (typeof n == "string" ? n.split(".") : n).reduce(
    (s, i) => s && s[i] !== void 0 ? s[i] : void 0,
    t
  );
  return o !== void 0 ? o : e;
}
function D(t, n, e) {
  return O({ ...t, ...n }).reduce((r, o) => ((e ? e(t[o]) === e(n[o]) : t[o] === n[o]) || (r[o] = n[o]), r), {});
}
const $t = (t, n) => Object.entries(D(t, n)).length === 0, Mt = (t, n) => {
  if (!t) return;
  const e = {};
  for (let r = 0; r < t.length; r++) {
    const o = t[r], s = typeof n == "function" ? n(o) : o[n], i = E(s) ? s : String(s);
    e[i] = (e[i] || []).concat(o);
  }
  return e;
}, At = (t, n = S.HLS) => {
  for (let e = t.length - 1; e > -1; e -= 1)
    if (t[e].endsWith(n))
      return t[e];
  return t[0];
}, Bt = async (t) => {
  var r;
  const n = (r = t == null ? void 0 : t.getReader) == null ? void 0 : r.call(t);
  if (!n) return;
  const e = await n.read();
  return new TextDecoder().decode(e.value);
}, Et = (t, n, e = "...") => !t || t.length <= n ? t : `${t.substring(0, n)}${e}`, Ot = (t) => !!t && t.trim() !== "", Dt = (t) => !!t && String(t).toLowerCase() !== "false" && t !== "0", Ft = ({
  text: t,
  /** Lenght of the step for reducing the size */
  lengthStep: n,
  /** Size reduction for each step  */
  sizeStep: e = 0.1,
  /** Minimum size */
  min: r = 0.5
}) => {
  const o = (t || "").length, s = 1 - Math.floor(o / n) * e;
  return s < r ? r : s;
}, Tt = `  
`, Rt = (t, n = "-") => t.join(n), jt = (t = "") => t === "" ? "" : ` ${t}`, xt = ({
  params: t,
  relative: n = !1
} = {}) => {
  const e = new URL(window.location.href);
  if (t)
    for (const r of Object.keys(t))
      e.searchParams.set(r, String(t[r]));
  return n ? `${e.pathname}${e.search}` : e.href;
}, Nt = ({
  relative: t = !1,
  queryString: n = !0
} = {}) => {
  const e = new URL(window.location.href);
  return n || (e.search = ""), t ? `${e.pathname}${e.search}` : e.href;
}, Yt = (t) => t ? /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i.test(
  t
) : !1, Lt = (t) => !t || t.indexOf("://") === -1 && t.indexOf("//") !== 0, bt = (t, n) => {
  const e = new URL(t);
  for (const r of Object.keys(n))
    e.searchParams.set(r, String(n[r]));
  return e.href;
};
function Ut(t) {
  return t && t.replace(/(wss?:\/\/|wss?:)/g, "").replace("/socket", "").replace("/websocket", "");
}
const vt = (t) => t ? t == null ? void 0 : t.reduce((n, e) => (e.platform === "email" || (n[e.platform] = e.url), n), {}) : void 0, Pt = (t) => t ? $.reduce((n, e) => {
  const r = t[e];
  return !r || e === "email" || n.push({
    platform: e,
    url: r
  }), n;
}, []) : void 0;
export {
  X as MS_DAY,
  tt as MS_HOUR,
  nt as MS_MINUTE,
  bt as addQueryParams,
  $t as areDeepEqual,
  H as arrayToMap,
  ct as bindEvents,
  xt as buildCurrentUrl,
  Rt as buildKey,
  Ft as calcResponsiveSize,
  L as compose,
  I as containsAll,
  J as containsAny,
  K as convertBytes,
  ft as decodeHtmlEntities,
  D as diff,
  k as difference,
  y as durationSuffixDefaults,
  rt as durationToFormat,
  vt as flattenSocials,
  Q as formatBytes,
  et as fromTo,
  St as get,
  Nt as getCurrentUrl,
  Ut as getDomainKeyFromUrl,
  it as getFormattedLength,
  W as getRandomItem,
  mt as getRandomNumber,
  At as getRecordingURL,
  Mt as groupBy,
  q as ifThen,
  R as isDefined,
  M as isFn,
  l as isFunction,
  Y as isInstanceOf,
  dt as isJsonString,
  Ot as isNotEmpty,
  yt as isObject,
  Lt as isRelativeUrl,
  N as isType,
  x as isTypeof,
  E as isValidKey,
  Yt as isValidUrl,
  V as joinOn,
  Z as joinWithAnd,
  O as keysOf,
  at as mergeValues,
  d as multiplier,
  B as navigatorLockSupported,
  Tt as newline,
  wt as omit,
  j as orFn,
  lt as parseJson,
  p as parseMilliseconds,
  Bt as parseStream,
  A as parsedDurationToFormat,
  b as pipe,
  U as promisify,
  C as retry,
  _ as retryWithBackoff,
  pt as shortenNumber,
  a as sizes,
  Dt as toBoolean,
  ot as toEpoch,
  gt as toLimit,
  st as toRRule,
  Et as truncate,
  v as tryCatch,
  P as tryCatchAsync,
  ut as unbindEvents,
  Pt as unflattenSocials,
  G as uniqBy,
  z as windowDefined,
  ht as withLock,
  jt as withSpace
};
